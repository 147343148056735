import React, { PropsWithChildren, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { updateLeadStatus } from "../services/leadService";
import { LeadDataI } from "../views/leads/leads";

interface PropsT {
  showStatusPopup: boolean;
  toggleLeadStatus: () => void;
  onStatusChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedStatus: string;
  setShowStatusPopup: React.Dispatch<React.SetStateAction<boolean>>;
  from: string;
  id: string;
  leadList: string;
  onLeadUpdate?: (updatedLead: LeadDataI) => void; 
  getLeads: (leadId: any) => void;
  setOpenWithHeader: React.Dispatch<React.SetStateAction<boolean>>;
  updateEditedRow?: (newData: any) => void;
}

interface StatusItem {
  color: string;
  idDefault: boolean;
  name: string;
  value: string;
}

interface StatusI {
  status: StatusItem[];
}

const SelectLeadStatus: React.FC<PropsWithChildren<PropsT>> = ({
  showStatusPopup,
  toggleLeadStatus,
  onStatusChange,
  selectedStatus,
  setShowStatusPopup,
  from,
  id,
  getLeads,
  setOpenWithHeader,
  leadList,
  updateEditedRow,
  onLeadUpdate  // Added this to destructured props
}) => {
  const [leadStatus, setLeadStatus] = useState<StatusI>();
  const [localSelectedStatus, setLocalSelectedStatus] = useState(selectedStatus);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (showStatusPopup) {
      setLocalSelectedStatus(selectedStatus);
    }
  }, [showStatusPopup, selectedStatus]);

  const getPreferncesFromLocalStorage = async () => {
    const preferences = localStorage.getItem("user_preferences");
    if (preferences) {
      try {
        const parsedPreferences = JSON.parse(preferences);
        setLeadStatus(parsedPreferences);
      } catch (error) {
        console.error("Error parsing user preferences:", error);
      }
    }
  };

  useEffect(() => {
    getPreferncesFromLocalStorage();
  }, []);

  const handleStatusChange = (statusValue: string) => {
    // Create a synthetic event to maintain compatibility
    const syntheticEvent = {
      target: {
        value: statusValue
      }
    } as React.ChangeEvent<HTMLInputElement>;
    
    setLocalSelectedStatus(statusValue);
    onStatusChange(syntheticEvent);
  };

  const renderStatus = () =>
    leadStatus?.status?.map((item, i) => (
      <div 
        className={`status-option ${item.value === localSelectedStatus ? 'selected' : ''}`} 
        key={i}
        onClick={() => handleStatusChange(item.value)}
        role="button"
        tabIndex={0}
      >
        <input
          type="radio"
          checked={item.value === localSelectedStatus}
          value={item.value}
          id={`status-${item.value}`}
          onChange={(e) => handleStatusChange(e.target.value)}
          className="status-radio"
        />
        <label 
          htmlFor={`status-${item.value}`} 
          className="status-label"
          onClick={(e) => e.preventDefault()} // Prevent double-firing
        >
          <span 
            className="status-dot"
            style={{ backgroundColor: item.color }}
          />
          {item?.name}
        </label>
      </div>
    ));

    const onSave = async () => {
      console.log('Status Update Started:', { 
        localSelectedStatus,
        id 
      });
      
      setIsLoading(true);
      
      let ids = Array.isArray(id) ? id : [id];
  
      try {
        const response = await updateLeadStatus({
          leadIDs: ids,
          status: localSelectedStatus,
        });
  
        console.log('Status Update Response:', {
          success: !!response?.status,
          data: response?.data
        });
  
        if (response && response.status) {
          toast.success(response?.data?.message);
          
          if (onLeadUpdate) {
            onLeadUpdate(response.data.data);
          } else if (updateEditedRow) {
            updateEditedRow(response?.data.data);
          } else {
            getLeads(leadList);
          }
          
          setOpenWithHeader(false);
        }
      } catch (err) {
        console.error('Status Update Error:', err);
        toast.error("Error while updating status!");
      } finally {
        setIsLoading(false);
        setShowStatusPopup(false);
      }
    };

  const handleClose = () => {
    setLocalSelectedStatus(selectedStatus);
    toggleLeadStatus();
  };

  return (
    <>
      <Modal isOpen={showStatusPopup} toggle={handleClose} className="status-modal">
        <ModalHeader toggle={handleClose} className="modal-header">
          Select Status
        </ModalHeader>
        <ModalBody className="modal-body">
          <div className="status-list">
            {renderStatus()}
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <button
            onClick={handleClose}
            type="button"
            className="btn-cancel"
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            disabled={!localSelectedStatus || isLoading}
            onClick={onSave}
            type="button"
            className="btn-save"
          >
            {isLoading ? 'Saving...' : 'Save'}
          </button>
        </ModalFooter>
      </Modal>
      <Toaster position="top-right" reverseOrder={false} />
      <style>{`
        .status-modal {
          max-width: 400px;
          margin: 1.75rem auto;
        }

        .modal-header {
          padding: 0.5rem 1rem;
          border-bottom: 1px solid #e9ecef;
          background-color: #f8f9fa;
          min-height: 40px;
          display: flex;
          align-items: center;
        }

        .modal-header h5 {
          margin: 0;
          font-size: 1rem;
        }

        .modal-body {
          padding: 0.5rem;
          max-height: 70vh;
          overflow-y: auto;
        }

        .status-list {
          padding: 0.25rem;
        }

        .status-option {
          display: flex;
          align-items: center;
          padding: 0.625rem;
          margin-bottom: 0.25rem;
          border-radius: 6px;
          cursor: pointer;
          transition: all 0.15s ease;
          border: 1px solid transparent;
          user-select: none;
        }

        .status-option:hover {
          background-color: #f8f9fa;
          border-color: #e9ecef;
        }

        .status-option.selected {
          background-color: #f0f7ff;
          border-color: #0d6efd;
        }

        .status-radio {
          margin-right: 10px;
          cursor: pointer;
          pointer-events: none;
        }

        .status-label {
          display: flex;
          align-items: center;
          margin: 0;
          cursor: pointer;
          flex-grow: 1;
          font-size: 0.9375rem;
          pointer-events: none;
        }

        .status-dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 8px;
          display: inline-block;
        }

        .modal-footer {
          padding: 0.5rem 1rem;
          border-top: 1px solid #e9ecef;
          background-color: #f8f9fa;
        }

        .btn-cancel, .btn-save {
          padding: 0.375rem 0.75rem;
          border-radius: 4px;
          font-size: 0.875rem;
          font-weight: 500;
          transition: all 0.15s ease;
        }

        .btn-cancel {
          border: 1px solid #dee2e6;
          background-color: #fff;
          color: #6c757d;
          margin-right: 0.5rem;
        }

        .btn-cancel:hover:not(:disabled) {
          background-color: #f8f9fa;
          border-color: #c1c9d0;
        }

        .btn-save {
          border: none;
          background-color: #0d6efd;
          color: #fff;
        }

        .btn-save:hover:not(:disabled) {
          background-color: #0b5ed7;
        }

        .btn-save:disabled,
        .btn-cancel:disabled {
          opacity: 0.65;
          cursor: not-allowed;
        }

        @media (max-width: 576px) {
          .status-modal {
            margin: 1rem;
          }
          
          .modal-body {
            max-height: 60vh;
          }
        }
      `}</style>
    </>
  );
};

export default SelectLeadStatus;