import React, {
  PropsWithChildren,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import * as yup from "yup";
import update from "immutability-helper";
import ErrorText from "../../components/errorText";
import {
  createNewLead,
  getLeadDetail,
  updateLead,
  putUserPreferences,
} from "../../services/leadService";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Inputs from "../../components/inputs";
import { setCustomSource } from "../../actions/actions";
import InputMaps from "../../components/InputMaps";
import moment from "moment";
import { Spin } from "antd";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { IoIosCloseCircle } from "react-icons/io";

export interface CreateLeadDataI {
  name: string;
  email: string;
  phone: string;
  notes: string;
  salesValue: number;
  integration?: string;
  multi_select?: string[];
}

interface PropsT {
  showCreateNewLeadDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  getLeads: (leadId: any) => void;
  isEditMode: boolean;
  isDetailsBox: boolean;
  leadToBeUpdate: string;
  handleDrawerClick: () => void;
  selectedLeadId: string;
  leadList: Array<any>;
  disabled?: boolean;

  ref?: any;
  onClose?: () => void;
}

const CreateLeadForm: React.FC<PropsWithChildren<PropsT>> = forwardRef(
  (
    {
      showCreateNewLeadDrawer,
      getLeads,
      isEditMode,
      isDetailsBox,
      leadToBeUpdate,
      handleDrawerClick,
      selectedLeadId,
      leadList,
      disabled,
      onClose,
    },
    ref
  ) => {
    const StoreData = useSelector((state: any) => {
      return state?.rootReducers;
    });
    const dispatch = useDispatch();
    const [dynamicData, setDynamicData] = useState<any>([]);
    const [leadSourceData, setLeadSourceData] = useState([]);

    const [leadData, setLeadData] = useState<any>({
      name: "",
      email: "",
      phone: "",
      salesValue: 0,
      notes: "",
      integration: "64109561e6c7e737227a6382",
      list: "",
      multi_select: [],
    });

    const [errors, setErrors] = useState({
      name: "",
      // phone: "",
      email: "",
      saleValue: "",
      notes: "",
    });
    const regExp = /\b\d{10}\b/;
    const [newSourceToggle, setNewSourceToggle] = useState(false);
    const [selectedSource, setSelectedSource] = useState("leadForm");
    const [sourceName, setSourceName] = useState("");
    const [selectedList, setSelectedList] = useState("");
    const [addressName, setAddressName] = useState({});
    const [isPageLoad, setIsPageLoad] = useState<boolean>(false);
    const [leadListData, setleadListData] = useState<Array<string>>([]);
    useEffect(() => {
      if (isEditMode || disabled) {
        fetchLeadDetails();
      }
      let form_Data = StoreData.user?.userPreferences?.customForm;
      let source_Data = StoreData.customSource?.sourceList;
      setDynamicData(form_Data);
      setLeadSourceData(source_Data);
    }, []);

    const fetchLeadDetails = async () => {
      const response = await getLeadDetail(leadToBeUpdate);
      if (response && response.status) {
        setLeadData({
          ...leadData,
          ...response?.data?.data.extraDetails,
          name: response?.data?.data?.name || "",
          email: response?.data?.data?.email || "",
          phone: response?.data?.data?.phone
            ? Number(response?.data?.data?.phone)
            : "",
          saleValue: response?.data?.data?.saleValue
            ? Number(response?.data?.data?.saleValue)
            : "",
          notes: response?.data?.data?.note || "",
        });
        // setSourceName(response.data.data.customSource || '')
        setSelectedSource(response.data.data?.customSource);
      }
    };

    let schema = yup.object().shape({
      name: yup.string().required("Name is required"),
      // phone: yup.string().required("Phone Number is Required").matches(regExp, {
      //   message: "Phone Number is not valid",
      //   excludeEmptyString: true,
      // }),
      // email: yup.string().email("Email is invalid").required("Email is required"),
      // saleValue: yup.string().required("Sale value is required"),
      // notes: yup.string().required("Note is required"),
    });

    const handleChange = (name: any, value: string | string[]) => {
      setLeadData({
        ...leadData,
        [name]: value,
      });

      setErrors({
        ...errors,
        [name]: null,
      });
    };
    const handleMapChange = (name: any, value: string, coords: any) => {
      const dynamic1 = name;
      const user = {
        [dynamic1]: {
          address: value,
          coords: {
            latitude: coords.lat,
            longitude: coords.lng,
          },
        },
      };

      setAddressName(user);
      setErrors({
        ...errors,
        [name]: null,
      });
    };
    const onSourceChange = (tab: string) => {
      setSelectedSource(tab);
    };

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    function removeNullAndBlankValues(obj: any): any {
      const newObj: any = {};
      for (const key in obj) {
        const value = obj[key];
        if (
          value !== null &&
          value !== "" &&
          value !== "0" &&
          !isEmptyObject(value) &&
          !isEmptyArray(value)
        ) {
          newObj[key] = value;
        }
      }
      return newObj;
    }

    function isEmptyObject(obj: any): boolean {
      return obj && typeof obj === "object" && Object.keys(obj).length === 0;
    }

    function isEmptyArray(value: any): boolean {
      if (Array.isArray(value)) {
        return (
          value.length === 0 || (value.length === 1 && isEmptyArray(value[0]))
        );
      }
      return typeof value === "object" && Object.keys(value).length === 0;
    }

    // Handle form submission
    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
      e && e.preventDefault();
      setIsPageLoad(true);
      if (Object.values(addressName)) {
        Object.assign(leadData, addressName);
      }
      const isFormValid = await schema.isValid(leadData, {
        abortEarly: false,
      });

      if (isFormValid) {
        const newLeadData: any = {
          name: leadData?.name,
          phone: leadData?.phone.toString(),
          email: leadData?.email,
          saleValue: leadData.saleValue ? Number(leadData.saleValue) : null,
          customSource: selectedSource,
          isDistributed: leadData.isDistributed || null,
          whatsApp: leadData.whatsApp || null,
          notes: leadData.whatsApp || null,
          files: leadData.files || null,
          integration: leadData.integration || null,
          list: leadData.list || null,
          extraDetails: {},
          customField: [{}],
        };

        // Loop through leadData object and add remaining fields to extraDetails
        for (const key in leadData) {
          if (
            !newLeadData.hasOwnProperty(key) &&
            leadData[key] !== null &&
            leadData[key] !== "" &&
            leadData[key] !== 0
          ) {
            newLeadData.extraDetails[key] = leadData[key];
          }
        }
        console.log("newLeadData =====", newLeadData);
        const updateLeadData: any = removeNullAndBlankValues(newLeadData);
        if (isEditMode) {
          try {
            const { integration, ...body } = updateLeadData;
            const editResponse = await updateLead(leadToBeUpdate, body);
            if (editResponse && editResponse.status == 200) {
              toast.success(editResponse?.data?.message);
              handleDrawerClick();
              clearAllLeadForm();
              onClose && onClose();
              leadData.list !== "0"
                ? getLeads(leadData.list)
                : getLeads(undefined);
            }
          } catch (err: any) {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error("Error while creating a new lead!");
            }
          }
        } else {
          try {
            let body: any = updateLeadData;
            const response = await createNewLead(body);
            if (response && response.status == 200) {
              toast.success(response?.data?.message);
              handleDrawerClick();
              clearAllLeadForm();
              leadData.list !== "0"
                ? getLeads(leadData.list)
                : getLeads(undefined);
            }
          } catch (err: any) {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error("Error while creating a new lead!");
            }
          }
        }
      } else {
        schema.validate(leadData, { abortEarly: false }).catch((err) => {
          const validationErrors = err.inner.reduce(
            (acc: any, error: { path: string; message: string }) => {
              return {
                ...acc,
                [error.path]: error.message,
              };
            },
            {}
          );
          setErrors((prevErrors) =>
            update(prevErrors, {
              $set: validationErrors,
            })
          );
        });
      }
      setIsPageLoad(false);
    };

    const clearAllLeadForm = () => {
      setLeadData({
        name: "",
        email: "",
        phone: "",
        salesValue: 0,
        notes: "",
      });
      setAddressName({});
      showCreateNewLeadDrawer(false);
    };

    const NewSourceToggle = () => {
      setNewSourceToggle(!newSourceToggle);
      setSourceName("");
    };

    const addNewSource = () => {
      if (sourceName) {
        let tempData: any = [...leadSourceData];
        let tempObj = {
          name: sourceName,
          value: sourceName?.split(" ")?.join("_"),
        };
        tempData.push(tempObj);
        handleAddLeadSource(tempData);
        dispatch(setCustomSource(tempData));
        setLeadSourceData(tempData);
        NewSourceToggle();
      }
    };
    const handleAddLeadSource = async (data: any) => {
      try {
        const response = await putUserPreferences({ customSource: data });
        if (response && response.status) {
          console.log(response.data.message);
        }
      } catch (err: any) {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error("Error while updating Source!");
        }
      }
    };
    useEffect(() => {
      if (selectedLeadId) {
        setSelectedList(selectedLeadId);
        handleChange("list", selectedLeadId);
      }
    }, [selectedLeadId]);

    useEffect(() => {
      if (leadList) {
        let defaultLead = {
          name: "Default lead list",
          _id: "",
        };

        setleadListData([defaultLead, ...leadList]);
      }
    }, [leadList]);
    return (
      <Root>
        <Spin size="large" tip="Loading..." spinning={isPageLoad}>
          <form
            id="LeadsFilterForm"
            onSubmit={(e) => handleSubmit(e)}
            className="leadsform"
          >
            <div className="form-container">
              <div className="product_group lead_form">
                <h5 className="lead_source">Lead Source</h5>

                <div className="close_icon" onClick={onClose}>
                  <IoIosCloseCircle size={25} />
                </div>

                <div className="lead_tab_scroll lead_form_tabs mb-0">
                  {!disabled &&
                    (!newSourceToggle ? (
                      <button
                        className="new_lead_btn"
                        type="button"
                        onClick={() => NewSourceToggle()}
                      >
                        <i className="bi bi-plus"></i> New Source
                      </button>
                    ) : (
                      <>
                        <input
                          name="name"
                          type="text"
                          className="form-control btn-toggle-sourse"
                          autoComplete="off"
                          value={sourceName}
                          onChange={(e) => setSourceName(e?.target?.value)}
                        />
                        <div className="new_source_inp">
                          <img
                            src="assets/img/checked.png"
                            alt="img"
                            className="check_icn"
                            onClick={() => addNewSource()}
                          />
                          <div className="vl"></div>
                          <img
                            src="assets/img/cancel.png"
                            alt="img"
                            className="cancel_icn"
                            onClick={() => NewSourceToggle()}
                          />
                        </div>
                      </>
                    ))}

                  <ul
                    className="nav nav-lead lead_form_tabs justify-content-around d-flex flex_wrap mb-0"
                    id="lead-tab"
                    role="tablist"
                  >
                    {leadSourceData?.map((item: any, i: number) => {
                      return selectedSource !== item.value && disabled ? (
                        <></>
                      ) : (
                        <li
                          onClick={() => onSourceChange(item.value)}
                          className="nav-item"
                          role="presentation"
                          key={i}
                        >
                          <button
                            className={`nav-link ${
                              selectedSource === item.value && "active"
                            } lead_btn_tab`}
                            id="new-source-tab"
                            data-bs-toggle="pill"
                            data-bs-target={`#${item.value}`}
                            type="button"
                            role="tab"
                            aria-controls={`${item.value}`}
                            aria-selected="true"
                          >
                            {item.name}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div>
                  <div>
                    <label className="form-label">
                      Select List
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <select
                      disabled={isEditMode || disabled}
                      name="cars"
                      className="form-control"
                      value={selectedList}
                      onChange={(e) => {
                        setSelectedList(e.target.value);
                        handleChange("list", e.target.value);
                      }}
                    >
                      {leadListData.map((item: any, ix: number) => {
                        return (
                          <option value={item._id} key={ix}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {dynamicData?.map((item: any, i: number) => {
  let finalValue = item.type === "multi-selection" 
    ? (leadData?.[item?.value] || [])
    : (isNaN(leadData?.[item?.value])
        ? leadData?.[item?.value?.toLowerCase()]
        : leadData?.[item?.value]);
                    return (
                      <div className="form-group hide_dt select_icon" key={i}>
                        {item.type === "location" ? (
                          <InputMaps
                            disabled={disabled}
                            type={item.type}
                            name={item?.value}
                            value={finalValue || ""}
                            onChange={(
                              name: string,
                              value: string,
                              coords: any
                            ) => handleMapChange(name, value, coords)}
                            className="form-control"
                            label={item.label}
                            isRequired={item?.isRequired}
                          />
                        ) : item.type === "date" ? (
                          <div className="form-group">
                            <label className="form-label">{item?.label}</label>
                            <input
                              disabled={disabled}
                              type="date"
                              id="task_date"
                              name="toBePerformAt"
                              className="form-control"
                              value={finalValue}
                              onChange={(e: any) =>
                                handleChange(item?.value, e.target.value)
                              }
                              placeholder="Enter Date"
                            />
                          </div>
                        ) : (
                          <Inputs
                            disabled={disabled}
                            name={item?.value}
                            type={
                              item.type === "phone" || item.type === "amount"
                                ? "number"
                                : item.type
                            }
                            autoComplete="off"
                            className={
                              item.label === "Notes"
                                ? "form-control note_area"
                                : "form-control"
                            }
                            placeholder={`Enter ${item?.name}`}
                            value={finalValue || ""}
                            onChange={(e: any) =>
                              handleChange(item?.value, e.target.value)
                            }
                            label={item.label}
                            isRequired={item.isRequired}
                            options={["selection", "multi-selection"].includes(item.type) ? item.options : undefined}
                            id={
                              item.type === "time" || item.type === "date"
                                ? "lead_date_time"
                                : ""
                            }
                          />
                        )}

                        {/* {errors?.name && item?.name === "Name" && item.isRequired ? (
                    <ErrorText message={errors?.name} />
                  ) : errors?.phone &&
                    item?.name === "Phone" &&
                    item.isRequired ? (
                    <ErrorText message={errors?.phone} />
                  ) : (
                    ""
                  )} */}
                        {/* {errors?.name && item?.name === "Name" && item.isRequired ? (
                    <ErrorText message={errors?.name} />
                  ) : errors?.phone &&
                    item?.name === "Phone" &&
                    item.isRequired ? (
                    <ErrorText message={errors?.phone} />
                  ) : errors?.email &&
                    item?.name === "Email" &&
                    item.isRequired ? (
                    <ErrorText message={errors?.email} />
                  ) : errors?.saleValue &&
                    item?.name === "saleValue" &&
                    item.isRequired ? (
                    <ErrorText message={errors?.saleValue} />
                  ) : errors?.notes &&
                    item?.name === "notes" &&
                    item.isRequired ? (
                    <ErrorText message={errors?.notes} />
                  ) :(
                    ""
                  )} */}
                      </div>
                    );
                  })}
                </div>
              </div>

              {!isDetailsBox || (isDetailsBox && isEditMode) ? (
                <div className="d-flex justify-content-center Leadscreate ">
                  <button type="submit" className="btn btn-dark w-100">
                    {!isEditMode ? "Add" : "Update"} Lead
                  </button>
                </div>
              ) : null}
            </div>
            <Toaster position="top-right" reverseOrder={false} />
          </form>
        </Spin>
      </Root>
    );
  }
);

export default CreateLeadForm;
const Root = styled.section`
  .form-container {
    display: flex;
    flex-direction: column;
  }
  .lead_form {
    margin-bottom: 50px;
  }
  .Leadscreate {
    position: sticky;
    margin-top: auto;
    bottom: 0px;
  }
  .close_icon {
    position: absolute;
    right: 10px;
    top: 8px;
    color: #3faefdd1;
    cursor: pointer;
  }
    .form-control {
    position: relative;
  }

  .dropdown-menu {
    position: absolute;
    width: 100%;
    z-index: 1000;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }

  .dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
    &:hover {
      background-color: #f8f9fa;
    }
  }

  .form-check-input {
    margin-right: 8px;
  }

  .selected-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

`;
