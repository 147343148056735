import {
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IGetRowsParams,
  SortChangedEvent,
} from "ag-grid-community";
// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { Spin } from "antd";
import _, { toInteger } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
// import "./aggrid.css";

export interface IFetchRowsParams {
  perPage: number;
  page: number;
  orderBy?: string;
  isAscending?: boolean;
}

export interface IPagination {
  perPage: number;
  page: number;
}
interface AgGridProps {
  columnDefs: ColDef[];
  rowHeight?: number;
  fetchData: (params: IFetchRowsParams) => Promise<any>;
  onRowClick?: (rowData: any) => void;
  onGridReady?: (params: GridReadyEvent) => void;
  defaultData?: any;
  totalRows?: number;
  isLoading?: boolean;
  suppressPaginationPanel?: boolean; 
  defaultPagination?: IPagination;
  onColumnOrderChanged?: (order: string[]) => void;
  onColumnWidthChanged?: (colId: string, newWidth: number) => void;
  onRowSelected: (event: any) => void;
  pagination?: boolean;
  paginationPageSize?: number;
}

export const initialGridParams = {
  perPage: 50,
  page: 1,
};

interface ISortParams {
  isAscending?: boolean;
  orderBy?: string;
}

const AgGrid: React.FC<AgGridProps> = ({
  columnDefs,
  fetchData,
  rowHeight,
  onRowClick,
  defaultData,
  isLoading,
  onColumnOrderChanged,
  onColumnWidthChanged,
  totalRows,
  defaultPagination,
  onGridReady,
  onRowSelected,
  pagination = false,
  paginationPageSize = 0,
}) => {
  const [sortParams, setSortParams] = useState<ISortParams | null>(null);
  const agGridRef = useRef<any | null>(null);
  const gridApi = useRef<GridApi | null>(null);
  const gridParamsRef = useRef<IGetRowsParams | null>(null);

  const debouncedColumnMove = useRef(
    _.debounce((newColumnOrder) => {
      onColumnOrderChanged && onColumnOrderChanged(newColumnOrder);
    }, 500)
  ).current;

  const debouncedColumnWidth = useRef(
    _.debounce((columnId, newWidth) => {
      onColumnWidthChanged && onColumnWidthChanged(columnId, newWidth);
    }, 500)
  ).current;

  const onRowClicked = (event: any) => {
    if (onRowClick) {
      const selectedRow = event.data;
      onRowClick(selectedRow);
    }
  };

  const onColumnOrderChangedInternal = (event: ColumnMovedEvent) => {
    if (!event.finished) {
      const newColumnOrder = event.api
        .getColumnState()
        .map((columnState) => columnState.colId);
      if (newColumnOrder) {
        onColumnOrderChanged && debouncedColumnMove(newColumnOrder);
      }
    }
  };

  const onColumnWidthChangedInternal = (event: ColumnResizedEvent) => {
    if (!event.finished) {
      const columnId = event.column?.getColId();
      const newWidth = event.column?.getActualWidth();
      if (columnId && newWidth) {
        onColumnWidthChanged && debouncedColumnWidth(columnId, newWidth);
      }
    }
  };

  const gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: initialGridParams.perPage,
    cacheBlockSize: 50,
    maxBlocksInCache: 10,
    domLayout: "autoHeight",
    onRowClicked: onRowClicked,
    onColumnMoved: onColumnOrderChangedInternal,
    suppressPaginationPanel: true,
    onColumnResized: onColumnWidthChangedInternal,
  };

  const afterGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
    onGridReady && onGridReady(params);
    if (defaultPagination) {
      params.api?.paginationGoToPage(defaultPagination.page);
    }

    const dataSource = {
      rowCount: undefined,
      getRows: async (params: IGetRowsParams) => {
        console.log('Row Fetch:', {
          startRow: params.startRow,
          endRow: params.endRow,
         
        });
        const endrow = params.endRow;
        const perPage = params.endRow - params.startRow;
        const tempSortParams = sortParams ? sortParams : {};
        gridParamsRef.current = params;
        const response = await fetchData({
          page: toInteger(endrow / perPage),
          perPage,
          ...tempSortParams,
        });
        if (response) {
          params.successCallback(response.data, response.totalRows);
        }
      },
    };
    params.api.setGridOption("datasource", dataSource);
  };

  const onSortChanged = (event: SortChangedEvent) => {
    if (event.columns && event.columns.length && gridApi.current) {
      const currentCol = event.columns[event.columns.length - 1];
      const orderBy = currentCol.getColId();
      const isSorting = currentCol.isSorting();
      const isAscending = currentCol.isSortAscending();
      setSortParams(isSorting ? { orderBy, isAscending } : null);
      fetchData({ ...initialGridParams, orderBy, isAscending });
    }
  };

  useEffect(() => {
    if (agGridRef.current !== null) {
      const agGridApi = agGridRef.current?.api;
      const columnApi = agGridRef.current?.columnApi;

      for (let column of columnDefs) {
        const columnRef = columnApi?.getColumn(column.colId);
        if (column && agGridApi) {
          columnApi.setColumnWidth(columnRef, column["width"]);
        }
      }
    }
  }, [columnDefs]);

  const renderAgGrid = useMemo(() => {
    return (
      <AgGridReact
        gridOptions={gridOptions}
        columnDefs={columnDefs}
        rowHeight={rowHeight}
        rowClassRules={{ "cursor-pointer": () => true }}
        suppressPaginationPanel={false}
        onSortChanged={onSortChanged}
        getRowStyle={() => ({ background: "white" })}
        ref={agGridRef}
        pagination={true}
        maxConcurrentDatasourceRequests={2}
        cacheOverflowSize={0}
        maxBlocksInCache={1}
        onGridReady={afterGridReady}
        rowModelType={"infinite"}
        rowSelection="multiple"
        suppressRowClickSelection
        onRowSelected={onRowSelected}
      />
    );
  }, [columnDefs]);

  return (
    <Spin size="large" tip="Loading..." spinning={isLoading}>
      <div className="ag-theme-alpine" style={{ width: "100%" }}>
        {renderAgGrid}
      </div>
    </Spin>
  );
};

export default AgGrid;
