import React, { useMemo } from "react";
import styled from "styled-components";
import facebook from "../../../assets/images/facebook-logo.svg";
import nineacres from "../../../assets/images/integration/99acres.png";
import excel from "../../../assets/images/integration/excel.png";
import googleforms from "../../../assets/images/integration/googleforms.png";
import housing from "../../../assets/images/integration/housing.jpg";
import indiamart from "../../../assets/images/integration/indiamart.png";
import justdial from "../../../assets/images/integration/justdial.png";
import leadform from "../../../assets/images/integration/leadform.png";
import magicbricks from "../../../assets/images/integration/magicbricks.png";
import tradeindia from "../../../assets/images/integration/tradeIndia.png";
import typeform from "../../../assets/images/integration/typeform.png";
import word from "../../../assets/images/integration/word.png";
import wordpress from "../../../assets/images/integration/wordpress.png";
import zapiuer from "../../../assets/images/integration/zapiuer.svg";
import customform from "../../../assets/images/integration/Custom Form.jpeg";
import LeadLabel from "../../../components/leadLabel";
import LeadStatus from "../../../components/leadStatus";
import ContactLinksGenerator from "../../../utils/contactlinksgenerator";
import moment from "moment";
import "../leads.css";
import { addCountryCode } from "../../../utils/helpers";
import { FaEnvelope, FaPhone, FaRegClock, FaWhatsapp } from "react-icons/fa";
import { Phone, Mail, Clock } from "lucide-react"; // Corrected WhatsApp import

const StyledImg = styled.img`
  margin-right: 5px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; /* Consistent space between icons */
`;

const IconHolder = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.disabled
      ? "#E0E0E0"
      : "#f1f3f5"}; /* Light background for enabled, gray for disabled */
  box-shadow: ${(props) =>
    props.disabled
      ? "none"
      : "0 1px 3px rgba(0, 0, 0, 0.1)"}; /* Shadow for enabled icons */
  cursor: ${(props) =>
    props.disabled ? "default" : "pointer"}; /* Pointer cursor if enabled */
`;

const nameRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label" title={value}>
          {/* {(value || "").length > 16
            ? value.substr(0, 16).concat("...")
            : value} */}
          {value}
        </div>
      </div>
    </div>
  );
};


const phoneRenderer = React.memo(({ value, data, node }: any) => {
  const contactObj = useMemo(() => (
    new ContactLinksGenerator({
      number: data?.phone,
      email: data?.email,
      message: "Hello"
    })
  ), [data?.phone, data?.email]);

  // Handle action button clicks
  const handleActionClick = (e: React.MouseEvent<HTMLDivElement>, href: string | undefined) => {
    e.stopPropagation();  // Stop event bubbling
    e.preventDefault();    // Prevent default behavior
    
    // If we have a valid href, open it
    if (href) {
      window.open(href, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <IconWrapper onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
      {[
        {
          disabled: !data?.phone,
          href: contactObj.tel,
          icon: <Phone size={14} color={data?.phone ? "#007BFF" : "#A9A9A9"} />,
          ariaLabel: "Call contact"
        },
        {
          disabled: !data?.email,
          href: contactObj.mail,
          icon: <Mail size={14} color={data?.email ? "#0056D2" : "#A9A9A9"} />,
          ariaLabel: "Email contact"
        },
        {
          disabled: !data?.phone,
          href: contactObj.whatsapp,
          icon: <FaWhatsapp size={14} color={data?.phone ? "#25D366" : "#A9A9A9"} />,
          ariaLabel: "WhatsApp contact"
        }
      ].map(({ disabled, href, icon, ariaLabel }, index) => (
        <IconHolder 
          key={index} 
          disabled={disabled}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => 
            !disabled && handleActionClick(e, href)
          }
          role="button"
          aria-label={ariaLabel}
          aria-disabled={disabled}
        >
          {icon}
        </IconHolder>
      ))}
    </IconWrapper>
  );
}, (prev, next) => 
  prev.data?.phone === next.data?.phone && 
  prev.data?.email === next.data?.email
);


const statusRenderer: React.FC<any> = (props) => {
  const { value, preferences } = props;
  if (!value || !preferences) {
    return <span>-</span>;
  }

  return (
    <div className="d-flex align-items-center h-100">
      <div className="lead-subtext">
        <LeadStatus preferences={preferences} status={value} isTag={true} />
      </div>
    </div>
  );
};

const labelRenderer: React.FC<any> = (props) => {
  const { value, preferences } = props;
  if (!value || !preferences) {
    return <span>-</span>;
  }

  return (
    <div className="d-flex align-items-center h-100">
      <div className="lead-subtext">
        <LeadLabel preferences={preferences} label={value} isTag={true} />
      </div>
    </div>
  );
};

const sourceRenderer: React.FC<any> = (props) => {
  const { value, data } = props;
  if (!value || !data) {
    return <span>-</span>;
  }

  const { customSource } = data;
  const imageMap: { [key: string]: string | JSX.Element } = {
    "99Acres": nineacres,
    CSV: excel,
    "Google spread sheets": googleforms,
    Housing: housing,
    Indiamart: indiamart,
    "Just Dial": justdial,
    "Magic Bricks": magicbricks,
    Tradeindia: tradeindia,
    "Type Form": typeform,
    Word: word,
    Wordpress: wordpress,
    Zapier: zapiuer,
    Facebook: facebook,
    "Lead Form": leadform,
    "Custom Form": customform,
    "WhatsApp Business": <FaWhatsapp size={16} color="#25D366" />, // WhatsApp Business icon
  };

  const source =
    typeof value === "string" ? value : value?.name || customSource;
  const icon = imageMap[source];

  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label d-flex align-items-center" title={source}>
          {typeof icon === "string" ? (
            <StyledImg src={icon} height="12" width="12" />
          ) : (
            icon
          )}
          <span>{source}</span>
        </div>
      </div>
    </div>
  );
};

const lastActivityRenderer: React.FC<any> = (props) => {
  const { value, data } = props;
  if (!value || !data) {
    return <span>-</span>;
  }

  const { lastActivityType } = data;
  const formattedTime = moment(value).fromNow();

  return (
    <div
      className="h-100"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        lineHeight: "1.1", // Slightly tighter line height
        fontSize: "0.85rem",
      }}
    >
      <span style={{ fontWeight: "500" }}>{lastActivityType}</span>
      <span
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "0.75rem",
          color: "#6c757d",
        }}
      >
        <Clock size={10} style={{ marginRight: "2px" }} /> {formattedTime}
      </span>
    </div>
  );
};

const assignedToRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  return value ? (
    <div className="d-flex align-items-center h-100">
      <div className="lead-subtext">
        <div
          style={{
            backgroundColor: "#2298FF",
            color: "#FFF",
            borderRadius: "10px",
            fontFamily: "Gilroy-Regular",
          }}
          className="cell_label p-1 px-2 fitContent"
        >
          {value?.firstName
            ? value?.firstName
            : "" + " " + value?.lastName
            ? value?.lastName
            : ""}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

const locationRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label" title={value}>
          {value.address}
        </div>
      </div>
    </div>
  );
};

const dateTimeRenderer = React.memo(({ value }: { value: string }) => {
  const formattedDate = useMemo(() => 
    value ? moment(value).format("MMMM D, YYYY, hh:mm A") : "-"
  , [value]);
  
  const relativeTime = useMemo(() => 
    value ? moment(value).fromNow() : ""
  , [value]);

  return (
    <div className="h-100 align-items-center d-flex flex-column">
      <span className="text-sm font-medium">{relativeTime}</span>
      <span className="text-xs text-gray-500" title={formattedDate}>
        {formattedDate}
      </span>
    </div>
  );
}, (prev, next) => prev.value === next.value);



const dateRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  const formattedDate = moment(value).format("DD MMM YYYY"); // e.g., 12 Nov 2024
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label" title={formattedDate}>
          {formattedDate}
        </div>
      </div>
    </div>
  );
};

const amountRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label">{`₹ ${value.toLocaleString("en-IN")}`}</div>
      </div>
    </div>
  );
};

export {
  labelRenderer,
  nameRenderer,
  phoneRenderer,
  sourceRenderer,
  statusRenderer,
  lastActivityRenderer,
  assignedToRenderer,
  locationRenderer,
  dateRenderer,
  amountRenderer,
  dateTimeRenderer,
};
