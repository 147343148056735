import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { RiCloseFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { setLeadFilter } from '../../actions/actions';
import { FilterParams } from './leadsGrid/leads.types';
import { Ban, Copy, PhoneOff, UserMinus, Calendar, Clock, LucideProps } from 'lucide-react';
import { filterLeads } from '../../services/leadService';
import DuplicateManager from './leadsGrid/DuplicateManager';
import QuickFilters from './QuickFilters';
import PopupComponent from '../../components/PopupComponent';



interface DuplicateFilterOptions {
  isDuplicate: boolean;
  list?: string;  // Optional as it may not always be present
  paginationParams: {
    page: number;
    perPage: number;
  };
}

interface IProps {
  onChange?: () => void;
}

// Update FilterKey to include isDuplicate
type FilterKey = keyof Pick<FilterParams, 'isUntouched' | 'isNotCalled' | 'isNotAssign' | 'isFollowUp' | 'isStaled' | 'isDuplicate'>;

type IconType = React.ForwardRefExoticComponent<Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>>;

const LeadsNewFilter: React.FC<IProps> = ({ onChange }) => {
  const dispatch = useDispatch();
  const { filterParam } = useSelector((state: any) => state.rootReducers.leads);
  
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [leadListData, setLeadListData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
 
  const triggerRef = useRef<HTMLDivElement>(null);
const [menuRect, setMenuRect] = useState<DOMRect | null>(null);

const [openDropdowns, setOpenDropdowns] = useState<{ [key: string]: boolean }>({});

  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [allDuplicateLeads, setAllDuplicateLeads] = useState<any[]>([]);
  
  const listId = useSelector((state: any) => 
    state.rootReducers.leadName.leadName.id
  );
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdownElements = document.querySelectorAll('.dropdown-container');
      let clickedInsideDropdown = false;
      
      dropdownElements.forEach(element => {
        if (element.contains(event.target as Node)) {
          clickedInsideDropdown = true;
        }
      });
      
      if (!clickedInsideDropdown) {
        setOpenDropdowns({});
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Close dropdowns when filters change
  useEffect(() => {
    setOpenDropdowns({});
  }, [filterParam]);

  
  const tooltips = {
    isUntouched: "Leads you haven't reached out to yet.",
    isNotCalled: "Leads with no calls made, or calls where you couldn't connect.",
    isNotAssign: "Leads not yet assigned to any team member and still with you.",
    isFollowUp: "Leads with no follow-up tasks scheduled.",
    isStaled: "Leads with no activity in the past 15 days.",
    isDuplicate: "Leads that have duplicate entries in the system."
  };
  
  const handleResetFilters = useCallback(() => {
    dispatch(setLeadFilter({
      paginationParams: {
        page: 1,
        perPage: 50
      }
    }));
    onChange?.();
  }, [dispatch, onChange]);
  
  const filters: Array<{ key: FilterKey; label: string; icon: IconType }> = [
    { key: 'isUntouched', label: 'Untouched', icon: Ban },
    { key: 'isNotCalled', label: 'No call', icon: PhoneOff },
    { key: 'isNotAssign', label: 'Unassigned', icon: UserMinus },
    { key: 'isFollowUp', label: 'No task', icon: Calendar },
    { key: 'isStaled', label: 'Stale', icon: Clock },
    { key: 'isDuplicate', label: 'Duplicates', icon: Copy }
  ];

    
// In LeadsNewFilter.tsx
const handleFilterClick = async (key: FilterKey) => {
  if (triggerRef.current) {
    setMenuRect(triggerRef.current.getBoundingClientRect());
  }
  // COMPLETELY SEPARATE duplicate handling 
  if (key === 'isDuplicate') {
    // Get the list ID directly from the leadName state
   

    try {
      setShowDuplicateModal(true); // Show modal first
      setIsLoading(true); // Show loading state
      setCurrentPage(1);
      setAllDuplicateLeads([]);

      // Call API with JUST duplicate flag and list
      const response = await filterLeads({
        isDuplicate: true,
        list: listId === "0" ? undefined : listId,
        paginationParams: {
          perPage: 50,
          page: 1
        }
      });
      
      if (response?.status) {
        setAllDuplicateLeads(response.data.data);
        setHasMore(response.data.data.length === 50);
        setShowDuplicateModal(true);
      }
    } catch (error) {
      console.error('[Filter] Error fetching duplicates:', error);
      setShowDuplicateModal(false); 
    } finally {
      setIsLoading(false);
    }
    return;
  }

  // Keep normal filter logic completely separate
  const newValue = !filterParam[key];
  dispatch(setLeadFilter({ 
    ...filterParam, 
    [key]: newValue || undefined,
    paginationParams: {
      page: 1,
      perPage: 50
    }
  }));
  onChange?.();
};

  const loadMoreDuplicates = async () => {
    if (!hasMore || isLoading) {
      console.log('[DuplicateManager] Skipping load more:', { hasMore, isLoading });
      return;
    }
    
    try {
      setIsLoading(true);
      const nextPage = currentPage + 1;
      
      const filterOptions = { 
        isDuplicate: true,
        list: filterParam.list,  // <-- Same change here
        paginationParams: { 
          page: nextPage, 
          perPage: 50 
        }
      };
  
      console.log('[DuplicateManager] Loading more:', filterOptions);
      const response = await filterLeads(filterOptions);
      
      if (response?.status) {
        const newLeads = response.data.data;
        console.log('[DuplicateManager] Got new leads:', {
          newLeadsCount: newLeads.length,
          currentTotal: allDuplicateLeads.length,
          nextPage
        });
        
        // Check if we got any new leads
        if (newLeads.length === 0) {
          console.log('[DuplicateManager] No more leads to load');
          setHasMore(false);
          return;
        }
  
        setAllDuplicateLeads(prev => [...prev, ...newLeads]);
        setCurrentPage(nextPage);
        setHasMore(newLeads.length === 50);
  
        console.log('[DuplicateManager] Updated state:', {
          totalLeads: allDuplicateLeads.length + newLeads.length,
          hasMore: newLeads.length === 50,
          currentPage: nextPage
        });
      }
    } catch (error) {
      console.error('[DuplicateManager] Error loading more duplicates:', error);
      setHasMore(false); // Set hasMore to false on error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Root>
      
      <FilterContainer data-tour="filter-bar">
       
        <FilterList role="list">
          {filters.map(({ key, label, icon: Icon }, index) => (
            <React.Fragment key={key}>
              {index > 0 && <Separator aria-hidden="true">•</Separator>}
              <FilterItem 
                isActive={!!filterParam[key]}
                onClick={() => handleFilterClick(key)}
                aria-label={`Filter by ${label}${filterParam[key] ? ' (active)' : ''}`}
                title={tooltips[key]}
                role="listitem"
              >
                <FilterContent>
                  <Icon size={14} className="filter-icon" aria-hidden="true" />
                  <FilterLabel>{label}</FilterLabel>
                  {filterParam[key] && (
                    <CloseButton 
                      aria-label={`Remove ${label} filter`}
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        handleFilterClick(key);
                      }}
                    >
                      <RiCloseFill size={14} />
                    </CloseButton>
                  )}
                 
                </FilterContent>
              </FilterItem>
            </React.Fragment>
          ))}
            <Separator aria-hidden="true">•</Separator>
            <div data-tour="quick-filters">
            <QuickFilters onChange={onChange} />
            </div>
        </FilterList>
       
        <PopupComponent
          isOpen={showDuplicateModal}
          onClose={() => {
            setShowDuplicateModal(false);
            setCurrentPage(1);
            setAllDuplicateLeads([]);
          }}
          title="Duplicate Leads Manager"
          size="lg"
          loading={isLoading} 
        >
          <DuplicateManager 
            leads={allDuplicateLeads}
            onClose={() => setShowDuplicateModal(false)}
            onLoadMore={loadMoreDuplicates}
            hasMore={hasMore}
            isLoading={isLoading}
            
           
          />
        </PopupComponent>
      </FilterContainer>
    </Root>
  );
};

const Root = styled.section`
  padding: 2px 6px;
  background: #ffffff;
  border-bottom: 1px solid #f1f5f9;
  width: 100%;
  position: relative;
  overflow: visible;
  border-radius: 0 0 8px 8px; /* Only round bottom corners */
  margin: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 34px;
  z-index: 1;
  flex-wrap: wrap; // Add this line
`;

const FilterHeader = styled.div`
  color:rgb(17, 44, 82);
  font-size: 13px;
  white-space: nowrap;
  user-select: none;
  padding-left: 2px;
`;

const FilterList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
   gap: 2px;
   overflow: visible;  // Add this
  position: relative; // Add this
  z-index: 50;       // Add this
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Separator = styled.span`
  color:rgb(0, 0, 0);
  margin: 0 6px; 
  font-size: 6px;
  line-height: 1;
  user-select: none;
  vertical-align: middle;
  font-weight: bold;
`;

const FilterContent = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 5px 10px;
  height: 26px;

  .filter-icon {
    color: currentColor;
    opacity: 0.8;
    flex-shrink: 0;
    width: 13px;
    height: 13px;
  }
`;

const FilterLabel = styled.span`
  white-space: nowrap;
  line-height: 1;
`;

const FilterItem = styled.li<{ isActive?: boolean }>`
  position: relative;
  color: ${props => props.isActive ? '#1e40af' : '#64748b'};
  background: ${props => props.isActive ? '#dbeafe' : '#f8fafc'};
  border: 1px solid ${props => props.isActive ? '#93c5fd' : '#e2e8f0'};
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  flex-shrink: 0;
  user-select: none;
  height: 26px;
  display: flex;
  align-items: center;

  &:hover {
    background: ${props => props.isActive ? '#bfdbfe' : '#f1f5f9'};
    border-color: ${props => props.isActive ? '#60a5fa' : '#cbd5e1'};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: none;
  }
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  margin-left: 2px;
  color: inherit;
  background: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.06);
  }

  &:active {
    transform: scale(0.95);
  }
`;
const ResetButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background: #f8fafc;
  color: #64748b;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #f1f5f9;
    border-color: #cbd5e1;
  }
`;

export default LeadsNewFilter;