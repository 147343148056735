import React, { useState } from 'react';
import { Phone, Mail, Paperclip, Edit, ExternalLink } from "lucide-react";
import { FaWhatsapp } from "react-icons/fa";
import { Tooltip } from "reactstrap";
import { addCountryCode } from "../../utils/helpers";

interface LeadActionIconsProps {
  phone?: string;
  email?: string;
  onFileClick: () => void;
  onEditClick: (e: React.MouseEvent) => void;
  onFormResponseClick?: () => void;
  hasCustomForms?: boolean;
  leadId: string;
}

const LeadActionIcons: React.FC<LeadActionIconsProps> = ({
  phone,
  email,
  onFileClick,
  onEditClick,
  onFormResponseClick,
  hasCustomForms,
  leadId
}) => {
  const [tooltipOpen, setTooltipOpen] = useState({
    phone: false,
    email: false,
    whatsapp: false,
    files: false,
    edit: false,
    external: false
  });

  type TooltipKey = 'phone' | 'email' | 'whatsapp' | 'files' | 'edit' | 'external';
  
  const toggle = (name: TooltipKey) => {
    setTooltipOpen(prev => ({
      ...prev,
      [name]: !prev[name]
    }));
  };

  const isPhoneValid = phone && phone.length > 4;
  const isEmailValid = email && email.length > 8;

  return (
    <ul className="popup-section-4-left-1 mt-1 text-center">
      <li>
        {isPhoneValid ? (
          <a href={`tel:+${phone}`} id="phoneTooltip">
            <span className="link_icon" style={{ backgroundColor: "#0EA5E9" }}>
              <Phone size={18} />
            </span>
          </a>
        ) : (
          <span className="link_icon" style={{ backgroundColor: "#9CA3AF" }} id="phoneTooltip">
            <Phone size={16} />
          </span>
        )}
        <Tooltip
          isOpen={tooltipOpen.phone}
          target="phoneTooltip"
          toggle={() => toggle('phone')}
        >
          Call Lead
        </Tooltip>
      </li>

      <li>
        {isEmailValid ? (
          <a href={`mailto:${email}`} id="emailTooltip">
            <span className="link_icon" style={{ backgroundColor: "#EAB308" }}>
              <Mail size={16} />
            </span>
          </a>
        ) : (
          <span className="link_icon" style={{ backgroundColor: "#adadad" }} id="emailTooltip">
            <Mail size={16} />
          </span>
        )}
        <Tooltip
          isOpen={tooltipOpen.email}
          target="emailTooltip"
          toggle={() => toggle('email')}
        >
          Send Email
        </Tooltip>
      </li>

      <li>
        {isPhoneValid ? (
          <a 
            href={`https://api.whatsapp.com/send?phone=${addCountryCode(phone)}`}
            target="_blank"
            rel="noopener noreferrer"
            id="whatsappTooltip"
          >
            <span className="link_icon" style={{ backgroundColor: "#22C55E" }}>
              <FaWhatsapp size={16} />
            </span>
          </a>
        ) : (
          <span className="link_icon" style={{ backgroundColor: "#adadad" }} id="whatsappTooltip">
            <FaWhatsapp size={16} />
          </span>
        )}
        <Tooltip
          isOpen={tooltipOpen.whatsapp}
          target="whatsappTooltip"
          toggle={() => toggle('whatsapp')}
        >
          Send WhatsApp Message
        </Tooltip>
      </li>

      <li>
        <a onClick={onFileClick} id="filesTooltip">
          <span className="link_icon" style={{ backgroundColor: "#EF4444" }}>
            <Paperclip size={16} />
          </span>
        </a>
        <Tooltip
          isOpen={tooltipOpen.files}
          target="filesTooltip"
          toggle={() => toggle('files')}
        >
          Manage Files
        </Tooltip>
      </li>

      <li>
        <a onClick={onEditClick} id="editTooltip">
          <span className="link_icon" style={{ backgroundColor: "#3B82F6" }}>
            <Edit size={16} />
          </span>
        </a>
        <Tooltip
          isOpen={tooltipOpen.edit}
          target="editTooltip"
          toggle={() => toggle('edit')}
        >
          Edit Lead
        </Tooltip>
      </li>

      <li>
        {hasCustomForms ? (
          <a onClick={onFormResponseClick} id="externalTooltip">
            <span className="link_icon" style={{ backgroundColor: "#F87171" }}>
              <ExternalLink size={16} />
            </span>
          </a>
        ) : (
          <span className="link_icon" style={{ backgroundColor: "#adadad" }} id="externalTooltip">
            <ExternalLink size={16} />
          </span>
        )}
        <Tooltip
          isOpen={tooltipOpen.external}
          target="externalTooltip"
          toggle={() => toggle('external')}
        >
          View Form Response
        </Tooltip>
      </li>
    </ul>
  );
};

export default LeadActionIcons;