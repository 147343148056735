import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { getContentByType } from '../../services/contentService';
import PopupComponent from '../../components/PopupComponent';

// Define interfaces for type safety
interface EmailTemplateDetails {
  title: string;
  tags: string[];
  subject: string;
  message: string;
  type: string;
  files?: any[];
}

// Updated interface to ensure compatibility with TemplateType
interface EmailTemplate {
  _id: string;
  type: string;
  details: EmailTemplateDetails;
  files: any[];
  uniqueLinkId: string;
  viewCount: number;
  createdBy: string;
  organization: string;
  team: string | null;
  createdAt: string;
  name: string;  // Made non-optional and required
  subject: string;  // Made non-optional and required
  body: string;  // Made non-optional and required
}

interface EmailTemplateApiResponse {
  statusCode: number;
  status: boolean;
  data: EmailTemplate[];
  page: number;
  total: number;
}

interface EmailTemplateListProps {
  isOpen: boolean;
  onClose: () => void;
  onEmailTemplateSelect: (template: EmailTemplate) => void;
  handleDiscardEmail: () => void;
}

const PER_PAGE = 50;

const EmailTemplatePopup: React.FC<EmailTemplateListProps> = ({
  isOpen,
  onClose,
  onEmailTemplateSelect,
  handleDiscardEmail,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>(null);
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<EmailTemplate[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const buttonStyle = {
    padding: '8px 16px',
    borderRadius: '6px',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontSize: '14px',
    fontWeight: 600,
  };

  const primaryButtonStyle = (disabled: boolean) => ({
    padding: '8px 16px',
    borderRadius: '6px',
    border: 'none',
    cursor: disabled ? 'not-allowed' : 'pointer',
    transition: 'all 0.3s ease',
    fontSize: '14px',
    fontWeight: 600,
    backgroundColor: disabled ? '#D1D5DB' : '#3FAEFD',
    color: 'white',
  });

  useEffect(() => {
    const getTemplates = async () => {
      try {
        setLoading(true);
        setError(false);
        console.log('Fetching email templates...');
        
        const response = await getContentByType('email_template', { 
          page: 1, 
          perPage: 100  // or a high number to get all templates
        }) as AxiosResponse<EmailTemplateApiResponse>;
  
        console.log('API response:', response);
  
        let data: EmailTemplate[] = [];
        // Validate response structure
        if (response.data && response.data.status && response.data.statusCode === 200) {
          data = response.data.data.map(template => ({
            ...template,
            name: template.details?.title || '',
            subject: template.details?.subject || '',
            body: template.details?.message || ''
          }));
        } else {
          console.error('Unexpected API response format:', response.data);
          setError(true);
          return;
        }
  
        console.log('Fetched templates:', data);
        setTemplates(data);
        setFilteredTemplates(data);
        setTotal(response.data.total);
        console.log('Total templates:', response.data.total);
      } catch (error) {
        console.error('Error fetching templates:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
  
    if (isOpen) {
      console.log('Email template popup is open, fetching templates...');
      getTemplates();
    }
  }, [isOpen]);

  useEffect(() => {
    const filtered = templates.filter(template => {
      const searchTerm = searchInput.toLowerCase();
      console.log('Filtering template:', template);
      console.log('Search term:', searchTerm);
      return template.name.toLowerCase().includes(searchTerm) ||
             template.subject.toLowerCase().includes(searchTerm) ||
             template.body.toLowerCase().includes(searchTerm);
    });
    console.log('Filtered templates:', filtered);
    setFilteredTemplates(filtered);
  }, [searchInput, templates]);

  const handleSave = () => {
    if (selectedTemplate) {
      onEmailTemplateSelect(selectedTemplate);
      onClose();
    }
  };

  const handleDiscard = () => {
    handleDiscardEmail();
    onClose();
  };

  const renderFooter = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <button
        onMouseEnter={(e) => {
          e.currentTarget.style.color = '#374151';
          e.currentTarget.style.backgroundColor = '#F3F4F6';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.color = '#6B7280';
          e.currentTarget.style.backgroundColor = 'transparent';
        }}
        onClick={handleDiscard}
        style={{
          ...buttonStyle,
          backgroundColor: 'transparent',
          color: '#6B7280',
        }}
      >
        Discard
      </button>
      <button
        onClick={handleSave}
        disabled={!selectedTemplate}
        style={primaryButtonStyle(!selectedTemplate)}
      >
        Save Template
      </button>
    </div>
  );

  return (
    <PopupComponent
      isOpen={isOpen}
      onClose={onClose}
      title={`Email Templates (${filteredTemplates.length})`}
      size="lg"
      footer={renderFooter()}
    >
      <div style={{ minHeight: '500px' }}>
        <div style={{ marginBottom: '16px' }}>
          <input
            type="text"
            placeholder="Search templates..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            style={{
              width: '100%',
              padding: '8px 12px',
              borderRadius: '6px',
              border: '1px solid #E5E7EB',
              fontSize: '14px',
              outline: 'none',
              boxShadow: searchInput ? '0 0 0 2px rgba(63, 174, 253, 0.2)' : 'none',
              borderColor: searchInput ? '#3FAEFD' : '#E5E7EB',
            }}
          />
        </div>

        {error && (
          <div style={{
            color: '#EF4444',
            textAlign: 'center',
            margin: '16px 0',
            fontSize: '14px'
          }}>
            Failed to load templates. Please try again later.
          </div>
        )}

        {loading ? (
          <div style={{ 
            padding: '16px',
            textAlign: 'center',
            color: '#6B7280'
          }}>
            Loading templates...
          </div>
        ) : (
          <div className="row g-3">
            {filteredTemplates.map(template => (
              <div className="col-md-4" key={template._id}>
                <div
                  onClick={() => setSelectedTemplate(template)}
                  style={{
                    padding: '12px',
                    border: `2px solid ${template._id === selectedTemplate?._id ? '#3FAEFD' : '#E5E7EB'}`,
                    borderRadius: '8px',
                    backgroundColor: template._id === selectedTemplate?._id ? '#F0F9FF' : 'white',
                    cursor: 'pointer',
                    height: '120px',
                    overflow: 'hidden'
                  }}
                >
                  <div style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '8px',
                    color: '#1F2937',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    {template.name}
                  </div>
                  <div style={{
                    fontSize: '12px',
                    color: '#6B7280',
                    lineHeight: '1.4',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden'
                  }}>
                    {template.subject || 'No subject available'}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        
        {total > PER_PAGE && (
          <div style={{ marginTop: '16px', textAlign: 'center' }}>
            <button
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#DBEAFE';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '#EFF6FF';
              }}
              style={{
                ...buttonStyle,
                backgroundColor: '#EFF6FF',
                color: '#3B82F6',
              }}
              onClick={() => setPage((prev) => prev + 1)}
              disabled={page >= total / PER_PAGE}
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </PopupComponent>
  );
};

export default EmailTemplatePopup;