import React, { useState } from 'react';
import { 
  Dropdown, 
  DropdownToggle, 
  DropdownMenu, 
  DropdownItem 
} from 'reactstrap';
import { 
  ChevronDown, 
  Copy, 
  UserPlus, 
  FolderInput, 
  Share2, 
  Trash2
} from 'lucide-react';

interface LeadOptionsMenuProps {
  leadId: string;
  onCopyToList: () => void;
  onAssignLead: () => void;
  onMoveToList: () => void;
  onShare: () => void;
  onDelete?: () => void;
  isDeleteAllowed?: boolean;
}

const LeadOptionsMenu: React.FC<LeadOptionsMenuProps> = ({
  leadId,
  onCopyToList,
  onAssignLead,
  onMoveToList,
  onShare,
  onDelete,
  isDeleteAllowed = true
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prev => !prev);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle 
        className="border-0 flex items-center ml-auto"
        style={{
          fontSize: "13px",
          background: "none",
          fontWeight: "700",
          color: "#00000099",
        }}
      >
        Options
        <ChevronDown className="ml-1" size={18} />
      </DropdownToggle>

      <DropdownMenu className="lead_options_dropdown">
        <DropdownItem
          tag="div"
          className="text-base font-medium"
        >
          Lead options
        </DropdownItem>

        <DropdownItem
          onClick={onCopyToList}
          className="flex gap-3 items-center"
        >
          <Copy size={18} />
          Copy to list
        </DropdownItem>

        <DropdownItem
          onClick={onAssignLead}
          className="flex gap-3 items-center"
        >
          <UserPlus size={18} />
          Assign lead
        </DropdownItem>

        <DropdownItem
          onClick={onMoveToList}
          className="flex gap-3 items-center"
        >
          <FolderInput size={18} />
          Move to list
        </DropdownItem>

        <DropdownItem
          onClick={onShare}
          className="flex gap-3 items-center"
        >
          <Share2 size={18} />
          Quick share
        </DropdownItem>

        {isDeleteAllowed && onDelete && (
          <DropdownItem
            onClick={onDelete}
            className="flex gap-3 items-center text-red-600"
          >
            <Trash2 size={18} className="text-red-600" />
            Delete
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LeadOptionsMenu;