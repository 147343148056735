import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  LayoutDashboard, Home, FileText, Calendar, Settings,
  List, Plug, Inbox, Megaphone, MessageCircle, User,
  HelpCircle, Bolt
} from 'lucide-react';
import UserProfileDropdown from './UserProfileDropdown';
import BusinessSwitcherModal from './BusinessSwitcherModal';

interface SidebarProps {
  userdata?: any;
}

// Common styles for NavLinks
const commonNavLinkStyle = (isActive: boolean) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0.3rem 0.5rem', // Reduced vertical padding by 25%
  marginBottom: '0.1rem',   // Reduced margin by 25%
  borderRadius: '6px',
  color: isActive ? '#0d6efd' : '#495057',
  backgroundColor: isActive ? '#e7f1ff' : 'transparent',
  transition: 'all 0.2s ease',
  textDecoration: 'none',
  fontSize: '0.9rem'
});

// NavLink items configuration
const navItems = [
  { path: '/dashboard', Icon: LayoutDashboard, label: 'Dashboard' },
  { path: '/leads', Icon: Home, label: 'Leads' },
  { path: '/content', Icon: FileText, label: 'Content' },
  { path: '/tasks', Icon: Calendar, label: 'Task' },
  { path: '/automation', Icon: Settings, label: 'Automation' },
  { path: '/form', Icon: List, label: 'Form' },
  { path: '/whatsappInbox', Icon: Inbox, label: 'Inbox' },
  { path: '/whatsappBroadcast', Icon: Megaphone, label: 'Campaigns' },
  { path: '/template', Icon: MessageCircle, label: 'Template' },
  { path: '/profile', Icon: User, label: 'WABA profile' }
];

const Sidebar: React.FC<SidebarProps> = ({ userdata }) => {
  const [showSwitcherModal, setShowSwitcherModal] = useState(false);
  
  const openHelpWebsite = () => {
    window.open("https://help.3sigmacrm.com", "_blank");
  };

  const NavItem: React.FC<{ path: string; Icon: any; label: string }> = ({ path, Icon, label }) => (
    <NavLink 
      className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
      style={({ isActive }) => commonNavLinkStyle(isActive)}
      to={path}
    >
      <Icon size={18} opacity={0.5} />
      <span className="ms-2">{label}</span>
    </NavLink>
  );

  return (
    <>
      <aside id="sidebar" className="sidebar d-flex flex-column" style={{ 
        width: "200px", 
        overflowY: "auto",
        overflowX: "hidden",
        borderRight: "1px solid #e9ecef",
        backgroundColor: "#ffffff",
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        borderRadius: 0,
        margin: 0,
        zIndex: 1040,
        boxShadow: '1px 0 5px rgba(0,0,0,0.05)',
        display: 'flex',
        flexDirection: 'column'
      }}>
        {/* Logo Section */}
        <div className="d-flex justify-content-center align-items-center" style={{ 
          height: "40px",  // Reduced from 48px
          minHeight: "40px", // Reduced from 48px
          padding: "0 8px",
          position: "relative",
          borderBottom: "1px solid #e9ecef",
          backgroundColor: "#ffffff",
          flexShrink: 0
        }}>
          <div style={{
            position: "absolute",
            bottom: 0,
            left: "20%",
            right: "20%",
            borderBottom: "2px dotted #e9ecef"
          }} />
          <a href="/dashboard" className="d-flex align-items-center">
            <img 
              src="assets/img/3sigma_logo.png" 
              alt="3Sigma Logo" 
              style={{ 
                height: "20px",
                transition: "transform 0.2s ease"
              }}
              className="hover:scale-105"
            />
          </a>
        </div>

        {/* Navigation Section */}
        <ul className="sidebar-nav" style={{ 
          listStyle: 'none', 
          padding: "0.2rem 0.375rem", // Reduced top/bottom padding
          margin: 0,
          width: '100%',
          boxSizing: 'border-box',
          flexGrow: 1,
          overflowY: 'auto'
        }}>
          <li>
            {navItems.map((item) => (
              <NavItem key={item.path} {...item} />
            ))}

            {userdata?.role?.name === 'super_admin' && (
              <NavItem path="/integrations" Icon={Plug} label="Integrations" />
            )}
          </li>
        </ul>

        {/* Footer Section */}
        <div className="mt-auto" style={{ padding: "0.3rem", flexShrink: 0 }}> {/* Reduced padding */}
          <div style={{
            background: "#f8f9fa",
            border: "1px solid #e9ecef",
            borderRadius: "6px",
            padding: "0.2rem", // Reduced padding
            marginBottom: "0.3rem" // Reduced margin
          }}>
            <NavLink
              className="nav-link get-help-link"
              to="#"
              onClick={openHelpWebsite}
              style={{
                fontSize: "0.85em",
                padding: "0.3rem 0.5rem", // Reduced padding
                display: "flex",
                alignItems: "center",
                color: "#495057",
                borderRadius: "4px",
                marginBottom: "0.2rem", // Reduced margin
                transition: "background-color 0.2s ease",
              }}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#edf2f7"}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
            >
              <HelpCircle size={16} opacity={0.5} />
              <span className="ms-2">Get Help</span>
            </NavLink>
            
            <NavLink
              className="nav-link"
              to="#"
              onClick={() => window.open("https://help.3sigmacrm.com/changelog/web-version-2.8.0", "_blank")}
              style={{
                fontSize: "0.85em",
                padding: "0.3rem 0.5rem", // Reduced padding
                display: "flex",
                alignItems: "center",
                color: "#495057",
                borderRadius: "4px",
                transition: "background-color 0.2s ease",
              }}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#edf2f7"}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
            >
              <Bolt size={16} opacity={0.5} />
              <span className="ms-2">What's New</span>
            </NavLink>
          </div>
        </div>
        
        {/* User Profile Section */}
        <UserProfileDropdown 
          userdata={userdata} 
          onSwitchBusiness={() => {
            const dropdown = document.querySelector('.user-info-dropdown-toggler');
            (dropdown as any)?.click();
            setShowSwitcherModal(true);
          }}
        />
      </aside>

      <BusinessSwitcherModal 
        isOpen={showSwitcherModal}
        onClose={() => setShowSwitcherModal(false)}
      />
    </>
  );
};

export default Sidebar;