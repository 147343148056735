import React from 'react';
import { Boxes } from 'lucide-react';

interface ActivityEmptyStateProps {
  type: 'activities' | 'tasks' | 'notes';
  message?: string;
  className?: string;
}

const ActivityEmptyState = React.memo<ActivityEmptyStateProps>(({ 
  type,
  message,
  className = ''
}) => {
  console.log('ActivityEmptyState Render:', { type });

  const getDefaultMessage = () => {
    switch (type) {
      case 'activities':
        return 'No activities found';
      case 'tasks':
        return 'No tasks found';
      case 'notes':
        return 'No notes found';
      default:
        return 'No data found';
    }
  };

  return (
    <div className={`empty-state text-center p-4 ${className}`}>
      <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '200px' }}>
        <Boxes className="mb-3" size={48} strokeWidth={1.5} color="#637381" />
        <h5 className="text-muted mb-2">
          {message || getDefaultMessage()}
        </h5>
        <p className="text-muted small mb-0">
          {type === 'activities' && 'Add an activity to get started'}
          {type === 'tasks' && 'Add a task to get started'}
          {type === 'notes' && 'Add a note to get started'}
        </p>
      </div>
    </div>
  );
});

ActivityEmptyState.displayName = 'ActivityEmptyState';

export default ActivityEmptyState;