import React, { useCallback, useEffect, useState } from "react";
import DrawerComponent from "../../components/drawer";
// @ts-ignore
import Papa from "papaparse";
import axios from "axios";
import { API_URL } from "../../config/config";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { TbDiscountCheckFilled } from "react-icons/tb";
import { getAllLeads, downloadCsv } from "../../services/leadService";
import { setLeads, setLeadName } from "../../actions/actions";
import styled from "styled-components";
import DragDropCSV from './DragDropCSV';
import { useDispatch } from "react-redux";
import DynamicConfirmationModal from "../../components/DynamicConfirmationModal";
import { FaGoogleDrive } from "react-icons/fa";
import UploadSheetFile from "../../UploadSheetFile";
import ImportGoogleContacts from "./ImportGoogleContacts";
import { HelpCircle } from "lucide-react";

interface Props {
  selectedList: any;
}
const UploadCsvFile: React.FC<Props> = (props) => {
  const { selectedList } = props;
  const [csvImportDrawer, setShowImportDrawer] = useState<boolean>(false);
  const [csvHeader, setCsvHeader] = useState<any>([]);
  const [csvFile, setCsvFile] = useState<any>(null);
  const [dynamicData, setDynamicData] = useState<any>(null);
  const [secondDrawer, setSecondDrawer] = useState<boolean>(false);
  const [selectingValue, setSelectingValue] = useState<any>({});
  const [selectedField, setSelectField] = useState<any>([]);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [thirdDrawer, setThirdDrawer] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [leadCounts, setLeadCounts] = useState<number>(0);
  const [totalLead, setTotalLead] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [leadListData, setLeadListData] = useState<Array<string>>([]);
  const [isFilterData, setIsFilterData] = useState<boolean>(false);
  const [selectedLeadList, setSelectedLeadList] = useState<any>({});
  const [formCloseModel, setFormCloseModel] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const fileReader = new FileReader();
  const dispatch = useDispatch();
  let formData = new FormData();
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  const API_ENDPOINT = API_URL + "api/v1";

  const onCsvFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results: any) {
          const rowsArray: any = [];
          results.data.map((d: any) => {
            rowsArray.push(Object.keys(d));
          });

          rowsArray[0].push("Don’t import");
          setCsvHeader(rowsArray[0]);
        },
      });

      fileReader.readAsText(e.target.files?.[0]);
      setCsvFile(e.target.files[0]);
      setSecondDrawer(true);
      setShowImportDrawer(false);
    }
  };
  const importCSVDrawer = () => {
    setShowImportDrawer(true);
  };

  const handleChange = (e: any) => {
    let currValue = selectingValue[e.target.name];

    let tempArr = selectedField;
    const index = tempArr.indexOf(currValue);
    if (index > -1) {
      // only splice array when item is found
      tempArr.splice(index, 1);
      // 2nd parameter means remove one item only
    }
    tempArr.push(e.target.value);
    setSelectField(tempArr);
    setSelectingValue((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const uploadLeads = async () => {
    try {
      setIsUploading(true); 
      let form_Data = StoreData.user?.userPreferences?.customForm;
      const keyMapping = form_Data.reduce((acc: any, obj: any) => {
        acc[obj.name] = obj.value;
        return acc;
      }, {});
      // Create a new object with keys replaced using the keyMapping
      const updatedObject = Object.keys(selectingValue).reduce(
        (acc: any, key) => {
          acc[keyMapping[key] || key] = selectingValue[key];
          return acc;
        },
        {}
      );
      if (updatedObject.phone.length < 1) {
        setIsUploading(false);  // Reset on validation error
        return toast.error("Please Select Phone Number");
      }
      if (updatedObject.name.length < 2) {
        return toast.error("Please Select lead Name");
      }
      formData.append("csv", csvFile);
      const keys = Object.keys(updatedObject);
      for (let key in updatedObject) {
        if (updatedObject.hasOwnProperty(key)) {
          if (updatedObject[key] && updatedObject[key] != "Don’t import") {
            formData.append(key, updatedObject[key]);
          }
        }
      }
      if (selectedList.id != "0") {
        formData.append("list", selectedList.id);
      }

      let obj_header = {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("auth_token") || "",
      };

      axios({
        method: "post",
        url: `${API_ENDPOINT}/lead/csv`,
        headers: obj_header,
        data: formData,
        onUploadProgress: (ev = ProgressEvent) => {
          const progress = (ev.loaded / ev.total) * 100;
          updateUploadProgress(Math.round(progress));
        },
      })
        .then(function (response) {
          if (response && response?.status) {
            getLeads();
            toast.success(response?.data?.message);
            setThirdDrawer(true);
            setSecondDrawer(false);
          }
        })
        .catch((data) => {
          toast.error(data.response.data.message);
        });
    } catch (err: any) {
      toast.error(err.response?.data?.message || "Error while uploading csv");
    }
    finally {
      setIsUploading(false);  // Reset loading state
    }
  };

  const updateUploadProgress = (value: number) => {
    setProgressValue(value);
  };
  const getLeads = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getAllLeads({
        isAscending: false,
        page: 1,
        perPage: 10,
      });
      if (response && response.status) {
        let responseData = response?.data;
        setLeadCounts(responseData.total);
        setTotalLead(responseData.data?.length);
        setCurrentPage(1);
        setLeadListData(responseData?.data);
        dispatch(setLeads(responseData?.data));
        setIsLoading(false);
        setIsFilterData(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }, [dispatch]);

  const CsvHelpLink = () => (
    <HelpLinkSection>
      <a 
        href="https://help.3sigmacrm.com/welcome/adding-or-importing-leads/bulk-import-leads-csv" 
        target="_blank"
        rel="noopener noreferrer"
      >
        <HelpCircle size={16} />
        <span>Need help with CSV import?</span>
      </a>
    </HelpLinkSection>
  );
  
  
  const onDownloadCsv = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      const response = await downloadCsv("");
      //   const response = await downloadCsvFile("62c048cfb4f7fe9f267107bb");
      if (response && response.status) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "template.csv");
        document.body.appendChild(link);
        link.click();
        toast.success("file downloaded successfully");
        $("#ImportleadsPopup").modal("hide");
      }
    } catch (err) {
      toast.error("error while download csv file!");
    }
  };

  const findMatch = (data: any) => {
    if (data.similarNames) {
      let tempData = data.similarNames;
      for (let i = 0; i < tempData.length; i++) {
        let tempObj = csvHeader?.find(
          (header: any) =>
            tempData[i].toLowerCase().trim() === header.toLowerCase().trim()
        );
        if (tempObj) {
          return tempObj;
        }
      }
      // if no similar names are found return empty string.
      return "";
    }
  };

  useEffect(() => {
    let form_Data = StoreData.user?.userPreferences?.customForm;
    setDynamicData(form_Data);

    let updatedArray: any = {};
    form_Data?.forEach((item: any) => {
      updatedArray[item.name.trim()] = "";
    });
    setSelectingValue({ ...updatedArray });
    let lead_name = StoreData.leadName.leadName;
    if (!("id" in lead_name)) {
      dispatch(setLeadName({ id: "0", name: "Lead list" }));
    }
    lead_name = StoreData.leadName.leadName;

    setSelectedLeadList(
      Object.keys(lead_name).length > 0
        ? lead_name
        : { id: "0", name: "Lead list" }
    );
  }, []);
  useEffect(() => {
    // const values = Object.keys(selectingValue);
    // values.forEach((item) => {
    //   let data = dynamicData.find((el: any) => {
    //     return el.name === item;
    //   });
    //   let returnMatch = findMatch(data);
    //   if (returnMatch) {
    //     setSelectingValue((prevState: any) => ({
    //       ...prevState,
    //       [item]: returnMatch,
    //     }));
    //     let tempArr = selectedField;
    //     tempArr.push(returnMatch);
    //     setSelectField([...tempArr]);
    //   }
    // });
    // const findMatchingKeysAndUpdateValues = (object1:any, keysArray:any) => {
    //     const updatedObject = { ...object1 };
    //     Object.keys(object1).forEach((key1) => {
    //       const matchingKey = keysArray.find((key2:any) => key2.toLowerCase().includes(key1.toLowerCase()));
    //       if (matchingKey) {
    //         updatedObject[key1] = matchingKey;
    //       }
    //     });
    //     return updatedObject;
    //   };
    //   const updatedObjectWithMatchingKeys =  findMatchingKeysAndUpdateValues(selectingValue, csvHeader);
    //   if(updatedObjectWithMatchingKeys){
    //     setSelectingValue((prev:any) => ({
    //         ...prev,
    //         ...updatedObjectWithMatchingKeys,
    //       }));
    //     //           let tempArr = selectedField;
    //     // tempArr.push(returnMatch);
    //     // setSelectField([...tempArr]);
    //   }
  }, [csvHeader]);
  return (
    <>
      <li
        className="dropdown-item"
        onClick={importCSVDrawer}
        style={{ border: "none !important" }}
      >
        <span role="img" aria-label="upload" style={{ marginRight: "8px" }}>
          📤
        </span>
       CSV bulk upload
      </li>

      {/* <li
        className="dropdown-item"
        // onClick={importCSVDrawer}
        style={{ border: "none !important" }}
      >
        <span role="img" aria-label="upload" style={{ marginRight: "8px" }}>
          <FaGoogleDrive color="#3faefc" />
        </span>
        Import from Google
      </li> */}

      <UploadSheetFile selectedList={{ id: "0", name: "Lead list" }} />
      <ImportGoogleContacts selectedList={{ id: "0", name: "Lead list" }} />

      <DrawerComponent
        openWithHeader={csvImportDrawer}
        setOpenWithHeader={() => {
          setFormCloseModel(true);
        }}
        drawerTitle="CSV Import 📊"
        size="sm" // Changed from "xs" to "sm" to increase drawer width
      >
        <form id="LeadsFilterForm" className="addleadsform lead_pop">
          <CsvImportSection className="form-container">
            <div className="product_group">
              <div className="form-group">
                <h5
                  className="text-center text-black mb-3"
                  style={{ fontSize: "20px" }}
                >
                  Bulk import leads 📥
                </h5>
                
                <p style={{ fontSize: "16px", textAlign: "center" }}>
                  Please map CSV field with CRM lead Form fields
                </p>
                <p className="intro-text">
                  Streamline your lead management process by importing your
                  leads in bulk. This powerful tool allows you to upload your
                  data from a CSV/Excel file, saving you time and effort. In the
                  next step, you'll be able to easily map your file columns to
                  our CRM fields, ensuring a smooth transition of your data.
                </p>
              </div>
              <CsvHelpLink />
              <div className="form-group2">
                <button
                  onClick={(e) => onDownloadCsv(e)}
                  className="download_btn"
                >
                  <span
                    role="img"
                    aria-label="download"
                    style={{ marginRight: "8px" }}
                  >
                    📥
                  </span>{" "}
                  Download File Template
                </button>
                <div className="mt-4 mx-3" id="csv_steps">
                  <h5>Upload in 3 easy steps 🚀</h5>
                  <ul>
                    <li>
                      <span>1️⃣</span> Select File
                    </li>
                    <li>
                      <span>2️⃣</span> Map Columns
                    </li>
                    <li>
                      <span>
                        <TbDiscountCheckFilled />
                      </span>{" "}
                      Import Lead
                    </li>
                  </ul>
                </div>

<div className="btn_bottom">
  <DragDropCSV 
    onFileUpload={async (file: File) => {
      return new Promise<void>((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results: any) {
            try {
              const rowsArray: any = [];
              results.data.map((d: any) => {
                rowsArray.push(Object.keys(d));
              });

              rowsArray[0].push("Don't import");
              setCsvHeader(rowsArray[0]);
              
              fileReader.readAsText(file);
              setCsvFile(file);
              setSecondDrawer(true);
              setShowImportDrawer(false);
              resolve();
            } catch (error: unknown) {
              reject(error);
            }
          },
          error: (error: Error) => {
            reject(error);
          }
        });
      });
    }} 
  />
</div>
              </div>
            </div>
          </CsvImportSection>
        </form>
      </DrawerComponent>

      <DrawerComponent
        openWithHeader={secondDrawer}
        setOpenWithHeader={() => setFormCloseModel(true)}
        drawerTitle="Map CSV Fields 🗺️"
        size="sm"
      >
        <MapFieldsContainer>
          <form
            id="LeadsFilterForm"
            className="addleadsform lead_pop"
            style={{ margin: "0 -15px" }}
          >
            <div className="form-container">
              <div className="product_group">
                <div className="form-group">
                  <p style={{ fontSize: "16px", textAlign: "center" }}>
                    Great! You've uploaded your CSV file. Now, let's map the
                    columns from your file to the corresponding fields in our
                    CRM. This ensures that your data is imported correctly and
                    is ready for use in your lead management process
                  </p>
                </div>
                <div>
                  <div className="d-flex justify-content-around align-items-center w-100 font-bold">
                    <p style={{ marginLeft: "-20px" }}>CRM fields</p>
                    <p>File fields </p>
                  </div>
                  {dynamicData?.map((v: any, i: any) => {
                    let field = v.name;
                    let isRequired = v?.isRequired;
                    return (
                      <div
                        className="d-flex justify-content-around align-items-center w-100"
                        style={{ marginBottom: "10px" }}
                        key={i}
                      >
                        <div
                          className="d-flex align-center bg-white p-2 mt-2"
                          style={{
                            borderRadius: "10px",
                            width: "230px",
                            height: "41px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <p
                            className="my-auto ms-2"
                            title={v?.name}
                            style={{ display: "contents" }}
                          >
                            {v.name}
                            {isRequired && " *"}
                            {/* {v?.name.length > 15
                            ? v?.name.substr(0, 15).concat("...")
                            : v?.name} */}
                          </p>
                        </div>
                        <div>
                          <img
                            alt="right"
                            src="assets/img/right.png"
                            height={19}
                            width={17}
                            style={{ marginTop: "6px" }}
                          />
                        </div>
                        <div
                          className="d-flex align-center bg-white p-2 mt-2"
                          style={{
                            borderRadius: "10px",
                            width: "230px",
                            height: "41px",
                          }}
                        >
                          <p className="my-auto ms-2 w-100">
                            <select
                              name={v?.name}
                              value={selectingValue[field]}
                              onChange={handleChange}
                              className="border-0 w-100"
                              style={{ outline: "none" }}
                            >
                              <option value={""}>Select</option>
                              {csvHeader?.map((c: any, i: any) => {
                                let x =
                                  selectedField?.includes(c) &&
                                  c !== "Don’t import";
                                // Don’t import
                                if (x) {
                                  return (
                                    <option hidden value={c} key={i}>
                                      {c}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option value={c} key={i}>
                                      {c}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>

                
                
              </div>
            </div>
          </form>
          <StickyButtonContainer>
  <button 
    className="import-btn"
    onClick={uploadLeads}
    disabled={isUploading}
  >
    {isUploading ? (
      <span>Uploading...</span>
    ) : (
      <>
        <span role="img" aria-label="import" style={{ marginRight: "8px" }}>
          📥
        </span>
        Import leads
      </>
    )}
  </button>
</StickyButtonContainer>
        </MapFieldsContainer>
      </DrawerComponent>

      <DrawerComponent
        openWithHeader={thirdDrawer}
        setOpenWithHeader={setThirdDrawer}
        drawerTitle="Import Complete 🎉"
        size="xs"
      >
        <form id="LeadsFilterForm" className="addleadsform lead_pop">
          <LeadImport className="form-container">
            <div className="product_group">
              <div className="form-group">
                <h5
                  className="text-center text-black mb-3"
                  style={{ fontSize: "24px" }}
                >
                  Lead Import Successful!
                </h5>
                <div className="check_icon">
                  <TbDiscountCheckFilled />
                </div>
                <p
                  className="text-center mt-3"
                  style={{ fontSize: "16px", color: "#555" }}
                >
                  Your leads are being processed and will be available shortly.
                  Please refresh the page in a few moments to see the updates.
                </p>
              </div>
              <div className="form-group2">
                <div className="d-flex w-100 d-flex justify-content-center btn_bottom">
                  <button
                    className="btn btn-primary text_small border-5"
                    onClick={() => {
                      setThirdDrawer(false);
                      window.location.reload();
                    }}
                    style={{ width: "90%", padding: "10px", fontSize: "16px" }}
                  >
                    Refresh Now
                  </button>
                </div>
              </div>
            </div>
          </LeadImport>
        </form>
      </DrawerComponent>

      <DynamicConfirmationModal
        onConfirmation={() => {
          setShowImportDrawer(false);
          setSecondDrawer(false);
          setFormCloseModel(false);
        }}
        showModal={formCloseModel}
        toggleModal={() => {
          setFormCloseModel(false);
        }}
        title="Are you sure to want to close this Form? you will loose all progress"
      />
    </>
  );
};
export default UploadCsvFile;
const CsvImportSection = styled.div`
  .intro-text {
    font-size: 11px;
    line-height: 1.4;
    color: #555;
    margin-bottom: 10px;
  }

  button.download_btn {
    background: #3a4b8680;
    color: #fff;
    padding: 8px;
    border-radius: 8px;
    display: block;
    margin: 20px auto;
    width: 90%;
    font-weight: 600;
    font-size: 14px;
    transition: background-color 0.3s ease;

    &:hover {
      background: #3a4b86;
    }
  }

  #csv_steps {
    h5 {
      font-size: 16px;
      font-weight: 600;
    }
    > ul {
      margin-top: 15px;
      li {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        color: #3faefdd1;
        align-items: center;
        margin-bottom: 10px;
        > span {
          width: 25px;
          display: block;
          text-align: center;
          color: #000;
          margin-right: 10px;
          > svg {
            font-size: 18px;
            color: #4cb050;
          }
        }
      }
    }
  }

  .btn_bottom {
    width: 90%;
    margin: 0 auto;
  }
  .upload_btn {
    position: relative;
    > span {
      display: block;
      width: 100%;
      background: #3a4b86;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      border-radius: 8px;
      padding: 10px;
      text-align: center;
      transition: background-color 0.3s ease;

      &:hover {
        background: #2a3b76;
      }
    }
    > input {
      width: 100%;
      cursor: pointer;
      z-index: 99;
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 0;
      height: 100%;
    }
  }
  .check_icon {
    color: #4cb050;
    display: block;
    text-align: center;
    font-size: 30px;
  }
`;
const HelpLinkSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  
  a {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #3a4b86;
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 8px;
    background: #f0f2f5;
    transition: all 0.2s ease;
    
    &:hover {
      background: #e4e7ed;
      text-decoration: none;
    }
  }
`;

const LeadImport = styled.div`
  .check_icon {
    font-size: 36px;
    text-align: center;
    color: #4cb050;
  }
`;
const MapFieldsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StickyButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 15px 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  margin-top: auto;

  .import-btn {
    display: block;
    width: 90%;
    margin: 0 auto;
    background: #3a4b86;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    transition: background-color 0.3s ease;
    border: none;
    cursor: pointer;

    &:hover {
      background: #2a3b76;
    }
  }
`;
