// Header.tsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import { APP_VERSION } from '../config/config';
import BusinessSwitcherModal from './BusinessSwitcherModal';
import Sidebar from './Sidebar';
import { FilterParams } from '../views/leads/leadsGrid/leads.types';

import GenericConfirmationModal from './GenericConfirmationModal';
import toast from 'react-hot-toast';
import { MailCheck } from 'lucide-react';

interface HeaderProps {
  onSearch?: (value: string) => void;
  closeIconClick?: () => void;
  isSearch?: boolean;
  searchPlaceholder?: string;
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
}

const emailPreview = `Subscribe to get your daily business report email like this from 3Sigma CRM:

📊 Daily Performance Summary
──────────────────
New Leads:  15 leads added
──────────────────
Calls Activity:
• Total Calls: 45 calls
• Connected: 32 calls
• Avg Call Time: 3.5 mins
──────────────────
Sales Updates:
• Total Sales: ₹75,000
• sales : 12 completed
──────────────────
Tasks:
• Due Today: 8 tasks
• Completed: 5 tasks
• Overdue: 2 tasks

Note: If you've already subsribed for daily email reports once no need do it again, no need to subscribe again.`;

const Header: React.FC<HeaderProps> = ({
  onSearch,
  closeIconClick,
  isSearch,
  searchPlaceholder,
  isOpen,
  setIsOpen,
}) => {
  const { filterParam }: { filterParam: FilterParams } = useSelector(
    (state: any) => state.rootReducers.leads
  );
  const [filterval, setfilterval] = useState<string>("");
  const [showSwitcherModal, setShowSwitcherModal] = useState(false);
  const [userdata, setUserdata] = useState<any>({});
  const navigate = useNavigate();
  const [showEmailConfirmModal, setShowEmailConfirmModal] = useState(false);
  const [emailPrefLoading, setEmailPrefLoading] = useState(false);

  const debouncedSearch = useRef(
    _.debounce((e: any) => {
      onSearch && onSearch(e);
    }, 500)
  ).current;

  const handleChange = (propogate?: boolean) => {
    setIsOpen && setIsOpen(!isOpen);
    if (propogate) {
      closeIconClick && closeIconClick();
    }
  };




  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    let local: any = window.localStorage?.getItem("userData");
    if (local) {
      setUserdata(JSON.parse(local));
    } else {
      handleLogout();
    }
  }, []);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        handleLogout();
      }
      return Promise.reject(error);
    }
  );

 

  
  const handleSwitchClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const dropdown = document.querySelector('.user-info-dropdown-toggler');
    (dropdown as any)?.click();
    setShowSwitcherModal(true);
  };

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
        style={{
          height: "1px",
          padding: "0 10px",
        }}
      >
        <nav className="header-nav ms-auto">
        
        </nav>
      </header>
     

      <Sidebar userdata={userdata} />

      <BusinessSwitcherModal 
        isOpen={showSwitcherModal}
        onClose={() => setShowSwitcherModal(false)}
      />
    </>
  );
};

export default Header;