import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "../../components/header";
import InboxChatHead from "./inboxChatHead";
import IncomingMsg from "./incomingMsg";
import TypingActionArea from "./typingActionArea";
import "./whatsapp.css";
import {
  assignConversation,
  getAllConversations,
  sendMessageToLead,
  updateProfile,
} from "../../services/whatsapp";
import { LeadDataI } from "../leads/leads";
import TaskDrawer from "../common/TaskDrawer";
import CreateTaskForm from "../../components/createTaskForm";
import CreateNoteForm from "../../components/createNoteForm";
import { useSelector } from "react-redux";
import { Button, Grid, Input, Spin } from "antd";
import { toast } from "react-hot-toast";
import { Formik } from "formik";
import moment from "moment";
import FacebookIntegration from "../../components/FacebookIntegration";
import GlobalDrawer from "../../components/GlobalDrawer";
import {
  disconnectRoom,
  joinRoom,
  onMessage,
  socket,
} from "../../hooks/socketService";
import { setLastConvoId } from "../../actions/actions";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { assignLeadInList } from "../../services/leadService";
import UltimatePlanInfo from "../../components/UltimatePlanInfo/UltimatePlanInfo";
import ChatLeftMenu from "./ChatLeftMenu";
import { EmptyChatScreen, LoaderContainer } from "./style";
import ChatDetailRightMenu from "./ChatDetailRightMenu";

const { useBreakpoint } = Grid;

const WhatsappInbox: React.FC = () => {
  // This hook will give us breakpoints
  const screens = useBreakpoint();
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  
  const dispatch = useDispatch();
  const boxRef = useRef<HTMLDivElement>(null);
  const [integrationStatus, setIntegrationStatus] = useState(false);
  const scrollToBottom = () => {
    if (boxRef.current) {
      boxRef.current.scrollTo({
        top: boxRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  
  const [assignLeadDrawer, setAssignLeadDrawer] = useState(false);
  const [usersArray, setUsersArray] = useState<any>([]);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [inputText, setInputText] = useState<string>("");

  const [updateList, setUpdateList] = useState<boolean>(false);
  const [lead, setLead] = useState<undefined | LeadDataI>();
  const [totalConversations, setTotalConversations] = useState(0);
  const [openForm, setOpenForm] = useState<null | "task" | "note">(null);
  const [labelData, setLabelData] = useState<any[]>([]);
  const [edit, setEdit] = useState<"" | "email" | "mobile" | "name">("");
  const [value, setValue] = useState("");
  const [searchInChat, setSearchInChat] = useState(false);
  const [showFbButton, isShowFbButton] = useState(false);
  const [filter, setFilter] = useState<{ [key: string]: string | boolean }>({});
  const [addChat, setAddChat] = useState(false);
  const [newMobileNumber, setNewMobileNumber] = useState("");
  const [from, setFrom] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [selectedFiles, setSelectedFiles] = useState<null | File[]>(null);
  const [searchMsgText, setSearchMsgText] = useState<string>("");
  const [currPage, setCurrPage] = useState<number>(1);
  const [assignLeadHeaderTitle, setAssignedLeadHeaderTitle] =
    useState<string>("Assign Lead");
  const [isIntegrated, setIsIntegrated] = useState(false);
  
  const [msgList, setMsgList] = useState<any>([]);

  const listInnerRef = useRef<any>();

  let users: any = [];

  const [userdata, setUserdata] = useState<any>({});

  const currentUser = useMemo(() => {
    let user = JSON.parse(
      localStorage.getItem("userData") || JSON.stringify({ firstName: "You" })
    );
    return user;
  }, []);

  const statusData = useMemo(() => {
    const { status } =
      JSON.parse(localStorage.getItem("user_preferences") || "") || {};
    return status?.reduce((prev: any, current: any) => {
      prev[current.value] = current;
      return prev;
    }, {});
  }, []);

  useEffect(() => {
    let local: any = window.localStorage?.getItem("userData");
    if (local) {
      setUserdata(JSON.parse(local));
    }
  }, []);

  // Get the token from your authentication process (e.g., after login)

  useEffect(() => {
    if (selectedUser && boxRef.current && msgList?.length) {
      setTimeout(() => {
        scrollToBottom();
      }, 500);
    }
  }, [selectedUser, boxRef.current, msgList]);

  useEffect(() => {
    onMessage((data: any) => {
      console.log("data>>>", data);
    });

    socket.on("message", (data: any) => {
      console.log("on message>>>", data);
    });

    socket.on("conversationList", (data: any) => {
      console.log("conversationList>>>", data);
    });
  }, []);

  const handleConnect = async (user: any) => {
    setSelectedUser(user);
    disconnectRoom();
    joinRoom(user?.name, user?.convoId);
    dispatch(setLastConvoId(user?.convoId));
  };

  const StateData = useSelector((state: any) => {
    return state?.rootReducers;
  });

  const teamMembers =
    StateData?.userData?.userDetails?.organizationEmployee?.map((item: any) => {
      return {
        value: item?._id,
        label: item?.firstName
          ? item?.firstName
          : "" + item?.lastName
          ? item?.lastName
          : "",
      };
    });

  const getDate = (timestamp: any) => timestamp?.split("T")[0];

  const fetchConversations = async (params: any = {}) => {
    try {
      setIsUserLoading(true);
      // Base pagination data
      const data = {
        orderBy: "_id",
        isAscending: false,
        page: currPage,
        perPage: 15,
      };
  
      // Build current filter params
      const currentParams = {
        page: currPage,
        perPage: 15,
        ...params  // Include any existing filter params
      };
  
      // Add any active filters from the filter state
      if (filter.search) currentParams["search"] = filter.search;
      for (const x of Object.keys(filter)) {
        if (x === "isRead") {
          currentParams["isRead"] = false;
        } else if (x === "isPinned") {
          currentParams["isPinned"] = true;
        } else if (x === "isAssigned") {
          currentParams["isAssigned"] = false;
        } else if (x === "isAssignedToMe") {
          currentParams["isAssignedToMe"] = true;
        }
      }
  
      const response = await getAllConversations(data, currentParams);
  
      let inboxList: any[] = [];
      inboxList = [...response.data.data];
  
      setTotalConversations(response.data.total);
  
      const groupedArr = _.groupBy(inboxList, (e) => getDate(e.lastMessageAt));
  
      const result: { title: String; data: any[] }[] = _.map(
        groupedArr,
        (value, key) => ({
          title: key,
          data: value.map((element) => ({
            ...element,
            id: element?._id,
            name: element?.lead?.name,
            mobile: element?.lead?.phone,
            lastMessageAt: element?.lastMessageAt,
            email: element?.lead?.email,
            userIcon: `${currentUser.bucketUrl}${element?.profile?.filePath}`,
            msg: element?.lastMessage?.message?.body,
            lead: element?.lead?._id,
            assignedTo: element?.assignedTo,
            timeStamp: moment(element?.lastMessage?.createdAt).format("LT"),
            msgStatus: element?.lastMessage?.status,
            convoId: element?.conversationId,
          })),
        })
      );
  
      // Handle pagination - append new data for subsequent pages
      setUsersArray(currPage === 1 ? result : [...usersArray, ...result]);
      setIsUserLoading(false);
  
      // Handle lastConvoId if exists
      if (StoreData?.userData?.lastConvoId) {
        const lastUserConv = users.find(
          (item: any) => item.convoId === StoreData?.userData?.lastConvoId
        );
        handleConnect(lastUserConv);
      }
  
    } catch (error) {
      console.log("inbox error>>>>", error);
      setIsUserLoading(false);
    }
  };

  useEffect(() => {
    if (lead) {
      let userPreferences = JSON.parse(
        localStorage.getItem("user_preferences") || ""
      );
      let tempArray = [];
      tempArray = StoreData?.user?.userPreferences?.labels
        ? StoreData?.user?.userPreferences?.labels
        : userPreferences?.labels;
      const updateArray = tempArray.map((obj: any) => {
        return {
          ...obj,
          isLabelChecked: lead?.label.includes(obj.value),
        };
      });
      setLabelData(updateArray);
    }
  }, [lead]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let params: any = {};
      if (filter.search) params["search"] = filter.search;
      for (const x of Object.keys(filter)) {
        if (x === "isRead") {
          params["isRead"] = false;
        } else if (x === "isPinned") {
          params["isPinned"] = true;
        } else if (x === "isAssigned") {
          params["isAssigned"] = false;
        } else if (x === "isAssignedToMe") {
          params["isAssignedToMe"] = true;
        }
      }
  
   
      // Now this single call will handle both pagination and filters
      fetchConversations(params);
    }, 500);
  
    return () => clearTimeout(delayDebounceFn);
  }, [filter, currPage]);



  useEffect(() => {
    const userIntigrationsList = JSON.parse(
      localStorage.getItem("userData") || ""
    ).userIntegrations;

    const findData = userIntigrationsList.find(
      (item: any) =>
        item?.integration?._id === "646b9286ae8ef73b33caec2e" && item?.isActive
    );

    setIntegrationStatus(!!findData);
    if (!findData) isShowFbButton(true);
  }, []);

  useEffect(() => {
    // Update body styles when the component mounts
    document.body.style.overflow = "hidden"; // Example: Disable scrolling

    // Clean up styles when the component unmounts
    return () => {
      document.body.style.overflow = ""; // Reset scrolling
    };
  }, []);

  const handleFilter = (input: any) => {
    const { name, value } = input.target;
    setFilter((preData) => ({ ...preData, [name]: value }));
  };

  const handleTagFilter = (value: string) => {
    if (filter[value]) {
        // Removing filter - fetch fresh data first
        setUsersArray([]); 
        setCurrPage(1);
        const newState = { ...filter };
        delete newState[value];
        setFilter(newState); // This will trigger useEffect to fetch new data
    } else {
        // Adding filter - current behavior is fine
        setUsersArray([]); 
        setCurrPage(1);
        setFilter({ ...filter, [value]: true });
    }
};
  

  const toggleAddChat = () => {
    setAddChat((prev: boolean) => !prev);
  };

  const addTaskHandler = () => setOpenForm("task");

  const addNoteHandler = () => setOpenForm("note");

  const onLabelChange = (e: any, name: string) => {
    setLabelData((prev) => {
      let updatedLabelData: any[] = [];
      for (let label of labelData) {
        updatedLabelData.push({
          ...label,
          isLabelChecked:
            name == label.value ? !label.isLabelChecked : label.isLabelChecked,
        });
      }
      return updatedLabelData;
    });
  };

  const editHandler = async (
    update: any = {},
    onSuccess: () => void = () => {},
    onErrors: () => void = () => {}
  ) => {
    const updateData = update || { [edit]: value };
    await updateProfile(updateData)
      .then(() => {
        onSuccess();
        toast.success("Profile updated successfully");
        setEdit("");
      })
      .catch(onErrors);
  };

  const handleAssignLead = async (leadId: string) => {
    if (!selectedUser) return;
    try {
      const data = {
        leadIds: [selectedUser.lead],
        assignToUser: leadId,
      };
      const response = await assignLeadInList(data);
      toast.success(response?.data?.message);
    } catch (err) {}
  };

  const handleAssignChat = async (leadId: string, item: any) => {
    if (!selectedUser) return;
    try {
      const data = {
        assignToIds: [leadId],
      };

      const response = await assignConversation(selectedUser?._id, data);

      if (response?.data) {
        let assignedData = response?.data?.data.assignToUsers?.[0];

        setSelectedUser({
          ...selectedUser,
          assignedTo: [...selectedUser.assignedTo, assignedData],
        });
      }
      toast.success(response?.data?.message);
    } catch (err) {}
  };

  const backHandler = () => {
    if (showUserDetails) {
      setShowUserDetails(false);
    } else {
      setSelectedUser(null);
    }
  };

  const searchInChatHandler = (text: string) => {};

  // Created this function for rendering input when user want to edit email/mobile/name
  const renderMiniForm = (
    name: any,
    currentValue: string,
    title: string,
    editable: boolean = true
  ) => {
    return (
      <div className="opt-in">
        <div style={{ flex: 1 }}>
          <label>{title}</label>
          {edit == name ? (
            <Formik
              initialValues={{ [name]: currentValue }}
              validate={(values) => {
                const formikErrors: any = {};
                if (!values[name]) {
                  formikErrors[name] = `Required`;
                }
                return formikErrors;
              }}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                editHandler(
                  values,
                  () => {
                    setSubmitting(false);
                  },
                  () => {
                    setSubmitting(false);
                    setErrors({ [name]: "Something went wrong" });
                  }
                );
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onSubmit={handleSubmit}
                >
                  <div>
                    <Input
                      name={name}
                      value={values[name]}
                      onChange={handleChange}
                      style={{ border: 0, borderRadius: "5px" }}
                    />
                    {errors[name] && (
                      <div
                        style={{
                          color: "#ff0000",
                          marginTop: "1px",
                          fontSize: "12px",
                        }}
                      >
                        {errors[name]}
                      </div>
                    )}
                  </div>
                  <div
                    style={{ display: "flex", gap: "2px", paddingLeft: "1rem" }}
                  >
                    <Button
                      onClick={() => setEdit("")}
                      style={{ color: "#ff0000", borderColor: "#ff0000" }}
                      icon={<i className="fa-regular fa-times"></i>}
                    />
                    <Button
                      // onClick={}
                      loading={isSubmitting}
                      htmlType="submit"
                      style={{ color: "#00df00", borderColor: "#00df00" }}
                      icon={<i className="fa-regular fa-check"></i>}
                    />
                  </div>
                </form>
              )}
            </Formik>
          ) : (
            <p>{currentValue}</p>
          )}
        </div>
        {edit !== name && editable && (
          <button
            onClick={() => {
              setEdit(name);
              setValue(currentValue);
            }}
          >
            <i className="fa-regular fa-pen"></i>
          </button>
        )}
      </div>
    );
  };

  const chatsData = useMemo(() => {
    return usersArray;
  }, [usersArray]);

  const addChatHandler = async (
    values: any,
    onSuccess: () => void = () => {},
    onError: () => void = () => {}
  ) => {
    try {
      await sendMessageToLead({
        phone: values.mobileNumber,
        type: "text",
        phoneId: "190076364199723",
        text: {
          body: "Hello!",
        },
      });
      await fetchConversations();
      toast.success("Chat added successfully");
      onSuccess();
      setAddChat(false);
    } catch (error) {
      onError();
    }
  };

  const onScrollListing = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 1 && !isUserLoading) {
        let totalConvo = usersArray?.reduce(
          (acc: number, item: any) => acc + item?.data?.length,
          0
        );
        if (totalConvo < totalConversations) {
          setCurrPage((prev) => prev + 1);
        }
      }
    }
  };

  // This variable will render chats(opted this way to make inbox responsive)
  const chats = useMemo(() => {
    return (
      <ChatLeftMenu
        usersArray={usersArray}
        handleFilter={handleFilter}
        filter={filter}
        setFilter={setFilter}
        handleTagFilter={handleTagFilter}
        addChat={addChat}
        addChatHandler={addChatHandler}
        setAddChat={setAddChat}
        handleConnect={handleConnect}
        selectedUser={selectedUser}
        onScrollListing={onScrollListing}
        listInnerRef={listInnerRef}
      />
    );
  }, [
    chatsData,
    errors.newMobileNumber,
    newMobileNumber,
    addChat,
    selectedUser,
    usersArray,
  ]);

  const updateMessageList = (newMessage: any) => {
    let clonedMessageArr = [...usersArray?.map((e: any) => e.data)];

    clonedMessageArr = clonedMessageArr?.flat();

    if (newMessage && selectedUser) {
      clonedMessageArr = clonedMessageArr.map((e: any) => {
        if (e._id === selectedUser?.id) {
          return {
            ...e,
            lastMessageAt: newMessage?.createdAt,

            lastMessage: {
              ...e.lastMessage,
              createdAt: newMessage?.createdAt,
              message: {
                ...e.lastMessage.message,
                body: newMessage?.message.body,
              },
            },
            msg: newMessage?.message.body,
          };
        }
        return e;
      });
    }

    const groupedArr = _.groupBy(clonedMessageArr, (e) =>
      getDate(e.lastMessageAt)
    );

    const result: { title: String; data: any[] }[] = _.map(
      groupedArr,
      (value, key) => ({
        title: key,
        data: _.sortBy(value, "lastMessageAt").reverse(),
      })
    );

    setUsersArray(result);
  };

  const chat = useMemo(() => {
    return (
      <div style={{ flex: 2, height: "100%" }} className="col px-0">
        <div className="chat-window-wrapper">
          <div className="inboxChatHead">
            <InboxChatHead
              setSearch={setSearchInChat}
              search={searchInChat}
              setShowDetails={setShowUserDetails}
              users={selectedUser}
              searchInChatHandler={searchInChatHandler}
              onChangeSearch={(e: string) => setSearchMsgText(e)}
              onDelete={() => {
                setCurrPage(1);
                setTimeout(() => {
                  fetchConversations();
                }, 500);
              }}
            />
          </div>
          <div className="inboxChat">
            <div className="flex-fill position-relative">
              <div
                className="convoBody"
                ref={boxRef}
                id="scrollable-box"
              >
                <div className="spacer"></div>
                <IncomingMsg
                  users={selectedUser}
                  setLead={setLead}
                  inputText={inputText}
                  scrollToBottom={scrollToBottom}
                  searchMsgText={searchMsgText}
                  onNewMessageReceived={updateMessageList}
                  setMsgList={setMsgList}
                />
              </div>
            </div>
            <div className="typing-area-wrapper">
              <TypingActionArea
                setSelectedFiles={setSelectedFiles}
                users={selectedUser}
                setInputText={setInputText}
                setUpdateList={setUpdateList}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }, [selectedUser, searchInChat, inputText, searchMsgText]);

  // This variable will render selected chat user details(opted this way to make inbox responsive)
  const selectedUserDetails = useMemo(() => {
    if (!selectedUser) {
      return <></>;
    }

    return (
      <ChatDetailRightMenu
        setShowUserDetails={setShowUserDetails}
        selectedUser={selectedUser}
        addTaskHandler={addTaskHandler}
        addNoteHandler={addNoteHandler}
        renderMiniForm={renderMiniForm}
        setAssignLeadDrawer={setAssignLeadDrawer}
        setAssignedLeadHeaderTitle={setAssignedLeadHeaderTitle}
        lead={lead}
        statusData={statusData}
        labelData={labelData}
        onLabelChange={onLabelChange}
        from={from}
        setLead={setLead}
      />
    );
  }, [selectedUser, labelData, statusData, edit, value, lead, screens]);

  const emptyChatScreen = useMemo(() => {
    return (
      <EmptyChatScreen>
        <img src="/assets/img/emptyconvo.png" alt="No chat selected" />
        <h5 className="text-dark fm-gilroy fs-5">
          Select a conversation to start chatting
        </h5>
      </EmptyChatScreen>
    );
  }, []);

  if (userdata?.subscriptionPlanType?.toLowerCase() !== "ultimate") {
    return (
      <div id="main" className="main">
        <Header />
        <UltimatePlanInfo />
      </div>
    );
  }

  return (
    <div id="main" className="main mt-0 px-0 inboxPage">
      <Header />
      {showFbButton ? (
        <FacebookIntegration
          onSuccess={() => {
            isShowFbButton(false);
            setCurrPage(1);
          }}
        />
      ) : (
        <>
          {isUserLoading && currPage === 1 && (
            <LoaderContainer>
              <div className="h-100 d-flex align-items-center justify-content-center">
                <h5 className="text-dark fm-gilroy fs-6 text-center">
                  <Spin size="large" tip="Loading..." spinning={true}></Spin>
                </h5>
              </div>
            </LoaderContainer>
          )}

<section className="auto-section-1 pt-0">
  <div className="d-flex justify-content-between">
    <div className="align-items-center auto-section-1-sub-1">
      {!screens.md && selectedUser && (
        <button style={{ marginRight: "1rem" }} onClick={backHandler}>
          <i className="fa-regular fa-chevron-left"></i>
        </button>
      )}
      <label style={{ fontSize: 20, marginBottom: '16px' }}>
        Inbox({totalConversations})
      </label>
     
    </div>
  </div>
</section>

          <section className="flex-fill">
            <div className="row h-100 m-0">
              {!screens.md ? (
                // Mobile
                <>
                  {selectedUser
                    ? showUserDetails
                      ? selectedUserDetails
                      : chat
                    : chats}
                </>
              ) : !screens.lg ? (
                // Tablets
                <>
                  {" "}
                  {chats}
                
{selectedUser ? (
  <>
    <div style={{ 
      flex: 2, 
      marginRight: '280px',  // Add fixed margin for right panel
      height: "75vh" 
    }} className="col px-0">
      {chat}
    </div>
    <div style={{
      position: 'fixed',
      right: 0,
      top: '60px',  // Match header height
      width: '280px',
      height: 'calc(100vh - 60px)',
      zIndex: 10
    }}>
      {selectedUserDetails}
    </div>
  </>
) : (
  <div style={{ flex: 2 }}>{emptyChatScreen}</div>
)}
                </>
              ) : (
                // Laptops
                <>
                  {!isUserLoading &&
                  !usersArray?.length &&
                  !Object.keys(filter)?.length ? (
                    <div className="h-100 d-flex align-items-center justify-content-center ">
                      <h5 className="text-dark fm-gilroy fs-6 text-center">
                        {isUserLoading ? "" : "No results found"}
                      </h5>
                    </div>
                  ) : (
                    chats
                  )}
                  {selectedUser ? (
                    <>
                      {chat}
                      {selectedUserDetails}
                    </>
                  ) : (
                    <div style={{ flex: 2 }}>{emptyChatScreen}</div>
                  )}
                </>
              )}
            </div>
          </section>
        </>
      )}

      <TaskDrawer
        openWithHeader={!!lead && !!openForm}
        setOpenWithHeader={() => {
          setOpenForm(null);
        }}
        onTaskDelete={() => {}}
        showDelete={false}
        drawerTitle={`Add ${openForm || ""}`}
        size="xs"
      >
        {openForm == "task" ? (
          <CreateTaskForm
            leadId={lead?._id}
            updateTaskValue={{}}
            action="add"
            status={""}
            drawerClose={() => {
              setOpenForm(null);
            }}
            fetchTaskStatusFollowUps={() => {}}
          />
        ) : (
          <CreateNoteForm
            leadIds={[{ id: lead?._id }]}
            handleNotesSubmit={() => {
              setOpenForm(null);
            }}
          />
        )}
      </TaskDrawer>

      <GlobalDrawer
        openWithHeader={assignLeadDrawer}
        setOpenWithHeader={() => {
          setAssignLeadDrawer(false);
        }}
        drawerTitle={assignLeadHeaderTitle || "Assign Lead"}
        size="xs"
      >
        <label className="form-label">Select team member</label>
        <div>
          {teamMembers?.map((item: any, ix: number) => {
            return (
              <button
                key={ix}
                onClick={() => {
                  setAssignLeadDrawer(false);

                  if (assignLeadHeaderTitle === "Assign Chat") {
                    handleAssignChat(item.value, item);
                  } else {
                    handleAssignLead(item.value);
                  }
                }}
                className="d-block bg-white w-100 p-3 rounded mb-2"
                style={{ margin: "8px 0px", textAlign: "left" }}
              >
                {item.label}
              </button>
            );
          })}
        </div>
      </GlobalDrawer>
    </div>
  );
};

export default WhatsappInbox;
