import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings, LogOut } from 'lucide-react';
import { APP_VERSION } from '../config/config';

interface UserProfileDropdownProps {
  userdata: {
    firstName?: string;
    lastName?: string;
    organization?: {
      name: string;
    };
    countryCode?: string;
    phone?: string;
    bucketUrl?: string;
    profile?: {
      filePath?: string;
    };
    role?: {
      name: string;
    };
  };
  onSwitchBusiness: () => void;
}

const UserProfileDropdown: React.FC<UserProfileDropdownProps> = ({
  userdata,
  onSwitchBusiness
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 1000);
  };


  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getInitials = () => {
    const first = userdata.firstName?.[0] || '';
    const last = userdata.lastName?.[0] || '';
    return (first + last).toUpperCase();
  };

  return (
    <div className="profile-section" ref={dropdownRef} style={{ 
      padding: '4px',
      borderTop: '1px solid #e9ecef',
      position: 'relative',
      width: '100%',
      marginTop: 'auto'
    }}>
      <button 
        onClick={() => setIsOpen(!isOpen)}
        style={{
          padding: '8px 12px',
          borderRadius: '8px',
          cursor: 'pointer',
          transition: 'all 0.2s ease',
          border: '1px solid #e9ecef',
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <div style={{
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          overflow: 'hidden',
          flexShrink: 0
        }}>
          {!userdata?.bucketUrl || !userdata?.profile?.filePath ? (
            <div style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f8f9fa',
              fontSize: '14px',
              color: '#495057'
            }}>
              {getInitials()}
            </div>
          ) : (
            <img
              src={userdata.bucketUrl + userdata.profile.filePath}
              alt="Profile"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          )}
        </div>

        <div className="ms-2 text-start overflow-hidden" style={{
          flex: 1
        }}>
          <div style={{
            fontSize: '13px',
            fontWeight: 500,
            color: '#212529',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
            {userdata.firstName} {userdata.lastName}
          </div>
          <div style={{
            fontSize: '11px',
            color: '#6c757d',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
            {userdata?.organization?.name}
          </div>
        </div>

        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            marginLeft: '8px',
            transform: isOpen ? 'rotate(180deg)' : 'none',
            transition: 'transform 0.2s ease'
          }}
        >
          <path
            d="M1.175 0L5 3.71159L8.825 0L10 1.14825L5 6L0 1.14825L1.175 0Z"
            fill="#6c757d"
          />
        </svg>
      </button>

      {isOpen && (
        <div style={{
          position: 'fixed',
          left: '208px',
          bottom: '8px',
          width: '280px',
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          border: '1px solid #e9ecef',
          zIndex: 1000
        }}>
          {/* Caret/pointer */}
          <div style={{
            position: 'absolute',
            left: '-6px',
            top: '50%',
            transform: 'translateY(-50%) rotate(-45deg)',
            width: '12px',
            height: '12px',
            backgroundColor: '#fff',
            border: '1px solid #e9ecef',
            borderRight: 'none',
            borderBottom: 'none',
            boxShadow: '-2px -2px 2px rgba(0,0,0,0.05)'
          }} />

          <div className="p-3">
            <div className="d-flex align-items-start gap-3">
              <div style={{
                width: '48px',
                height: '48px',
                borderRadius: '50%',
                overflow: 'hidden',
                flexShrink: 0
              }}>
                {!userdata?.bucketUrl || !userdata?.profile?.filePath ? (
                  <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#f8f9fa',
                    fontSize: '18px',
                    color: '#495057'
                  }}>
                    {getInitials()}
                  </div>
                ) : (
                  <img
                    src={userdata.bucketUrl + userdata.profile.filePath}
                    alt="Profile"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                )}
              </div>
              
              <div className="flex-grow-1">
                <h6 className="mb-1" style={{ fontSize: '14px', fontWeight: 600 }}>
                  {userdata.firstName} {userdata.lastName}
                </h6>
                <p className="mb-1" style={{ fontSize: '12px', color: '#6c757d' }}>
                  {userdata.role?.name ? userdata.role.name.replace('_', ' ') : 'User'}
                </p>
                <p className="mb-0" style={{ fontSize: '12px', color: '#6c757d' }}>
                  {userdata?.organization?.name}
                </p>
              </div>
            </div>

            <div className="mt-2" style={{ fontSize: '12px', color: '#6c757d' }}>
              + {userdata?.countryCode} {userdata?.phone}
            </div>
          </div>

          <div style={{
            borderTop: '1px solid #e9ecef',
            borderBottom: '1px solid #e9ecef',
            padding: '8px 0'
          }}>
            <button
              onClick={() => {
                onSwitchBusiness();
                setIsOpen(false);
              }}
              className="dropdown-item d-flex align-items-center px-3 py-2"
              style={{ fontSize: '14px' }}
            >
              <Settings size={16} className="me-2 opacity-50" />
              Switch Business Account
            </button>

            <button
              onClick={handleLogout}
              className="dropdown-item d-flex align-items-center px-3 py-2"
              style={{ fontSize: '14px', color: '#dc3545' }}
            >
              <LogOut size={16} className="me-2" />
              Logout
            </button>
          </div>

          <div className="p-2 text-center" style={{ fontSize: '12px', color: '#6c757d' }}>
            Version {APP_VERSION}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfileDropdown;