import React, { useState } from 'react';
import { 
  Container, 
  Card, 
  Form, 
  Button, 
  ListGroup,
  Badge,
  Alert
} from 'react-bootstrap';

// Types
interface Rule {
  id: string;
  name: string;
  source: string;
  createdBy: string;
  status: boolean;
}

interface RuleFormData {
  name: string;
  source: string;
  fieldName: string;
  operator: string;
  values: string[];
  currentValue: string;
}

interface RulesListProps {
  rules: Rule[];
  onAddNew: () => void;
  onToggleStatus: (id: string) => void;
  onDelete: (id: string) => void;
}

interface RuleFormProps {
  onCancel: () => void;
  onSave: (rule: RuleFormData) => void;
}

// Constants
const MOCK_RULES: Rule[] = [
  {
    id: '1',
    name: 'new test',
    source: 'Lead Form',
    createdBy: 'Vivek actuve magnabox',
    status: true
  },
  {
    id: '2',
    name: 'Hdjd',
    source: 'Lead Form',
    createdBy: 'Vivek actuve magnabox',
    status: false
  }
];

const SOURCES = [
  { value: 'lead_form', label: 'Lead Form' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'indiamart', label: 'IndiaMART' },
  { value: 'tradeindia', label: 'TradeIndia' },
  { value: 'csv', label: 'CSV' }
] as const;

const OPERATORS = [
  { value: 'eq', label: 'Equal' },
  { value: 'gt', label: 'Greater Than' },
  { value: 'lt', label: 'Less Than' },
  { value: 'ne', label: 'Not Equal' }
] as const;

export default function DistributionRules(): JSX.Element {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [rules, setRules] = useState<Rule[]>(MOCK_RULES);

  const handleToggleStatus = (id: string) => {
    setRules(rules.map(rule => 
      rule.id === id ? {...rule, status: !rule.status} : rule
    ));
  };

  const handleDelete = (id: string) => {
    setRules(rules.filter(rule => rule.id !== id));
  };

  const handleSave = (formData: RuleFormData) => {
    const newRule: Rule = {
      id: String(rules.length + 1),
      name: formData.name,
      source: SOURCES.find(s => s.value === formData.source)?.label || formData.source,
      createdBy: 'Current User',
      status: true
    };
    setRules([...rules, newRule]);
    setShowForm(false);
  };

  return (
    <Container fluid className="p-3">
      {!showForm ? (
        <RulesList 
          rules={rules} 
          onAddNew={() => setShowForm(true)}
          onToggleStatus={handleToggleStatus}
          onDelete={handleDelete}
        />
      ) : (
        <RuleForm 
          onCancel={() => setShowForm(false)}
          onSave={handleSave}
        />
      )}
    </Container>
  );
}

function RulesList({ rules, onAddNew, onToggleStatus, onDelete }: RulesListProps): JSX.Element {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Manage Distribution</h2>
      </div>

      <ListGroup>
        {rules.map((rule) => (
          <ListGroup.Item key={rule.id} className="mb-2">
            <div className="d-flex justify-content-between align-items-start">
              <div>
                <h5>{rule.name}</h5>
                <p className="text-muted mb-1">{rule.source}</p>
                <small className="text-muted">Created By: {rule.createdBy}</small>
              </div>
              <div className="d-flex align-items-center gap-3">
                <Button 
                  variant="link" 
                  className="text-danger p-0"
                  onClick={() => onDelete(rule.id)}
                >
                  Delete
                </Button>
                <Form.Check
                  type="switch"
                  checked={rule.status}
                  onChange={() => onToggleStatus(rule.id)}
                />
              </div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <Button 
        variant="primary" 
        className="position-fixed bottom-0 end-0 m-4 rounded-circle" 
        style={{ width: '60px', height: '60px' }}
        onClick={onAddNew}
      >
        +
      </Button>
    </div>
  );
}

function RuleForm({ onCancel, onSave }: RuleFormProps): JSX.Element {
  const [formData, setFormData] = useState<RuleFormData>({
    name: '',
    source: '',
    fieldName: '',
    operator: 'eq',
    values: [],
    currentValue: ''
  });
  const [error, setError] = useState<string>('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (!formData.name.trim()) {
      setError('Rule name is required');
      return;
    }
    if (!formData.source) {
      setError('Please select a lead source');
      return;
    }
    
    onSave(formData);
  };

  const addValue = () => {
    if (formData.currentValue.trim()) {
      setFormData(prev => ({
        ...prev,
        values: [...prev.values, prev.currentValue.trim()],
        currentValue: ''
      }));
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-4">
        <Button variant="link" onClick={onCancel} className="p-0 me-3">
          Back
        </Button>
        <h2>Create Distribution Rule</h2>
      </div>

      {error && (
        <Alert variant="danger" className="mb-4">
          {error}
        </Alert>
      )}

      <Form onSubmit={handleSubmit}>
        <Card className="mb-4">
          <Card.Body>
            <Form.Group className="mb-3">
              <Form.Label>Rule Name*</Form.Label>
              <Form.Control
                type="text"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                placeholder="Enter rule name"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Lead Source*</Form.Label>
              <Form.Select
                value={formData.source}
                onChange={(e) => setFormData(prev => ({ ...prev, source: e.target.value }))}
              >
                <option value="">Select source</option>
                {SOURCES.map(source => (
                  <option key={source.value} value={source.value}>
                    {source.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Field Name*</Form.Label>
              <Form.Control
                type="text"
                value={formData.fieldName}
                onChange={(e) => setFormData(prev => ({ ...prev, fieldName: e.target.value }))}
                placeholder="Enter field name"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Values*</Form.Label>
              <div className="d-flex gap-2 mb-2">
                <Form.Control
                  type="text"
                  value={formData.currentValue}
                  onChange={(e) => setFormData(prev => ({ ...prev, currentValue: e.target.value }))}
                  placeholder="Type and press Add"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      addValue();
                    }
                  }}
                />
                <Button onClick={addValue}>Add</Button>
              </div>
              <div className="d-flex flex-wrap gap-2">
                {formData.values.map((value, index) => (
                  <Badge 
                    key={index} 
                    bg="primary" 
                    className="p-2"
                  >
                    {value}
                    <Button
                      variant="link"
                      className="text-white p-0 ms-2"
                      onClick={() => setFormData(prev => ({
                        ...prev,
                        values: prev.values.filter((_, i) => i !== index)
                      }))}
                    >
                      ×
                    </Button>
                  </Badge>
                ))}
              </div>
            </Form.Group>

            <Card bg="light" className="mt-4">
              <Card.Body>
                <h6>Rule Preview:</h6>
                <p className="mb-0">
                  When lead source is{' '}
                  <strong>
                    {SOURCES.find(s => s.value === formData.source)?.label || '...'}
                  </strong>
                  {' '}and{' '}
                  <strong>{formData.fieldName || '...'}</strong>
                  {': '}
                  <strong className="text-primary">
                    {formData.values.join(', ') || '...'}
                  </strong>
                </p>
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>

        <div className="d-flex justify-content-end gap-2">
          <Button variant="outline-secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Save Rule
          </Button>
        </div>
      </Form>
    </div>
  );
}