import React, { useState, useEffect, useCallback, useMemo, ChangeEvent, useRef } from 'react';
import { Eye, PlusCircle, Save } from 'lucide-react';
import ReactQuill from 'react-quill';
import GlobalDrawer from '../../components/GlobalDrawer';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import styled from 'styled-components';
import  PopupComponent  from '../../components/PopupComponent';
import EmailPreview from './PreviewModal';
import "react-quill/dist/quill.snow.css";
import GenericConfirmationModal from '../../components/GenericConfirmationModal';

interface EmailData {
  title: string;
  tags: string[];
  subject: string;
  message: string;
  type?: string;
  files?: File[];
  personalizedFields?: Array<{value: string, label: string}>;
}

interface PersonalizationField {
  value: string;
  label: string;
  type?: string;
}


interface Tag {
  name: string;
  value: string;
}

interface EmailDashboardProps {
  openEmailBox: boolean;
  setOpenEmailBox: (value: boolean) => void;
  activeId: string;
  handleFormSubmit: (data: EmailData) => Promise<void>;
  messageTag: Tag[];
  initialData: EmailData;
  onSuccessfulSubmit?: () => Promise<void>; 
  
  availableFields?: PersonalizationField[];
}

// Styled Components
const StyledEmailEditor = styled.div`
  font-family: "Inter", sans-serif;
  background-color: #f8f9fa;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  padding: 20px;
  width: 100%;
  min-width: 800px;
`;

const FormField = styled.div`
  margin-bottom: 16px;
  
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  margin-right: 48px;
`;

const Button = styled.button<{ primary?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: 8px;

  ${(props) =>
    props.primary
      ? `
    background-color: #3498db;
    color: white;
    &:hover {
      background-color: #2980b9;
    }
    svg {
      color: white;
    }
  `
      : `
    background-color: #e9ecef;
    color: #495057;
    &:hover {
      background-color: #dee2e6;
    }
  `}

  svg {
    margin-right: 6px;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.2s ease;
  background: #fff;

  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }

  &::placeholder {
    color: #6c757d;
  }

  &:hover {
    border-color: #adb5bd;
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
  padding: 4px;
`;

const StyledTag = styled.span<{ active: boolean }>`
  padding: 6px 16px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: ${(props) => (props.active ? "#3498db" : "#f0f2f5")};
  color: ${(props) => (props.active ? "white" : "#4b5563")};
  text-transform: capitalize;
  margin: 4px;
  font-weight: 500;
  letter-spacing: 0.3px;
  box-shadow: ${(props) =>
    props.active ? "0 2px 4px rgba(52,152,219,0.2)" : "none"};

  &:hover {
    background-color: ${(props) => (props.active ? "#2980b9" : "#e5e7eb")};
    transform: translateY(-1px);
  }
`;

const StyledReactQuill = styled(ReactQuill)`
  .ql-container {
    font-size: 14px;
    background: #fff;
    border-color: #ced4da;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 200px;
  }
  
  .ql-editor {
    min-height: 200px;
    font-size: 14px;
    line-height: 1.5;
  }

  .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #fff;
    border-color: #ced4da;
  }
`;

const EmailDashboard: React.FC<EmailDashboardProps> = ({
  openEmailBox,
  setOpenEmailBox,
  activeId,
  handleFormSubmit,
  messageTag,
  initialData,
  onSuccessfulSubmit,
  availableFields = [],
}) => {
  console.log('EmailDashboard rendered:', { openEmailBox, activeId, initialData });

  // State management
  const [emailData, setEmailData] = useState<EmailData>(initialData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isFieldDrawerOpen, setIsFieldDrawerOpen] = useState(false);
  const editorRef = useRef<ReactQuill | null>(null);
  const [previewData, setPreviewData] = useState<{
    isOpen: boolean;
    subject: string;
    content: string;
    attachments?: File[];
  }>({
    isOpen: false,
    subject: '',
    content: '',
    attachments: undefined
  });

  // Reset form when initialData changes
  useEffect(() => {
    console.log('EmailDashboard useEffect - initialData changed:', initialData);
    if (openEmailBox) {
      setEmailData(initialData);
      setHasUnsavedChanges(false);
    }
  }, [initialData, openEmailBox]);

  const reduxFields = useSelector((state: any) => 
    state?.rootReducers?.userData?.userDetails?.userPreference?.customForm?.map(
      (item: any) => ({
        value: item?.value,
        label: item?.name,
        type: item?.type,
      })
    ) || []
  );

  const handleFieldInsertion = useCallback((field: PersonalizationField) => {
    console.log('Field Insertion Called:', { field, editorRef: editorRef.current });

    if (editorRef.current) {
      console.log('Editor Ref Exists');
      try {
        // Use optional chaining and nullish coalescing
        const quill = editorRef.current.getEditor?.() ?? null;
        console.log('Quill Editor:', quill);

        if (quill) {
          console.log('Quill Methods Available');
          
          const range = quill.getSelection();
          console.log('Current Selection Range:', range);

          if (range) {
            quill.insertText(range.index, `{{${field.value}}}`, 'user');
            console.log('Text Inserted Successfully');
          } else {
            console.warn('No selection range found, inserting at end');
            const textLength = quill.getText().length;
            quill.insertText(textLength, `{{${field.value}}}`, 'user');
          }
        } else {
          console.error('Quill methods not available');
        }
      } catch (error) {
        console.error('Error in field insertion:', error);
      }
    } else {
      console.error('Editor Ref is Null');
    }

    // Always try to close the drawer and mark changes
    setIsFieldDrawerOpen(false);
    setHasUnsavedChanges(true);
  }, []);

  // Form validation
  const validateForm = useCallback(() => {
    const errors: string[] = [];
    if (!emailData.title.trim()) errors.push("Title is required");
    if (!emailData.subject.trim()) errors.push("Subject is required");
    if (!emailData.message.trim()) errors.push("Email content is required");
    return errors;
  }, [emailData]);

  // Handle input changes
  const handleInputChange = useCallback((key: keyof EmailData, value: string | string[]) => {
    console.log('handleInputChange:', { key, value });
    setEmailData(prev => ({ ...prev, [key]: value }));
    setHasUnsavedChanges(true);
  }, []);

  // Handle tag selection
  const handleTagToggle = useCallback((tagValue: string) => {
    console.log('handleTagToggle:', tagValue);
    setEmailData(prev => ({
      ...prev,
      tags: [tagValue],
    }));
    setHasUnsavedChanges(true);
  }, []);


  const handleConfirmClose = useCallback(() => {
    setOpenEmailBox(false);
  }, [setOpenEmailBox]);

  // Handle preview toggle
  const handlePreviewToggle = useCallback(() => {
    setPreviewData({
      isOpen: !previewData.isOpen,
      subject: emailData.subject,
      content: emailData.message,
      attachments: emailData.files
    });
  }, [emailData, previewData.isOpen]);

  // Handle save operation
  const handleSave = useCallback(async () => {
    console.log('handleSave called:', emailData);
    const errors = validateForm();
    if (errors.length > 0) {
      errors.forEach(error => toast.error(error));
      return;
    }

    setIsSubmitting(true);
    try {
      const dataToSubmit: EmailData = {
        ...emailData,
        type: 'email_template',
      };

      await handleFormSubmit(dataToSubmit);
      toast.success('Email template saved successfully!');
      setHasUnsavedChanges(false);
      setOpenEmailBox(false);
      
      // Call the refresh function if it exists
      if (onSuccessfulSubmit) {
        await onSuccessfulSubmit();
      }
    } catch (error) {
      console.error('Error saving template:', error);
      if (error instanceof Error) {
        toast.error(`Failed to save email template: ${error.message}`);
      } else {
        toast.error('Failed to save email template. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  }, [emailData, handleFormSubmit, setOpenEmailBox, validateForm, onSuccessfulSubmit]);

  // Handle close with unsaved changes
  const handleClose = useCallback(() => {
    if (hasUnsavedChanges) {
      setShowConfirmModal(true);
    } else {
      setOpenEmailBox(false);
    }
  }, [hasUnsavedChanges]);

  // ReactQuill modules configuration
  const modules = useMemo(() => ({
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
  }), []);

  // Header actions for PopupComponent
  const headerActions = useMemo(() => (
    <ButtonGroup>
      <Button onClick={handlePreviewToggle}>
        <Eye size={16} /> Preview
      </Button>
      <Button primary onClick={handleSave} disabled={isSubmitting}>
        {isSubmitting ? (
          "Saving..."
        ) : (
          <>
            <Save size={16} /> Save Template
          </>
        )}
      </Button>
    </ButtonGroup>
  ), [handlePreviewToggle, handleSave, isSubmitting]);

  return (
    <>
      <PopupComponent 
        isOpen={openEmailBox}
        onClose={handleClose}
        title={activeId ? "Edit Email Template" : "Add Email Template"}
        size="lg"
        loading={isSubmitting}
        headerActions={headerActions}
      >
        <StyledEmailEditor>
          <Content>
            <FormField>
              <label>Title</label>
              <Input
                type="text"
                placeholder="Enter title"
                value={emailData.title}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  console.log('Title changed:', e.target.value);
                  handleInputChange("title", e.target.value);
                }}
              />
            </FormField>

            <FormField>
              <label>Tags</label>
              <TagContainer>
                {messageTag.map((tag) => (
                  <StyledTag
                    key={tag.value}
                    active={emailData.tags[0] === tag.value}
                    onClick={() => handleTagToggle(tag.value)}
                  >
                    {tag.name.toLowerCase().replace(/_/g, " ")}
                  </StyledTag>
                ))}
              </TagContainer>
            </FormField>

            <FormField>
              <label>Subject</label>
              <Input
                type="text"
                placeholder="Enter subject"
                value={emailData.subject}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange("subject", e.target.value)}
              />
            </FormField>

        
            <FormField>
  <div style={{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    marginBottom: '8px' 
  }}>
    <label>Message</label>
    <Button 
      type="button"
      onClick={() => setIsFieldDrawerOpen(true)}
      style={{ 
        display: 'flex',
        alignItems: 'center',
        padding: '6px 12px',
        fontSize: '12px',
        backgroundColor: '#f0f0f0',
        color: '#333',
        border: '1px solid #ddd',
        borderRadius: '4px',
        gap: '6px'
      }}
    >
      <PlusCircle size={14} /> Insert Personalization Field
    </Button>
  </div>
  <StyledReactQuill
    ref={editorRef}
    value={emailData.message}
    onChange={(content) => {
      console.log('Quill Content Changed:', content);
      handleInputChange("message", content);
    }}
    modules={modules}
    placeholder="Compose your email..."
  />
</FormField>
          </Content>
        </StyledEmailEditor>
      </PopupComponent>

      {previewData.isOpen && (
        <EmailPreview
          isOpen={previewData.isOpen}
          onClose={() => setPreviewData(prev => ({ ...prev, isOpen: false }))}
          subject={previewData.subject}
          content={previewData.content}
          attachments={previewData.attachments}
        />
        
      )}

     <GenericConfirmationModal
    showModal={showConfirmModal}
    toggleModal={() => setShowConfirmModal(false)}
    onConfirmation={handleConfirmClose}
    title="Unsaved Changes"
    message="You have unsaved changes. Are you sure you want to close?"
    confirmText="Yes, Close"
    cancelText="Cancel"
    isWarning={true}
  />
 

 <PopupComponent
  isOpen={isFieldDrawerOpen}
  onClose={() => setIsFieldDrawerOpen(false)}
  title="Insert Personalization Field"
  size="sm"
>
  <div 
    style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      maxHeight: '400px', 
      overflowY: 'auto',
      overflowX: 'hidden', // Prevent horizontal scrolling
      width: '100%' 
    }}
  >
    {(availableFields.length > 0 ? availableFields : reduxFields).map((field: any) => (
      <Button
        key={field.value}
        onClick={() => handleFieldInsertion({
          value: field.value,
          label: field.label || field.name
        })}
        style={{ 
          display: 'block', 
          width: '100%', 
          textAlign: 'left',
          marginBottom: '8px',
          padding: '10px',
          background: '#f0f0f0',
          border: 'none',
          borderRadius: '4px'
        }}
      >
        {field.label || field.name}
      </Button>
    ))}
  </div>
</PopupComponent>
</>

  );
};

export default EmailDashboard;