import React, { useEffect, useState, useCallback } from "react";
import toast, { Toaster } from "react-hot-toast";
import CreateTaskForm from "../../components/createTaskForm";
import DrawerComponent from "../../components/drawer";
import Header from "../../components/header";
import TasksTable from "../../components/tasksTable";
import { getAllFollowUps, taskById } from "../../services/followupService";
import { bulkOperationTasks, deleteTask } from "../../services/taskService";
import InfiniteScroll from "react-infinite-scroll-component";
import "./follwups.scss";
import FilterIcon from "../../assets/images/filter.svg";
import ReloadIcon from "../../assets/images/reload.svg";
import TaskFilter from "./TaskFilter";
import LeadDetails from "../leads/leadDetails";
import { LeadDataI } from "../leads/leads";
import { useSelector } from "react-redux";
import { activityById } from "../../services/activityService";
import { uploadFiles } from "../../services/utilityService";
import { leadFileList } from "../../actions/actions";
import { useDispatch } from "react-redux";
import LeadMenu from "./LeadMenu/leadMenu";
import { TbWindowMaximize } from "react-icons/tb";
import { TaskQueryParameters, initialQueryParams } from "../../utils/types";
import BackToTop from "../../components/BackToTop";
import { Spin } from "antd";
import GlobalDrawer from "../../components/GlobalDrawer";
import TaskDrawer from "../common/TaskDrawer";
import moment from "moment";
import type { ITaskData } from './types';
import TaskGrid from "./TaskGrid";
import TaskBulkActionsBar from "./TaskBulkActionsBar";
import { AlertCircle, Calendar, CheckCircle, Clock, Filter, RotateCw } from "lucide-react";
import TaskFormPopupWrapper from "../leads/TaskFormPopupWrapper;";

const FollowUps: React.FC = () => {
  const [todayTask, setTodayTask] = useState<ITaskData[]>([]);
  const [overdueTask, setOverdueTask] = useState([]);
  const [upcomingTask, setUpcomingTask] = useState([]);
  const [completedTask, setCompletedTask] = useState([]);
  const [status, setStatus] = useState<
    "today" | "upcoming" | "overdue" | "completed"
  >("today");
  const [openWithHeader, setOpenWithHeader] = React.useState<boolean>(false);
  
  const [action, setAction] = React.useState<string>("");
  const [selectedTask, setSelectedTask] = useState<string>("");
  const [updateTaskValue, setUpdateTaskValue] = useState<any>({});
  const [taskTabCount, setTaskTabCount] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTasks, setTotalTasks] = useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [openFilterMenu, setOpenFilterMenu] = useState<boolean>(false);
  const [selectedLead, setSelectedLead] = useState<LeadDataI>();
  const [leadId, setLeadId] = useState<any>();
  const [leadLabelData, setLeadLabelData] = useState<Array<string>>([]);
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  const [selectedTaskKeys, setSelectedTaskKeys] = useState<React.Key[]>([]);
const [selectedTasks, setSelectedTasks] = useState<ITaskData[]>([]);
  const [paramState, setParamState] = useState<TaskQueryParameters>({});
 
  const [isTaskFormOpen, setIsTaskFormOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);

  const [bulkTaskIds, setBulkTaskIds] = useState<string[]>([]);
const [confirmationCallback, setConfirmationCallback] = useState<(value: boolean) => void>(() => {});

  const [openLeadDrawer, setOpenLeadDrawer] = useState<boolean>(false);

 
  let userPreferences: any = {};

  try {
    userPreferences = JSON.parse(
      localStorage.getItem("user_preferences") || ""
    );
  } catch (e) {
    userPreferences = {};
  }

  useEffect(() => {
    console.log('Selected tasks changed:', {
      count: selectedTaskKeys.length,
      tasks: selectedTasks
    });
  }, [selectedTaskKeys, selectedTasks]);

  const handleAddTask = () => {
    setCurrentTask(null);
    setIsTaskFormOpen(true);
  };
  

  // Close task form
  const handleCloseTaskForm = () => {
    setIsTaskFormOpen(false);
    setCurrentTask(null);
  };

  // Refresh task list after operation
  const handleTaskStatusUpdate = (newTaskData: any) => {
    setIsTaskFormOpen(false);
    // Optionally refresh task list
    fetchTaskStatusFollowUps();
  };

  const fetchTaskStatusFollowUps = async () => {
    let params = {
      orderBy: "toBePerformAt",
      isAscending: true,
      page: 1,
      perPage: 50,
      status: status,
    };
    setParamState({
      ...paramState,
      orderBy: "toBePerformAt",
      page: 1,
      isAscending: true,
      status: status,
    });
    
  };

  const handleSelectionChange = (newSelectedRowKeys: React.Key[], selectedRows: ITaskData[]) => {
    console.log('Selection changed in FollowUps:', { newSelectedRowKeys, selectedRows });
    setSelectedTaskKeys(newSelectedRowKeys);
    setSelectedTasks(selectedRows);
  };

  
  const fetchTaskStatusOnScroll = (tab: string) => {
    if (tab === status) {
      setIsLoading(true);
      let increasePage = currentPage;
      if (increasePage === currentPage) {
        increasePage = increasePage + 1;
      }

      setParamState({
        ...paramState,
        orderBy: "toBePerformAt",
        isAscending: true,
        page: increasePage,
        status: status,
      });
      setOpenFilterMenu(false);
    }
  };

  const refreshTab = () => {
    // fetchData()
    // const updateObject = {...paramState , page:1}
    const updateObject = {
      status,
      isAscending: true,
      orderBy: "toBePerformAt",
      page: 1,
    };

    setParamState(updateObject);
    // setParamState(initialQueryParams)
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const requestParams: TaskQueryParameters = {};
      // Helper function to conditionally add parameters
      const addParam = (
        paramName: keyof TaskQueryParameters,
        paramValue: any
      ) => {
        if (paramValue !== undefined && paramValue !== "") {
          requestParams[paramName] = paramValue;
        }
      };

      // Add parameters conditionally using the helper function
      addParam("orderBy", paramState.orderBy);
      addParam("isAscending", paramState.isAscending);
      addParam("page", paramState.page);
      addParam("perPage", 50);

      // Check and add "status" with specific values
      if (
        paramState.status &&
        ["completed", "upcoming", "overdue", "today"].includes(
          paramState.status
        )
      ) {
        addParam("status", paramState.status);
      }

      addParam("leadId", paramState.leadId);
      addParam("userId", paramState.userId);

      if (paramState.byOrganization) {
        addParam("byOrganization", paramState.byOrganization);
        // addParam('team', paramState.team);
      } else {
        addParam("team", paramState.team);
      }
      addParam("assignToUser", paramState.taskAssign);
      addParam("taskType", paramState.taskType);
      addParam("startedAt", paramState.startedAt);
      addParam("endedAt", paramState.endedAt);

      // Add other parameters in a similar fashion...

      const response = await getAllFollowUps(requestParams);
      if (response && response.status) {
        const responseData = response?.data;
        // CLOSE FILTER DIALOG BOX
        setOpenFilterMenu(false);
        let tempArray: any = [];
        // paramState.page

        if (paramState.page && paramState.page > 1) {
          if (status === "today") {
            tempArray = [...todayTask];
            tempArray.push(...responseData.data);
            setTodayTask(tempArray);
          } else if (status === "upcoming") {
            tempArray = [...upcomingTask];
            tempArray.push(...responseData.data);
            setUpcomingTask(tempArray);
          } else if (status === "overdue") {
            tempArray = [...overdueTask];
            tempArray.push(...responseData.data);
            setOverdueTask(tempArray);
          } else {
            tempArray = [...completedTask];
            tempArray.push(...responseData.data);
            setCompletedTask(tempArray);
          }
        } else {
          tempArray = [...responseData.data];
          if (status === "today") {
            setTodayTask(tempArray);
          } else if (status === "upcoming") {
            setUpcomingTask(tempArray);
          } else if (status === "overdue") {
            setOverdueTask(tempArray);
          } else {
            setCompletedTask(tempArray);
          }
        }

        setCurrentPage(paramState?.page ?? 1);
        setIsLoading(false);
        setTotalTasks(tempArray?.length);
        setTaskTabCount(responseData.aggregation);
      }
    } catch (error) {
      // Handle error here
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTaskStatusFollowUps();
  }, [status]);


  const handleBulkAction = async (action: string, date?: any, newAssigneeId?: string) => {
    console.log('handleBulkAction called:', { action, date, newAssigneeId, selectedTaskKeys });
  
    if (selectedTaskKeys.length === 0) {
      toast.error('Please select at least one task');
      return;
    }
  
    // Get task IDs from selected tasks
    const taskIds = selectedTasks.map(task => task._id);
  
    try {
      switch (action) {
        case 'delete':
          try {
            // Use bulk operation to mark tasks as deleted
            const bulkDeletePayload = {
              taskIds: selectedTaskKeys.map(id => id as string),
              isDeleted: true
            };
  
            const response = await bulkOperationTasks(bulkDeletePayload);
  
            if (response && response.status) {
              toast.success(`${selectedTaskKeys.length} tasks deleted successfully`);
              
              // Refresh the current tab
              fetchTaskStatusFollowUps();
              
              // Clear selections
              setSelectedTaskKeys([]);
              setSelectedTasks([]);
              setOpenWithHeader(false);
            } else {
              toast.error('Failed to delete tasks');
            }
          } catch (error) {
            console.error('Bulk delete error:', error);
            toast.error('Error deleting tasks');
          }
          break;
  
        case 'reassign':
          try {
            if (!newAssigneeId) {
              toast.error('No assignee selected');
              return;
            }
  
            console.log('Making reassign API call with:', {
              taskIds: selectedTaskKeys,
              assignedTo: newAssigneeId
            });
  
            await bulkOperationTasks({
              taskIds: selectedTaskKeys.map(id => id.toString()),
              assignedTo: newAssigneeId
            });
  
            toast.success('Tasks reassigned successfully');
            await fetchTaskStatusFollowUps();
            setSelectedTaskKeys([]);
            setSelectedTasks([]);
            setOpenWithHeader(false);
          } catch (error) {
            console.error('Reassign error:', error);
            toast.error('Error reassigning tasks');
          }
          break;
  
          case 'updateDueDate':
            try {
              if (!date) {
                toast.error('No date selected');
                return;
              }
    
              await bulkOperationTasks({
                taskIds: selectedTaskKeys.map(id => id.toString()),
                dueDate: date  // API will handle the date format
              });
    
              toast.success('Due dates updated successfully');
              await fetchTaskStatusFollowUps();
              setSelectedTaskKeys([]);
              setSelectedTasks([]);
              setOpenWithHeader(false);
            } catch (error) {
              console.error('Update due date error:', error);
              toast.error('Error updating due dates');
            }
            break;
  
        case 'complete':
          try {
            await bulkOperationTasks({
              taskIds,
              isCompleted: true
            });
            toast.success('Tasks marked as complete');
            await fetchTaskStatusFollowUps();
            setSelectedTaskKeys([]);
            setSelectedTasks([]);
            setOpenWithHeader(false);
          } catch (error) {
            console.error('Complete tasks error:', error);
            toast.error('Error completing tasks');
          }
          break;
      }
  
    } catch (error) {
      console.error('Bulk operation error:', error);
      toast.error('Failed to update tasks');
    }
  };

  
  const onStatusChange = (status: "today" | "upcoming" | "overdue" | "completed") => {
    setStatus(status);
    // Clear selections when changing tabs
    setSelectedTaskKeys([]);
    setSelectedTasks([]);
  };

  const toggleDrawer = (action: React.SetStateAction<string>) => {
    setOpenWithHeader(true);
    setAction(action);
  };

  const onTaskDelete = () => {
    if (updateTaskValue) {
      // Use the bulk actions selection instead
      setSelectedTaskKeys([updateTaskValue._id]);
      setSelectedTasks([updateTaskValue]);
      handleBulkAction('delete');
    }
  };
  

  

  const handleEditTask = async (_id: string) => {
    try {
      const response = await taskById(_id);
      if (response && response.status) {
        setCurrentTask(response.data.data);
        setIsTaskFormOpen(true);
      }
    } catch (err) {
      toast.error("Error while fetching task data.");
    }
  };

  const toggleLeadFilterDrawer = useCallback(() => {
    setOpenFilterMenu(true);
  }, []);

  const handleLeadClick = (leadId: string) => {
    let selected = StoreData?.leads?.leads?.find(
      (lead: any) => lead._id === leadId
    );
    let tempArray = [];
    tempArray = StoreData?.user?.userPreferences?.labels
      ? StoreData?.user?.userPreferences?.labels
      : userPreferences?.labels;

    const mergedArray = tempArray.map((obj1: any) => {
      const matchingObjTeam = selected?.label.find(
        (obj2: any) => obj2 === obj1.value
      );
      let temp_obj = {};
      if (selected?.label.includes(obj1.value)) {
        temp_obj = {
          ...obj1,
          isLabelChecked: true,
        };
      } else {
        temp_obj = {
          ...obj1,
          isLabelChecked: false,
        };
      }

      if (matchingObjTeam) {
        return { ...temp_obj };
      }
      return obj1;
    });

    setSelectedLead(selected);
    setLeadId(leadId);
    setLeadLabelData(mergedArray);
    setOpenWithHeader(true);
    setOpenLeadDrawer(!openLeadDrawer);
  };

  const onClickSecondOption = () => {
    handleLeadClick(updateTaskValue?.lead?.[0]?._id);
  };

  const onApplyFilterClick = async (data: any) => {
    console.log('Filter data received:', data);
    let increasePage = currentPage;
  
    // Ensure we're using the correct date fields
    const filters = {
      ...paramState,
      page: increasePage,
      team: data?.sortTeamsBy,
      taskType: data?.taskType,  // Explicitly set taskType only if it exists
      userId: data?.sortTeamMemberBy,
      orderBy: data?.orderBy,  // Separately set orderBy
      byOrganization: data.byOrganization,
      startedAt: data?.startDate || data?.startedAt,
      endedAt: data?.endDate || data?.endedAt,
      taskAssign: data?.sortTaskAssignBy,
    };
  
   

   
    delete filters.status;
    console.log('Applied filters:', filters); // For debugging
    setParamState(filters);
  };

  useEffect(() => {
    if (Object.keys(paramState).length != 0) {
      fetchData();
    }
  }, [paramState]);
  // console.log('paramState ======>',paramState)
  return (
    <div id="main" className="main">
      <Header />
      <section className="content-section-1">
        <div className="row">
          <div className="col-md-12">
          <ul className="nav nav-pills mb-3 content-section-1-sub-1 justify-content-around d-flex" id="pills-tab" role="tablist">
  <li onClick={() => onStatusChange("today")} className="nav-item" role="presentation">
    <button className={`nav-link ${status === "today" ? "active" : ""} px-4`} 
           id="pills-duetoday-tab" 
           data-bs-toggle="pill" 
           data-bs-target="#pills-duetoday">
      <Clock size={16} className="me-2" />
      Today ({taskTabCount?.today ? taskTabCount?.today : 0})
    </button>
  </li>
  <li onClick={() => onStatusChange("upcoming")} className="nav-item" role="presentation">  
    <button className={`nav-link ${status === "upcoming" ? "active" : ""} px-4`}
           id="pills-upcoming-tab"
           data-bs-toggle="pill" 
           data-bs-target="#pills-upcoming">
      <Calendar size={16} className="me-2" />
      Upcoming ({taskTabCount?.upcoming ? taskTabCount?.upcoming : 0})
    </button>
  </li>
  <li onClick={() => onStatusChange("overdue")} className="nav-item" role="presentation">
    <button className={`nav-link ${status === "overdue" ? "active" : ""} px-4`}
           id="pills-overdue-tab"
           data-bs-toggle="pill"
           data-bs-target="#pills-overdue">
      <AlertCircle size={16} className="me-2" />
      Overdue ({taskTabCount?.overdue ? taskTabCount?.overdue : 0})
    </button>
  </li>
  <li onClick={() => onStatusChange("completed")} className="nav-item" role="presentation">
    <button className={`nav-link ${status === "completed" ? "active" : ""} px-4`}
           id="pills-completed-tab"
           data-bs-toggle="pill"
           data-bs-target="#pills-completed">
      <CheckCircle size={16} className="me-2" />
      Done ({taskTabCount?.completed ? taskTabCount?.completed : 0})
    </button>
  </li>
  <div className="d-flex">
    <li className="me-3">
      <div className="p-2 d-flex justify-content-center" 
           style={{backgroundColor: "#EBF0F4", borderRadius: "5px", height: "34px"}}>
        <Filter size={18} className="cursor-pointer" onClick={toggleLeadFilterDrawer} />
        <span className="ms-2">Filter</span>
      </div>
    </li>
    <li>
      <div className="p-2 d-flex justify-content-center"
           style={{backgroundColor: "#EBF0F4", borderRadius: "5px", height: "34px"}}
           onClick={refreshTab}>
        <RotateCw size={18} className="cursor-pointer" />
        <span className="ms-2">Refresh</span>
      </div>
    </li>
  </div>
</ul>
            <Spin size="large" tip="Loading..." spinning={isLoading}>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-duetoday"
                  role="tabpanel"
                  aria-labelledby="duetoday-tab"
                >
                  {/* @ts-ignore */}
                  <InfiniteScroll
                    dataLength={totalTasks}
                    next={() => fetchTaskStatusOnScroll("today")}
                    hasMore={true}
                    loader={isLoading ? <h4></h4> : null}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <div className="followups-section-1">
                    <TaskGrid
  data={todayTask}
  isLoading={isLoading}
  handleEditTask={handleEditTask}
  selectedRowKeys={selectedTaskKeys}
  onSelectChange={handleSelectionChange}
/>
</div>
                  </InfiniteScroll>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-upcoming"
                  role="tabpanel"
                  aria-labelledby="upcoming-tab"
                >
                  {/* @ts-ignore */}
                  <InfiniteScroll
                    dataLength={totalTasks}
                    next={() => fetchTaskStatusOnScroll("upcoming")}
                    hasMore={true}
                    loader={isLoading ? <h4></h4> : null}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <div className="followups-section-1">
                    <TaskGrid
  data={upcomingTask}
  isLoading={isLoading}
  handleEditTask={handleEditTask}
  selectedRowKeys={selectedTaskKeys}
  onSelectChange={handleSelectionChange}
/>
                    </div>
                  </InfiniteScroll>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-overdue"
                  role="tabpanel"
                  aria-labelledby="overdue-tab"
                >
                  {/* @ts-ignore */}
                  <InfiniteScroll
                    dataLength={totalTasks}
                    next={() => fetchTaskStatusOnScroll("overdue")}
                    hasMore={true}
                    loader={isLoading ? <h4></h4> : null}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <div className="followups-section-1">
                    <TaskGrid
   data={overdueTask}
  isLoading={isLoading}
  handleEditTask={handleEditTask}
  selectedRowKeys={selectedTaskKeys}
  onSelectChange={handleSelectionChange}
/>
                    </div>
                  </InfiniteScroll>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-completed"
                  role="tabpanel"
                  aria-labelledby="completed-tab"
                >
                  {/* @ts-ignore */}
                  <InfiniteScroll
                    dataLength={totalTasks}
                    next={() => fetchTaskStatusOnScroll("completed")}
                    hasMore={true}
                    loader={isLoading ? <h4></h4> : null}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <div className="followups-section-1">
                    <TaskGrid
    data={completedTask}
  isLoading={isLoading}
  handleEditTask={handleEditTask}
  selectedRowKeys={selectedTaskKeys}
  onSelectChange={handleSelectionChange}
/>
                    </div>
                  </InfiniteScroll>
                </div>
              </div>
            </Spin>
          </div>
        </div>
        <div className="row">
        {!openWithHeader && !openFilterMenu && (
    <button 
      className="add-task-button"
      onClick={handleAddTask}
    >
      <i className="bi bi-plus-circle-fill"></i>
      <span>Add Task</span>
    </button>
  )}

<TaskFormPopupWrapper
        isOpen={isTaskFormOpen}
        onClose={handleCloseTaskForm}
        action={currentTask ? 'edit' : 'add'}
        updateTaskValue={currentTask}
        fetchTaskStatusFollowUps={handleTaskStatusUpdate}
      />
  <style>{`
    .add-task-button {
      position: fixed;
      bottom: 20px;
      right: 20px;
      background: #2196F3;
      color: white;
      border: none;
      border-radius: 8px;
      padding: 8px 16px;
      display: inline-flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      z-index: 1000;
      font-family: 'Gilroy', sans-serif;
      width: auto;  /* Prevent full width */
      min-width: min-content;
    }
            
            .add-task-button:hover {
              transform: translateY(-2px);
              box-shadow: 0 6px 12px rgba(0,0,0,0.15);
            }
            
            .add-task-button i {
              font-size: 20px;
            }
            
            .add-task-button span {
              font-size: 14px;
              font-weight: 500;
            }
          `}</style>
        </div>

        
        <TaskDrawer
          openWithHeader={openWithHeader}
          setOpenWithHeader={setOpenWithHeader}
          drawerTitle={action === "add" ? "Add Task" : "Edit Task"}
          onRedirectClick={() => onClickSecondOption()}
          onTaskDelete={onTaskDelete}
          size="xs"
        >
          <CreateTaskForm
            updateTaskValue={updateTaskValue}
            action={action}
            status={status}
            drawerClose={() => setOpenWithHeader(false)}
            fetchTaskStatusFollowUps={fetchTaskStatusFollowUps}
          />
        </TaskDrawer>
       
       
      </section>
      <GlobalDrawer
        openWithHeader={openFilterMenu}
        setOpenWithHeader={setOpenFilterMenu}
        drawerTitle="Task filter"
        size="xs"
      >
        <TaskFilter
          {...{
            modal: openFilterMenu,
            toggle: () => setOpenFilterMenu(false),
            filters: paramState,
            key: "filterMenu",
            applyFilter: (data) => onApplyFilterClick(data),
          }}
        />
      </GlobalDrawer>

      {selectedTaskKeys.length > 0 && (
  <TaskBulkActionsBar
    selectedCount={selectedTaskKeys.length}
    onClearSelection={() => {
      setSelectedTaskKeys([]);
      setSelectedTasks([]);
    }}
    onBulkAction={handleBulkAction}
  />
)}
      <Toaster position="top-right" reverseOrder={false} />
      <LeadMenu
        leadId={leadId}
        leadLabelData={leadLabelData}
        setOpenWithHeader={setOpenWithHeader}
        selectedLead={selectedLead}
        openLeadDrawer={openLeadDrawer}
        setOpenLeadDrawer={setOpenLeadDrawer}
      />

      {/* back to top button */}
      <BackToTop />
    </div>
  );
};

export default FollowUps;
