import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Smartphone, Monitor } from 'lucide-react';
import PopupComponent from '../../components/PopupComponent';

interface EmailPreviewProps {
  isOpen: boolean;
  onClose: () => void;
  subject: string;
  content: string;
  attachments?: File[];
}

interface ViewModeToggleProps {
  viewMode: 'mobile' | 'desktop';
  onViewModeChange: (mode: 'mobile' | 'desktop') => void;
}

const DEFAULT_EMAIL_STYLES = `
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .email-container {
    max-width: 600px;
    margin: 0 auto;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .email-body {
    padding: 20px;
    color: #333333;
    font-size: 16px;
    line-height: 1.6;
  }
`;

const StyledViewModeToggle = styled.div`
  display: flex;
  gap: 2px;
  padding: 4px;
  background: #f4f5f6;
  border-radius: 8px;
  margin-right: 8px;
`;

const ViewModeButton = styled.button<{ active: boolean }>`
  background: ${props => props.active ? 'white' : 'transparent'};
  border: none;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  color: ${props => props.active ? '#1a1d1f' : '#6f767e'};
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #1a1d1f;
  }

  box-shadow: ${props => props.active ? '0 2px 4px rgba(0,0,0,0.05)' : 'none'};
`;

const StyledContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`;

const SubjectLine = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #1a1d1f;
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eef1f5;
`;

const LoadingIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #6f767e;
  font-size: 14px;
`;

const PreviewContainer = styled.div<{ $viewMode: 'mobile' | 'desktop' }>`
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 500px;
  padding: ${props => props.$viewMode === 'mobile' ? '20px' : '0'};
  background: ${props => props.$viewMode === 'mobile' ? '#f4f5f6' : 'transparent'};
  border-radius: 12px;
  transition: all 0.3s ease;
`;

const StyledFrame = styled.iframe`
  min-height: 500px;
  border: none;
  transition: all 0.3s ease;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`;

const StyledAttachmentSection = styled.div`
  margin-top: 24px;
  padding-top: 20px;
  border-top: 1px solid #eef1f5;
`;

const AttachmentTitle = styled.h4`
  margin: 0 0 16px;
  font-size: 15px;
  font-weight: 600;
  color: #1a1d1f;
`;

const StyledAttachmentItem = styled.div`
  padding: 12px 16px;
  background: #f4f5f6;
  border-radius: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  color: #6f767e;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ViewModeToggle = ({
  viewMode,
  onViewModeChange,
}: ViewModeToggleProps) => (
  <StyledViewModeToggle>
    <ViewModeButton
      active={viewMode === 'mobile'}
      onClick={() => onViewModeChange('mobile')}
      title="Mobile view"
    >
      <Smartphone size={18} />
    </ViewModeButton>
    <ViewModeButton
      active={viewMode === 'desktop'}
      onClick={() => onViewModeChange('desktop')}
      title="Desktop view"
    >
      <Monitor size={18} />
    </ViewModeButton>
  </StyledViewModeToggle>
);

const PreviewModal: React.FC<EmailPreviewProps> = ({
  isOpen,
  onClose,
  subject,
  content,
  attachments = [],
}) => {
  const [viewMode, setViewMode] = useState<'mobile' | 'desktop'>('mobile');
  const [isFrameLoaded, setIsFrameLoaded] = useState(false);

  const decodeHTMLContent = useCallback((raw: string): string => {
    try {
      const txt = document.createElement('textarea');
      txt.innerHTML = raw;
      return txt.value;
    } catch (error) {
      console.error('Error decoding HTML:', error);
      return raw;
    }
  }, []);

  const processedContent = useMemo(() => {
    try {
      const decodedContent = decodeHTMLContent(content);
      const hasDoctype = decodedContent.trim().toLowerCase().startsWith('<!doctype html');
      const hasHtml = decodedContent.trim().toLowerCase().includes('<html');

      if (hasDoctype || hasHtml) {
        return decodedContent;
      }

      return `
        <!DOCTYPE html>
        <html>
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <style>${DEFAULT_EMAIL_STYLES}</style>
          </head>
          <body>
            <div class="email-container">
              <div class="email-body">
                ${decodedContent}
              </div>
            </div>
          </body>
        </html>
      `;
    } catch (error) {
      console.error('Error processing content:', error);
      return `<div style="color: red; padding: 20px;">Error rendering email content</div>`;
    }
  }, [content, decodeHTMLContent]);

  const emailContent = (
    <StyledContent>
      <SubjectLine>Subject: {subject}</SubjectLine>
      
      <PreviewContainer $viewMode={viewMode}>
        {!isFrameLoaded && (
          <LoadingIndicator>Loading preview...</LoadingIndicator>
        )}
        <StyledFrame
          srcDoc={processedContent}
          title="Email preview"
          onLoad={() => setIsFrameLoaded(true)}
          style={{
            opacity: isFrameLoaded ? 1 : 0,
            width: viewMode === 'mobile' ? '375px' : '100%',
          }}
          sandbox="allow-same-origin allow-popups"
        />
      </PreviewContainer>

      {attachments.length > 0 && (
        <StyledAttachmentSection>
          <AttachmentTitle>
            Attachments ({attachments.length})
          </AttachmentTitle>
          {attachments.map((file, i) => (
            <StyledAttachmentItem key={i}>
              {file.name} ({Math.round(file.size / 1024)}kb)
            </StyledAttachmentItem>
          ))}
        </StyledAttachmentSection>
      )}
    </StyledContent>
  );

  return (
    <PopupComponent
      isOpen={isOpen}
      onClose={onClose}
      title="Email Preview"
      size="lg"
      headerActions={
        <ViewModeToggle
          viewMode={viewMode}
          onViewModeChange={setViewMode}
        />
      }
    >
      {emailContent}
    </PopupComponent>
  );
};

export default PreviewModal;