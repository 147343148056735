import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./profile.css";
import { FaCheck, FaTimes, FaExclamationTriangle, FaCamera } from 'react-icons/fa';
import {
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  Spinner
} from "reactstrap";
import Header from "../../components/header";
import FacebookIntegration from "../../components/FacebookIntegration";
import { downloadChatMedia, getWhatsappProfile, updateProfile, uploadMedia } from "../../services/whatsapp";

// Interfaces
interface ErrorDetail {
  error_description: string;
}
interface ApiResponse<T> {
  data: {
    data: T;
    message?: string;
    status?: number;
  };
}

// Add to your existing interfaces
interface WhatsappProfileResponse extends UserProfileData {
  healthStatus: {
    entities: Entity[];
  };
}
interface Entity {
  entity_type: string;
  can_send_message: string;
  error_description: string;
  errors: ErrorDetail[];
  additional_info: string[];
}

interface UserProfileData {
  vertical: string;
  websites: string[];
  email: string;
  address: string;
  description: string;
  profilePictureHandle: string;
  verifiedName: string;
  displayPhoneNumber: string;
  messagingLimitTier: string;
  nameStatus: string;
  qualityScore: { score: string };
  status: string;
  healthStatus?: {
    entities: Entity[];
  };
}

const CATEGORY_OPTIONS = [
  'OTHER', 'AUTO', 'BEAUTY', 'APPAREL', 'EDU', 'ENTERTAIN',
  'EVENT_PLAN', 'FINANCE', 'GROCERY', 'GOVT', 'HOTEL', 'HEALTH', 'NONPROFIT',
  'PROF_SERVICES', 'RETAIL', 'TRAVEL', 'RESTAURANT', 'NOT_A_BIZ'
];



const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'available':
    case 'connected':
    case 'green':
      return 'text-success';
    case 'limited':
    case 'declined':
      return 'text-danger';
    default:
      return 'text-warning';
  }
};

const getStatusIcon = (status: string) => {
  switch (status.toLowerCase()) {
    case 'available':
    case 'connected':
    case 'green':
      return <FaCheck className="me-2" />;
    case 'limited':
    case 'declined':
      return <FaTimes className="me-2" />;
    default:
      return <FaExclamationTriangle className="me-2" />;
  }
};

export default function Profile() {
  const [showFbButton, setShowFbButton] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<UserProfileData>({} as UserProfileData);
  const [wabaEntities, setWabaEntities] = useState<Entity[]>([]);
  const [businessEntities, setBusinessEntities] = useState<Entity[]>([]);
  const [phoneNumberEntities, setPhoneNumberEntities] = useState<Entity[]>([]);
  const [isCheckingIntegration, setIsCheckingIntegration] = useState(true);
  const [isIntegrated, setIsIntegrated] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);
const [isLoadingImage, setIsLoadingImage] = useState(false);

const checkIntegrationStatus = () => {
  try {
    console.log('Checking integration status - START');
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    console.log('Parsed userData:', userData);

    const userIntegrations = userData.userIntegrations || [];
    console.log('User Integrations:', userIntegrations);

    const isIntegrated = userIntegrations.some(
      (item: any) => {
        const isMatch = item?.integration?._id === "646b9286ae8ef73b33caec2e" && item?.isActive;
        console.log('Integration Check:', {
          integrationId: item?.integration?._id,
          isActive: item?.isActive,
          isMatch
        });
        return isMatch;
      }
    );

    console.log('Final Integration Status:', isIntegrated);
    return isIntegrated;
  } catch (error) {
    console.error("Error checking integration status:", error);
    return false;
  }
};

  useEffect(() => {
    if (userData.profilePictureHandle) {
      loadProfilePicture(userData.profilePictureHandle);
    } else {
      setProfileImage(null);
    }
  }, [userData.profilePictureHandle]);

  const fetchUserData = async () => {
    console.log('Fetch User Data - START');
    
    try {
      // Detailed integration status check
      const isIntegrated = checkIntegrationStatus();
      console.log('Integration Status in fetchUserData:', isIntegrated);
  
      // Early return if not integrated
      if (!isIntegrated) {
        console.warn('Not integrated - stopping fetch');
        setIsLoading(false);
        setShowFbButton(true);
        return;
      }
  
      console.log('Proceeding with API call');
      setIsLoading(true);
      const response: ApiResponse<WhatsappProfileResponse> = await getWhatsappProfile();
      console.log('API Response:', response);
  
      const profileData = response.data.data;
      console.log('Profile Data:', profileData);
      setUserData(profileData);
      
      if (profileData.healthStatus?.entities) {
        const entities: Entity[] = profileData.healthStatus.entities;
        console.log('Entities:', entities);
  
        const wabaEntities = entities.filter((entity: Entity) => entity.entity_type === "WABA");
        const businessEntities = entities.filter((entity: Entity) => entity.entity_type === "BUSINESS");
        const phoneNumberEntities = entities.filter((entity: Entity) => entity.entity_type === "PHONE_NUMBER");
  
        console.log('Filtered Entities:', {
          wabaEntities,
          businessEntities,
          phoneNumberEntities
        });
  
        setWabaEntities(wabaEntities);
        setBusinessEntities(businessEntities);
        setPhoneNumberEntities(phoneNumberEntities);
      }
    } catch (error: unknown) {
      console.error("Comprehensive Error in fetchUserData:", error);
      const errorMessage = error instanceof Error ? error.message : "Failed to fetch profile data";
      
      // Log additional context
      console.error('Error Details:', {
        errorMessage,
        type: typeof error,
        stringified: JSON.stringify(error)
      });
  
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      console.log('Fetch User Data - END');
      setIsLoading(false);
    }
  };

  const handleManualRefresh = async () => {
    setIsCheckingIntegration(true);
    const status = checkIntegrationStatus();
    setIsIntegrated(status);
    setShowFbButton(!status);
    
    if (status) {
      await fetchUserData();
    }
    setIsCheckingIntegration(false);
  };

  useEffect(() => {
    const initializeProfile = async () => {
      console.log('Initializing Profile - START');
      
      try {
        const status = checkIntegrationStatus();
        console.log('Initial Integration Status:', status);
        
        setIsIntegrated(status);
        setShowFbButton(!status);
        
        if (status) {
          console.log('Calling fetchUserData');
          await fetchUserData();
        }
      } catch (error) {
        console.error('Initialization Error:', error);
      } finally {
        console.log('Initializing Profile - END');
        setIsCheckingIntegration(false);
      }
    };
  
    initializeProfile();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      vertical: userData.vertical || "",
      websites: userData.websites || [""],
      email: userData.email || "",
      address: userData.address || "",
      description: userData.description || "",
    },
    validationSchema: Yup.object({
      vertical: Yup.string().required("Category is required"),
      websites: Yup.array().of(Yup.string().url("Invalid URL")).min(1, "At least one website is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      address: Yup.string().required("Address is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        await updateProfile(values);
        toast.success("Profile updated successfully");
        await fetchUserData();
      } catch (error) {
        console.error("Error updating profile:", error);
        toast.error("Failed to update profile");
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        setIsUploading(true);
        
        const formData = new FormData();
        formData.append('file', file);
        
        // Upload media first
        const mediaResponse = await uploadMedia(formData);
        
        // Check for correct response structure
        if (mediaResponse.data?.data?.[0]?.uploadedId) {
          await updateProfile({
            ...validation.values,
            profilePictureHandle: mediaResponse.data.data[0].uploadedId
          });
          toast.success("Profile picture updated successfully");
          await fetchUserData();
        } else {
          throw new Error('No upload ID received from server');
        }
  
      } catch (error) {
        console.error("Error updating profile picture:", error);
        toast.error(error instanceof Error ? error.message : "Failed to update profile picture");
      } finally {
        setIsUploading(false);
      }
    }
  };

  const loadProfilePicture = async (mediaId: string) => {
    try {
      setIsLoadingImage(true);
      const response = await downloadChatMedia({ mediaId });
      
      // Convert ArrayBuffer to base64
      const blob = new Blob([response.data], { type: 'image/jpeg' });
      const imageUrl = URL.createObjectURL(blob);
      setProfileImage(imageUrl);
    } catch (error) {
      console.error("Error loading profile picture:", error);
      setProfileImage(null);
    } finally {
      setIsLoadingImage(false);
    }
  };
  
  return (
    <div id="main" className="main">
      <Header />
      {showFbButton ? (
        <FacebookIntegration
          onSuccess={() => {
            setShowFbButton(false);
            fetchUserData();
          }}
        />
      ) : (
        <Container fluid className="py-4 bg-light">
          <h4 className="text-primary mb-4">Whtaspp business API Profile</h4>

          {isLoading ? (
              <div className="text-center py-5">
              <Spinner color="primary" />
            </div>
          ) : error ? (
            <div className="text-center py-5">
              <div className="text-danger mb-3">
                <FaTimes size={40} />
              </div>
              <p className="text-danger">{error}</p>
            </div>
          ) : (
            <>
              <Row className="mb-4">
                <Col md={4} className="mb-4 mb-md-0">
                <Card className="h-100 shadow-sm">
                <CardBody>
    <CardTitle tag="h5" className="border-bottom pb-2 mb-3">Waba Health</CardTitle>
    {wabaEntities[0] && (
      <div>
        <p className="mb-2">
          {getStatusIcon(wabaEntities[0].can_send_message)}
          <span className="fw-bold">Health: </span>
          <span className={getStatusColor(wabaEntities[0].can_send_message)}>
            {wabaEntities[0].can_send_message}
          </span>
        </p>
        {wabaEntities[0].additional_info?.map((info, index) => (
          <p key={index} className="mb-2 small text-muted">
            {info}
          </p>
        ))}
      </div>
    )}
    
    <CardTitle tag="h5" className="border-bottom pb-2 mt-4 mb-3">Business Health</CardTitle>
    {businessEntities[0] && (
      <div>
        <p className="mb-2">
          {getStatusIcon(businessEntities[0].can_send_message)}
          <span className="fw-bold">Health: </span>
          <span className={getStatusColor(businessEntities[0].can_send_message)}>
            {businessEntities[0].can_send_message}
          </span>
        </p>
        {businessEntities[0].additional_info?.map((info, index) => (
          <p key={index} className="mb-2 small text-muted">
            {info}
          </p>
        ))}
      </div>
    )}
  </CardBody>
</Card>
                </Col>

                <Col md={4} className="mb-4 mb-md-0">
                <Card className="h-100 shadow-sm">
  <CardBody>
    <CardTitle tag="h5" className="border-bottom pb-2 mb-3">Account Health</CardTitle>
    {userData.messagingLimitTier && (
      <p className="mb-2">
        <span className="fw-bold">Message limit:</span> {userData.messagingLimitTier}
      </p>
    )}
    {userData.nameStatus && (
      <p className="mb-2">
        {getStatusIcon(userData.nameStatus)}
        <span className="fw-bold">Name status:</span>{" "}
        <span className={getStatusColor(userData.nameStatus)}>
          {userData.nameStatus}
        </span>
      </p>
    )}
    {userData.qualityScore?.score && (
      <p className="mb-2">
        {getStatusIcon(userData.qualityScore.score)}
        <span className="fw-bold">Quality:</span>{" "}
        <span className={getStatusColor(userData.qualityScore.score)}>
          {userData.qualityScore.score}
        </span>
      </p>
    )}
    {userData.status && (
      <p className="mb-2">
        {getStatusIcon(userData.status)}
        <span className="fw-bold">Status:</span>{" "}
        <span className={getStatusColor(userData.status)}>
          {userData.status}
        </span>
      </p>
    )}
  </CardBody>
</Card>
                </Col>

                <Col md={4}>
                <Card className="h-100 shadow-sm">
  <CardBody>
    <CardTitle tag="h5" className="border-bottom pb-2 mb-3">Phone Health</CardTitle>
    {userData.displayPhoneNumber && (
      <p className="mb-2">
        <span className="fw-bold">Phone: </span> 
        {userData.displayPhoneNumber}
      </p>
    )}
    {phoneNumberEntities[0] && (
      <div>
        <p className="mb-2">
          {getStatusIcon(phoneNumberEntities[0].can_send_message)}
          <span className="fw-bold">Phone number health: </span>
          <span className={getStatusColor(phoneNumberEntities[0].can_send_message)}>
            {phoneNumberEntities[0].can_send_message}
          </span>
        </p>
        {phoneNumberEntities[0].additional_info?.map((info, index) => (
          <p key={index} className="mb-2 small text-muted">
            {info}
          </p>
        ))}
      </div>
    )}
    {userData.verifiedName && (
      <p className="mb-2">
        <span className="fw-bold">Verified Name: </span>
        {userData.verifiedName}
      </p>
    )}
  </CardBody>
</Card>
                </Col>
              </Row>

              <Card className="shadow-sm">
                <CardBody>
                  <h5 className="card-title mb-4">WhatsApp Cloud API Profile</h5>
                  <Row>
                    <Col md={4} className="text-center mb-4">
                      <div className="position-relative d-inline-block">
  {isLoadingImage ? (
    <div 
      className="rounded-circle mb-3 border d-flex align-items-center justify-content-center bg-light"
      style={{ width: "150px", height: "150px" }}
    >
      <Spinner size="sm" />
    </div>
  ) : profileImage ? (
    <img
      className="rounded-circle mb-3 border"
      src={profileImage}
      alt="Profile"
      style={{ width: "150px", height: "150px", objectFit: "cover" }}
      onError={(e) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        target.src = "/assets/img/no-profile.png";
      }}
    />
  ) : (
    <div 
      className="rounded-circle mb-3 border d-flex align-items-center justify-content-center bg-light"
      style={{ width: "150px", height: "150px" }}
    >
      <span className="text-muted">No Image</span>
    </div>
  )}
  
  <label
    htmlFor="profile-picture"
    className="btn btn-sm btn-primary position-absolute bottom-0 end-0 rounded-circle"
    style={{ width: "32px", height: "32px", padding: "0", lineHeight: "32px" }}
  >
    {isUploading ? <Spinner size="sm" /> : <FaCamera />}
  </label>
  <input
    type="file"
    id="profile-picture"
    onChange={handleImageChange}
    accept="image/*"
    hidden
  />
</div>
                      <h4 className="mt-2 mb-0">{userData.verifiedName}</h4>
                    </Col>

                    <Col md={8}>
                      <Form onSubmit={validation.handleSubmit}>
                        <Row>
                          <Col md={6} className="mb-3">
                            <Label className="form-label fw-bold">Category</Label>
                            <Input
                              type="select"
                              name="vertical"
                              value={validation.values.vertical}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.vertical && !!validation.errors.vertical}
                            >
                            {CATEGORY_OPTIONS.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </Input>
                            <FormFeedback>{validation.errors.vertical}</FormFeedback>
                          </Col>

                          <Col md={6} className="mb-3">
                            <Label className="form-label fw-bold">Email</Label>
                            <Input
                              type="email"
                              name="email"
                              placeholder="Enter email"
                              value={validation.values.email}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.email && !!validation.errors.email}
                            />
                            <FormFeedback>{validation.errors.email}</FormFeedback>
                          </Col>

                          <Col md={6} className="mb-3">
                            <Label className="form-label fw-bold">Website</Label>
                            <Input
                              type="url"
                              name="websites[0]"
                              placeholder="Enter website URL"
                              value={validation.values.websites[0] || ""}
                              onChange={(e) => {
                                validation.setFieldValue("websites", [e.target.value]);
                              }}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.websites && !!validation.errors.websites}
                            />
                            <FormFeedback>
                              {typeof validation.errors.websites === 'string' 
                                ? validation.errors.websites 
                                : Array.isArray(validation.errors.websites) 
                                  ? validation.errors.websites[0] 
                                  : null}
                            </FormFeedback>
                          </Col>

                          <Col md={6} className="mb-3">
                            <Label className="form-label fw-bold">Address</Label>
                            <Input
                              type="text"
                              name="address"
                              placeholder="Enter business address"
                              value={validation.values.address}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.address && !!validation.errors.address}
                            />
                            <FormFeedback>{validation.errors.address}</FormFeedback>
                          </Col>

                          <Col md={12} className="mb-3">
                            <Label className="form-label fw-bold">Description</Label>
                            <Input
                              type="textarea"
                              name="description"
                              placeholder="Enter business description"
                              rows={4}
                              value={validation.values.description}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.description && !!validation.errors.description}
                            />
                            <FormFeedback>{validation.errors.description}</FormFeedback>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="text-end mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              disabled={isSubmitting}
                              className="px-4"
                            >
                              {isSubmitting ? (
                                <>
                                  <Spinner size="sm" className="me-2" />
                                  Saving...
                                </>
                              ) : (
                                "Save Changes"
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      )}
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}
