import React, { useState, useEffect, useCallback } from 'react';
import { Card, Dropdown, Menu, message, Select, Table } from 'antd';
import { Search, ArrowLeft, Send, Eye, MessageSquareReply, AlertCircle, 
         Phone, Mail, MessageCircle, Calendar, CheckCircle2, Download, 
         X, ArrowUpRight} from 'lucide-react';
import debounce from 'lodash/debounce';
import  GenericConfirmationModal from '../../components/GenericConfirmationModal';
import { getCampaignById } from '../../services/broadcast';
import type { CampaignViewProps, CampaignFormData, LeadData, StatusType, ErrorObject, ContentStructure } from './campaignViewTypes';
import { STATUS_COLORS } from './campaignViewTypes';
import type { MenuProps } from 'antd';
import './campaignView.css';
import LeadExportService from '../../services/LeadExportService';
import moment from 'moment';
import ContactLinksGenerator from '../../utils/contactlinksgenerator';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_ENDPOINT } from '../../services/userService';
import LeadDetails from '../leads/leadDetails';
import DrawerComponent from '../../components/drawer';


const getErrorDisplay = (error: ErrorObject | ErrorObject[] | string | null | undefined): string | null => {
  if (!error) return null;

  // Case 1: If error is a string
  if (typeof error === 'string') {
    try {
      // Try to parse the string as JSON
      const parsed = JSON.parse(error);
      return getErrorDisplay(parsed); // Recursively handle parsed object/array
    } catch {
      // If parsing fails, return the string as-is
      return error;
    }
  }

  // Case 2: If error is an array
  if (Array.isArray(error)) {
    const errorMessages = error
      .map((e) => getErrorDisplay(e)) // Recursively process each element
      .filter(Boolean); // Remove empty/null values
    return errorMessages.length > 0 ? errorMessages.join(', ') : 'Error occurred';
  }

  // Case 3: If error is an object
  if (typeof error === 'object' && error !== null) {
    // Extract error message from possible properties
    return (
      error.error_data?.details ?? // Check error_data.details first
      error.message ?? // Fallback to message
      error.title ?? // Fallback to title
      'Error occurred' // Default fallback
    );
  }

  // Case 4: If error is of an unexpected type
  return 'Error occurred';
};

const renderTemplateOrContent = (campaignData: CampaignFormData) => {
  if (!campaignData) return null;

  const serviceType = campaignData.serviceType?.toLowerCase() ?? '';
  
  // Handle WhatsApp templates
  if (serviceType === 'whatsapp' && campaignData.whatsappTemplate?.name) {
    return (
      <>
        <span>•</span>
        <span>Template: {campaignData.whatsappTemplate.name}</span>
      </>
    );
  }

  // Handle Content
  if (campaignData.content) {
    // If content is a string, render it directly
    if (typeof campaignData.content === 'string') {
      return (
        <>
          <span>•</span>
          <span>Content: {campaignData.content}</span>
        </>
      );
    }

    // If content is our structured object
    if (typeof campaignData.content === 'object' && 'details' in campaignData.content) {
      const { details } = campaignData.content as unknown as ContentStructure;
      const displayText = details.title || details.subject || 'Untitled';
      
      return (
        <>
          <span>•</span>
          <span>Content: {displayText}</span>
        </>
      );
    }
  }

  return null;
};

const LoadingSpinner: React.FC = () => (
  <div className="loading-spinner">Loading...</div>
);

const CampaignView: React.FC<CampaignViewProps> = ({ campaignId, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState<CampaignFormData | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState<StatusType | ''>('');
  const [inputValue, setInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [formCloseModel, setFormCloseModel] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const [openWithHeader, setOpenWithHeader] = useState(false);
const [selectedLead, setSelectedLead] = useState<any>(null);


const onLeadClick = (lead: any) => {
  if (lead?._id) {
    setSelectedLead(lead);
    setOpenWithHeader(true);
  } else {
    message.error('Invalid lead selected');
  }
};

  const fetchCampaignData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getCampaignById(campaignId, {
        page: currentPage,
        perPage: pageSize,
        status: selectedStatus || undefined,
        search: searchTerm || undefined,
        orderBy: 'lastActivity',
        isAscending: false
      });

      setCampaignData(data.data);
    } catch (error) {
      console.error('Error fetching campaign details:', error);
    } finally {
      setLoading(false);
    }
  }, [campaignId, currentPage, pageSize, selectedStatus, searchTerm]);

  useEffect(() => {
    fetchCampaignData();
  }, [fetchCampaignData]);

  // Debounced search handler
  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
      setCurrentPage(1);
    }, 300),
    []
  );



  // Add this with other handlers in CampaignView component
const handleStatusChange = (value: StatusType | '') => {
  setSelectedStatus(value);
  setCurrentPage(1); // Reset to first page when status changes
};

// Add immediate input handler
const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;
  setInputValue(value);  // Update UI immediately
  handleSearch(value);   // Debounced API call
};

const handleExportModalToggle = () => {
  setIsExportModalOpen(!isExportModalOpen);
};

// Modify clear handler to clear both states
const handleClearSearch = () => {
  setInputValue('');
  setSearchTerm('');
  setCurrentPage(1);
};


const { userDetails } = useSelector((state: any) => {
  return state?.rootReducers.userData;
});




const confirmExport = async () => {
  try {
    await LeadExportService.exportLeads(
      campaignId,
      {
        status: selectedStatus,
        searchTerm: searchTerm
      }
    );
    
    setIsExportModalOpen(false);
  } catch (error) {
    console.error('Export error:', error);
    setIsExportModalOpen(false);
  }
};


  // Keeping the styling components
  const StatusBadge: React.FC<{ status: StatusType }> = ({ status }) => (
    <span className="status-badge" style={{ backgroundColor: STATUS_COLORS[status] }}>
      {status}
    </span>
  );

  const ActionButtons: React.FC<{ lead: LeadData }> = ({ lead }) => {
    const handleAction = (url: string) => {
      window.open(url, '_blank');
    };
  
    const contactLinks = new ContactLinksGenerator({
      number: lead.phone || '',
      email: lead.email || '',
      message: `Hi ${lead.name || ''},`
    });
  
    return (
      <div className="flex items-center gap-2 bg-gray-50 rounded-lg px-2">
        {lead.phone && (
          <button 
            onClick={() => handleAction(contactLinks.tel)}
            className="p-2.5 hover:bg-gray-100 rounded-full transition-colors duration-200"
            title="Call"
          >
            <Phone size={16} strokeWidth={2} className="text-[#1677FF]" />
          </button>
        )}
        
        {lead.email && (
          <button 
            onClick={() => handleAction(contactLinks.mail)}
            className="p-2.5 hover:bg-gray-100 rounded-full transition-colors duration-200"
            title="Email"
          >
            <Mail size={16} strokeWidth={2} className="text-[#F1B003]" />
          </button>
        )}
        
        {lead.phone && (
          <button 
            onClick={() => handleAction(contactLinks.whatsapp)}
            className="p-2.5 hover:bg-gray-100 rounded-full transition-colors duration-200"
            title="WhatsApp"
          >
            <MessageCircle size={16} strokeWidth={2} className="text-[#4CB050]" />
          </button>
        )}

        <button 
          onClick={(e) => {
            e.stopPropagation();
            onLeadClick(lead);
          }}
          className="p-2.5 hover:bg-gray-100 rounded-full transition-colors duration-200"
          title="View Lead Details"
        >
          <ArrowUpRight size={16} strokeWidth={2} className="text-gray-600" />
        </button>
      </div>
    );
};

  if (!campaignData) {
    return <LoadingSpinner />;
  }
  


 

 return (
  <div className="campaign-view">
  <div className="campaign-header">
  <div className="header-content">
    <button className="back-button" onClick={onClose}>
      <ArrowLeft size={24} />
    </button>
    <div className="campaign-info">
      <h1>{campaignData.name}</h1>
      <div className="campaign-meta">
        <span>
          Sent by {campaignData.createdBy.firstName} {campaignData.createdBy.lastName}
        </span>
        <span>•</span>
        <span>{moment(campaignData.createdAt).format('Do MMM YYYY [at] h:mm A')}</span>
        {renderTemplateOrContent(campaignData)}
      </div>
      {campaignData.error && getErrorDisplay(campaignData.error) && (
  <div className="error-message text-red-500 mt-1 text-sm">
    {getErrorDisplay(campaignData.error)}
  </div>
)}
    </div>
    {/* Move the info message to the right side */}
    <div className="info-message">
      <AlertCircle size={16} color="#faad14" />
      <span>Metrics might update or change as we receive more data.</span>
    </div>
  </div>
</div>
   

    <div className="campaign-content">
      <div className="metrics-grid">
        <Card className="metric-card">
          <div className="metric-header">
            <div className="metric-title">
              <span>Sent</span>
              <Send size={18} color="#1890ff" />
            </div>
            <span className="metric-value">{campaignData.totalSent}</span>
          </div>
          <div className="metric-description">Total messages sent</div>
        </Card>

        <Card className="metric-card">
          <div className="metric-header">
            <div className="metric-title">
              <span>Delivered</span>
              <CheckCircle2 size={18} color="#52c41a" />
            </div>
            <span className="metric-value">{campaignData.totalDelivered}</span>
          </div>
          <div className="metric-description">Successfully delivered</div>
        </Card>

        <Card className="metric-card">
          <div className="metric-header">
            <div className="metric-title">
              <span>Read</span>
              <Eye size={18} color="#722ed1" />
            </div>
            <span className="metric-value">{campaignData.totalRead}</span>
          </div>
          <div className="metric-description">Messages read</div>
        </Card>

        <Card className="metric-card">
          <div className="metric-header">
            <div className="metric-title">
              <span>Replied</span>
              <MessageSquareReply size={18} color="#eb2f96" />
            </div>
            <span className="metric-value">{campaignData.totalReplied}</span>
          </div>
          <div className="metric-description">Responses received</div>
        </Card>
      </div>

      <div className="metrics-grid-secondary">
  <Card className="metric-card">
    <div className="metric-header">
      <div className="metric-title">
        <span>Failed</span>
        <AlertCircle size={18} color="#f5222d" />
      </div>
      <span className="metric-value">{campaignData.totalFailed}</span>
    </div>
    <div className="metric-description">Failed deliveries</div>
  </Card>

  <Card className="metric-card">
    <div className="metric-header">
      <div className="metric-title">Delivery Rate</div>
      <span className="metric-value">{campaignData.totalDeliveredRate}%</span>
    </div>
    <div className="metric-description">Successful delivery percentage</div>
  </Card>

  <Card className="metric-card">
    <div className="metric-header">
      <div className="metric-title">Read Rate</div>
      <span className="metric-value">{campaignData.totalReadRate}%</span>
    </div>
    <div className="metric-description">Message read percentage</div>
  </Card>

  <Card className="metric-card">
    <div className="metric-header">
      <div className="metric-title">Reply Rate</div>
      <span className="metric-value">{campaignData.totalRepliedRate}%</span>
    </div>
    <div className="metric-description">Message reply percentage</div>
  </Card>
</div>

      <Card>
        <div className="leads-table-header">
        <h2>Campaign Leads ({campaignData.totalLeads})</h2>
          <div className="leads-controls">
            <Select
              className="status-select"
              placeholder="Filter Status"
              style={{ width: 160 }} 
              value={selectedStatus}
              onChange={handleStatusChange}
              options={[
                { value: '', label: 'All Status' },
                { value: 'sent', label: 'Sent' },
                { value: 'delivered', label: 'Delivered' },
                { value: 'read', label: 'Read' },
                { value: 'replied', label: 'Replied' },
                { value: 'failed', label: 'Failed' }
               
              ]}
            />
            
            <div className="search-box">
  <Search size={16} />
  <input
    type="text"
    placeholder="Search leads..."
    value={inputValue}  // Use inputValue instead of searchTerm
    onChange={handleInputChange}
  />
  {inputValue && (  // Show X when there's input
    <X 
      size={16}
      className="cursor-pointer"
      onClick={handleClearSearch}
    />
  )}
</div>
<button 
      className="export-button"
      onClick={handleExportModalToggle}
    >
      <Download size={16} />
      Export
    </button>
    <GenericConfirmationModal 
  showModal={isExportModalOpen} 
  toggleModal={handleExportModalToggle} 
  onConfirmation={confirmExport}
  title="Confirm Export" 
  message={`Export ${campaignData?.totalLeads || 0} leads?
    ${selectedStatus ? `Filtered by status: ${selectedStatus}` : ''}
    ${searchTerm ? `Searching for: "${searchTerm}"` : ''}`}
  confirmText="Export"
  cancelText="Cancel"
  isWarning={false}
/>
  
          </div>
        </div>

        <Table
          loading={loading}
          dataSource={campaignData.leads}
          columns={[
            {
              title: 'Lead Name',
              dataIndex: 'name',
              key: 'name',
              render: (name: string) => name || 'N/A'
            },
            {
              title: 'Status',
              dataIndex: 'messageStatus',
              key: 'messageStatus',
              render: (status: StatusType) => {
                const colorMap: { [key: string]: string } = {
                  sent: '#1890ff',      // Blue
                  delivered: '#52c41a',  // Green
                  read: '#722ed1',      // Purple
                  replied: '#eb2f96',   // Pink
                  failed: '#f5222d' 
                };
            
                const color = colorMap[status?.toLowerCase()] || '#8c8c8c';
                
                return (
                  <span className="status-badge" style={{ backgroundColor: color }}>
                    {status ? status.charAt(0).toUpperCase() + status.slice(1) : 'N/A'}
                  </span>
                );
              }
            },
            {
              title: 'Last Activity',
              key: 'lastActivity',
              render: (_, record) => record.lastActivityLabel || 'No activity',
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (_, record) => <ActionButtons lead={record} />,
            },
          ]}
          pagination={{
            total: campaignData.totalLeads,
            pageSize: pageSize,
            current: currentPage,
            onChange: (page) => setCurrentPage(page),
            showSizeChanger: false,
            showTotal: (total) => `Total ${total} items`,
            position: ['bottomCenter']
          }}
          scroll={{ y: 400 }}
          className="leads-table"
        />
      </Card>
      {selectedLead && (
  <DrawerComponent
    openWithHeader={openWithHeader}
    setOpenWithHeader={setOpenWithHeader}
    customHeader={false}
    drawerTitle="Lead Details"
    size="lg"
    className="lead_details"
  >
   <LeadDetails
  _id={selectedLead._id}
  name={selectedLead.name}
  integration={selectedLead.integration}
  customSource={selectedLead.customSource}
  createdAt={selectedLead.createdAt}
  status={selectedLead.status}
  saleValue={selectedLead.saleValue}
  website={selectedLead.website}
  label={selectedLead.label}
  // ... other required props
  deleteLeadFromDrawer={() => {}} // Provide default no-op functions for callback props
  copyLeadToList={() => {}}
  moveLeadToList={() => {}}
  onLabelClick={() => {}}
  onStatusClick={() => {}}
  setFrom={() => {}}
  handleEditModal={() => {}}
  handleLeadDetailsModalOpen={() => {}}
  onLeadFileUpload={() => {}}
  editLead={() => {}}
  leadFileDelete={() => {}}
/>
  </DrawerComponent>
)}
      </div>
  </div>
);
};
export default CampaignView;