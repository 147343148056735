import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  repeatOptions,
  taskDueDates,
  taskDueDatesValues,
} from "../utils/globalConstants";
import moment from "moment";
import Select from "react-select";
import ErrorText from "./errorText";
import * as yup from "yup";
import update from "immutability-helper";
import { FaClock } from "react-icons/fa";
import { DatePicker, TimePicker } from "antd";
import {
  createNewTask,
  updateTaskbyId,
  getUserOrganizationList,
} from "../services/taskService";
import toast, { Toaster } from "react-hot-toast";
import styled from "styled-components";
import { convertTimeToIST, convertToIST } from "../utils/helpers";
import _ from "lodash";
// import _, { isEmpty } from "lodash";
import "./taskItem.css";
import Form from "react-bootstrap/Form";
import TaskLeadSelect from "./TaskLeadSelect";

interface Props {
  action: string;
  status: string;
  updateTaskValue: any;
  leadId?: string;
  drawerClose: () => void;
  fetchTaskStatusFollowUps: (data?: any) => void;
}

interface taskType {
  task: string;
  type: string;
  dueDate: string | undefined;
  toBePerformAt: string | undefined;
  time: string;
  repeat: string;
  taskAssign: string;
  notes: string;
  leadIds: string;
}

const CreateTaskForm: React.FC<PropsWithChildren<Props>> = ({
  updateTaskValue,
  leadId,
  action,
  status,
  drawerClose,
  fetchTaskStatusFollowUps,
}) => {
  const DUE_DATE_FORMAT = "YYYY-MM-DD";
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [date, setDate] = useState<any>(null);
  const [isCustomDate, toggleCustomDate] = useState(false);
  const [leadList, setLeadList] = useState<Array<any>>([]);
  const [taskList, setTaskList] = useState<Array<any>>([]);
  const [taskAssignTo, setTaskAssignTo] = useState<Array<any>>([]);
  const [isCompleted, setIsCompleted] = useState(false);

  const [showLeadSelect, setShowLeadSelect] = useState(false);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  
  const [isLeadSelectOpen, setIsLeadSelectOpen] = useState(false);
const [selectedLeadName, setSelectedLeadName] = useState('');

  const [taskData, setTaskData] = useState<taskType>({
    task: "",
    type: "",
    dueDate: "",
    toBePerformAt: moment().format('YYYY-MM-DDTHH:mm:ss'), // Current date and time
  time: moment().format('YYYY-MM-DDTHH:mm:ss'), // Current date and time
    repeat: "NoRepeat",
    taskAssign: "",
    notes: "",
    leadIds: "",
  });

  const taskOptions = useMemo(() => 
    taskList?.map((task, j) => (
      <option value={task?.value} key={j}>
        {task?.name}
      </option>
    ))
  , [taskList]);

  // Memoize task assign options
  const taskAssignOptions = useMemo(() => 
    taskAssignTo?.map((item: any, j: number) => (
      <option value={item?._id} key={j}>
        {item.firstName} - {item.roleName} - {item.organizationName}
      </option>
    ))
  , [taskAssignTo]);
  
  const [errors, setErrors] = useState({
    task: "",
    type: "",
    dueDate: "",
    toBePerformAt: "",
    time: "",
    repeat: "",
    taskAssign: "",
    notes: "",
  });

  useEffect(() => {
    handleGetUserOrganization();
    setTaskList(StoreData?.user?.userPreferences?.taskType);
    setLeadList(StoreData?.leads?.leads);
  }, []);

  useEffect(() => {
    if (action === "edit") {
      if (Object.values(updateTaskValue).length > 0) {
        console.log('Original UTC time:', updateTaskValue.toBePerformAt);
        
        // Explicitly handle IST conversion (UTC+5:30)
        const utcTime = moment.utc(updateTaskValue.toBePerformAt);
        const istOffset = 330; // IST is UTC+5:30 = 330 minutes
        const localTime = utcTime.utcOffset(istOffset);
        
        console.log('Conversion steps:', {
          originalUTC: utcTime.format(),
          afterISTConversion: localTime.format(),
          displayTime: localTime.format('hh:mm A')
        });
        
        setTaskData({
          task: updateTaskValue?.lead?.[0]?._id ?? updateTaskValue?.lead ?? "",
          type: updateTaskValue.type,
          dueDate: "custom",
          toBePerformAt: localTime.format('YYYY-MM-DDTHH:mm:ss'),
          time: localTime.format('YYYY-MM-DDTHH:mm:ss'),
          repeat: updateTaskValue.repeat || "NoRepeat",
          taskAssign: updateTaskValue?.assignedTo[0]?._id,
          notes: "",
          leadIds: updateTaskValue.lead[0]._id,
        });
  
        console.log('Set form data with IST time:', {
          toBePerformAt: localTime.format('YYYY-MM-DDTHH:mm:ss'),
          formattedDisplayTime: localTime.format('hh:mm A')
        });
  
        toggleCustomDate(true);
        setIsCompleted(updateTaskValue?.isCompleted ?? false);
        setSelectedLeadName(updateTaskValue?.lead?.[0]?.name || '');
      }
    }
  }, [updateTaskValue]);
  
  // Also update the TimePicker component:
<TimePicker
  format="hh:mm A"
  className="select_date"
  value={taskData.time ? moment(taskData.time) : moment()} // Fallback to current time
  onChange={(time) => {
    if (time) {
      console.log('Time picked:', time.format());
      handleDateChange("toBePerformAt", time, "time");
    }
  }}
/>

const handleLeadSelect = useCallback((leadId: string, leadName: string) => {
  setTaskData(prev => ({
    ...prev,
    task: leadId
  }));
  setSelectedLeadName(leadName);
  setErrors(prev => ({
    ...prev,
    task: ""
  }));
}, []);
  
  // Fetch lead name for edit mode
  const fetchLeadName = (leadData: any) => {
    if (!leadData) return '';
    if (Array.isArray(leadData) && leadData.length > 0) {
      return leadData[0].name || '';
    }
    return leadData.name || '';
  };

  const handleGetUserOrganization = async () => {
    const data = localStorage.getItem("userData");
    if (data) {
      let tempArray = [...JSON.parse(data).organizationEmployee];
      tempArray.forEach((el: any) => {
        let findRole = StoreData.userData.userDetails.organizationRoles.find(
          (x: any) => x._id == el.role
        ).displayName;
        el.roleName = findRole;
        el.organizationName = StoreData.userData.userDetails.organization.name;
      });

      setTaskAssignTo(tempArray);
    }

    // try {
    //   const response = await getUserOrganizationList({
    //     page: 1,
    //     perPage: 15,
    //   });
    //   if (response && response.status) {
    //     let tempArray = [...response.data.data];
    //     tempArray.forEach((el: any) => {
    //       let findRole = StoreData.userData.userDetails.organizationRoles.find(
    //         (x: any) => x._id == el.role
    //       ).displayName;
    //       el.roleName = findRole;
    //       el.organizationName =
    //         StoreData.userData.userDetails.organization.name;
    //     });

    //     setTaskAssignTo(tempArray);
    //   }
    // } catch (err) {
    //   console.log(err, "Error");
    // }
  };

  const handleSetDueDate = (dueDateType: string) => {
    setErrors({
      ...errors,
      toBePerformAt: "",
    });
  
    switch (dueDateType) {
      case "today":
      case "tomorrow":
      case "3d":
      case "1w":
      case "1m":
        const dt = moment()
          .add(taskDueDatesValues[dueDateType as keyof typeof taskDueDatesValues], "days")
          .format("YYYY-MM-DD");
          
        // Preserve existing time if it exists
        const existingTime = taskData.time 
          ? moment(taskData.time).format("HH:mm:ss")
          : moment().format("HH:mm:ss");
        
        const combined = moment(dt + "T" + existingTime);
        
        setTaskData({
          ...taskData,
          toBePerformAt: combined.toISOString(),
          dueDate: dueDateType,
          time: combined.toISOString()
        });
        toggleCustomDate(false);
        setIsDatePickerOpen(false);
        break;
  
      case "custom":
        // Preserve current date/time when switching to custom
        const currentDateTime = taskData.toBePerformAt 
          ? moment(taskData.toBePerformAt)
          : moment();
          
        setTaskData({
          ...taskData,
          toBePerformAt: currentDateTime.toISOString(),
          dueDate: dueDateType,
          time: currentDateTime.toISOString()
        });
        toggleCustomDate(true);
        // Auto open the date picker
        setIsDatePickerOpen(true);
        break;
  
      default:
        break;
    }
  };

  let schema = yup.object().shape({
    task: yup.string().required("task is required"),
    type: yup.string().required("Task Type is required"),
    dueDate: yup
      .string()
      .required("due Date is required")
      .nullable()
      .notRequired(),
    toBePerformAt: yup.string().required("due Date is required"),
    // repeat: yup.string().required("Repeat is required"),
    time: yup.string().required("time is required"),
    // taskAssign: yup.string().required("task Assigned is required"),
    // notes: yup.string().required("Notes is required"),
  });

  const handleChange = useCallback((
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setTaskData(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: null,
    }));
  }, []);
  const handleDateChange = (
    name: string,
    value: moment.Moment | null, 
    type = ""
  ) => {
    console.log('handleDateChange entry:', {
      type,
      value: value?.format(),
      currentTime: moment(taskData.toBePerformAt).format('HH:mm'),
      fullState: taskData
    });
  
    if (type === "time") {
      // Time picker logic
      const newTime = moment(value).format("HH:mm"); 
      const currentDate = moment(taskData.toBePerformAt).format("YYYY-MM-DD");
      const combined = moment(`${currentDate} ${newTime}`, "YYYY-MM-DD HH:mm");
  
      console.log('Time change:', {
        newTime,
        currentDate, 
        combined: combined.format()
      });
  
      setTaskData({
        ...taskData,
        toBePerformAt: combined.toISOString(),
        time: combined.toISOString()
      });
  
    } else {
      // Date picker logic
      const newDate = moment(value).format("YYYY-MM-DD");
      const existingTime = moment(taskData.toBePerformAt).format("HH:mm");
  
      console.log('Date change:', {
        newDate,
        keepingTime: existingTime,
        beforeCombine: taskData.toBePerformAt
      });
  
      const combined = moment(`${newDate} ${existingTime}`, "YYYY-MM-DD HH:mm");
  
      console.log('After date combine:', {
        result: combined.format(),
        preserved: existingTime === moment(combined).format("HH:mm")
      });
  
      setTaskData({
        ...taskData,
        toBePerformAt: combined.toISOString(),
        time: combined.toISOString()
      });
    }
  };

  const handleTaskSubmit = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isFormValid = await schema.isValid(taskData, {
      abortEarly: false,
    });
    if (isFormValid) {
      // let UtcDate = moment
      //   .parseZone(`${taskData.toBePerformAt}T${taskData.time}`)
      //   .utc()
      //   .format();

      const UtcDate = () => {
        if (taskData.toBePerformAt) {
          const combinedDateTimeString =
            taskData.toBePerformAt.slice(0, 11) + taskData.time + "Z";
          const combinedDateTime = new Date(combinedDateTimeString);
          return combinedDateTime.toISOString();
        } else {
          return "";
        }
      };
      let tempLeadIds = [];
      let assignData = [];
      if (taskData.taskAssign?.length > 0) {
        assignData.push(taskData.taskAssign);
      } else {
        assignData = [];
      }

      tempLeadIds.push(taskData.task);
      let tempObj: any = {};
      if (action === "add") {
        tempObj = {
          leadIds: tempLeadIds,
          type: taskData.type,
          toBePerformAt: taskData.toBePerformAt,
          // toBePerformAt: UtcDate,
          extraDetails: {},
          ...(assignData?.length > 0 && {
            assignedTo: assignData,
          }),
          ...(taskData.repeat !== "" && {
            repeat: taskData.repeat,
          }),
          ...(taskData.notes?.length > 0 && {
            notes: taskData.notes,
          }),
        };
      } else {
        tempObj = {
          type: taskData.type,
          toBePerformAt: taskData.toBePerformAt,
          extraDetails: {},
          ...(assignData?.length > 0 && {
            assignedTo: assignData,
          }),
          ...(taskData.repeat !== "" && {
            repeat: taskData.repeat,
          }),
          ...(taskData.notes?.length > 0 && {
            notes: taskData.notes,
          }),
          isCompleted: isCompleted,
        };
      }

      try {
        let response: any = "";
        if (action === "add") {
          response = await createNewTask(tempObj);
        } else {
          response = await updateTaskbyId(updateTaskValue._id, tempObj);
        }
        if (response && response.status) {
          drawerClose();
          fetchTaskStatusFollowUps(response?.data);
          toast.success(response?.data?.message);
        }
      } catch (err) {
        toast.error("Error while creating new task");
      }
    } else {
      schema.validate(taskData, { abortEarly: false }).catch((err) => {
        const errors = err.inner.reduce(
          (acc: any, error: { path: string; message: string }) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          },
          {}
        );
        setErrors((prevErrors) =>
          update(prevErrors, {
            $set: errors,
          })
        );
      });
    }
  }, [taskData, schema, errors]);

  const leadForTask = action == "edit" ? updateTaskValue?.lead : leadList;
  const previousDate = moment(taskData.toBePerformAt).format("DD MMM YYYY");

  const renderPreviousNotes = () => {
    if (!updateTaskValue?.noteLogs?.length) return null;
  
    const sortedNotes = _.orderBy(
      updateTaskValue?.noteLogs,
      [(obj) => new Date(obj.createdAt)],
      ["desc"]
    );
  
    return (
      <NotesContainer>
        <NotesTitle>Previous Notes</NotesTitle>
        {sortedNotes
          ?.filter((e) => e.note)
          .map((e, i) => (
            <NoteItem key={i}>
              <NoteContent>{e.note}</NoteContent>
              <NoteMeta>
                <MetaItem>{moment(e.createdAt).format("DD MMM")}</MetaItem>
                <MetaItem>{moment(e.createdAt).format("hh:mm a")}</MetaItem>
                <MetaItem>by {getNotesCreatedBy(e?.createdBy)}</MetaItem>
              </NoteMeta>
            </NoteItem>
          ))}
      </NotesContainer>
    );
  };

  const getNotesCreatedBy = (id: string) => {
    let employee = StoreData?.userData.userDetails.organizationEmployee ?? [];

    let name = "";

    name = employee?.find(({ _id }: any) => _id == id)?.firstName ?? "";

    return name;
  };

  const previousTimeValue = taskData.time ? moment(taskData.time) : undefined;

  return (
    <Root>
      {/* <form id="LeadsFilterForm" className="follwup_drower"> */}
      <div>
      {!leadId && (
  <div className="form-group">
    <label className="form_label">Task for </label>
    <input
      type="text"
      className="form-control"
      value={selectedLeadName || 'Select a lead'}
      onClick={() => setShowLeadSelect(true)}
      readOnly
      style={{ 
        cursor: 'pointer',
        backgroundColor: '#fff'
      }}
    />
    {errors.task ? <ErrorText message={errors?.task} /> : ""}
    
    <TaskLeadSelect
  isOpen={showLeadSelect}
  onClose={() => setShowLeadSelect(false)}
  onSelect={handleLeadSelect}
  leadList={leadForTask}
  error={errors.task}
/>
  </div>
)}
         
        <div className="form-group">
          <label className="form_label">Task Type </label>
          <select
            id="inputState"
            value={taskData?.type}
            onChange={(e) => handleChange(e)}
            name="type"
            className="form-select"
          >
            <option value="">Select Task Type</option>
            {taskList?.map((task, j) => (
              <option value={task?.value} key={j}>
                {task?.name}
              </option>
            ))}
          </select>
          {errors.type ? <ErrorText message={errors?.type} /> : ""}
        </div>
        <div className="form-group">
          <label className="form_label">Due Date</label>
          <select
            className="form-select"
            value={taskData?.dueDate}
            name="dueDate"
            onChange={(e) => {
              handleSetDueDate(e.target.value);
            }}
          >
            <option value="">Select due date</option>
            {taskDueDates.map((dueDate, d) => (
              <option value={dueDate.value} key={d}>
                {dueDate.name}
              </option>
            ))}
          </select>
          {errors.dueDate ? <ErrorText message={errors?.dueDate} /> : ""}
        </div>
        {isCustomDate && (
  <div className="form-group">
    <label className="form_label">Custom Date</label>
    <DatePicker
      format="DD MMM YYYY"
       aria-label="Select custom date"
      value={moment(taskData.toBePerformAt)}
      className="select_date"
      open={isDatePickerOpen}
      onOpenChange={(open) => setIsDatePickerOpen(open)}
      onChange={(date) => {
        if (date) {
          handleDateChange("toBePerformAt", moment(date));
          setIsDatePickerOpen(false);
        }
      }}
    />
    {errors.toBePerformAt ? (
      <ErrorText message={errors?.toBePerformAt} />
    ) : (
      ""
    )}
  </div>
)}
        <div className="form-group">
          <label className="form_label">Time</label>
          <div className="timepicker-element">
          <TimePicker
  format="hh:mm A"
  className="select_date"
  value={moment(taskData.toBePerformAt)}
  onChange={(time) => {
    console.log('TimePicker onChange:', {
      newTime: time?.format(),
      currentToBePerformAt: taskData.toBePerformAt,
      currentTime: taskData.time,
      timeFormat: moment(taskData.toBePerformAt).format('HH:mm')
    });
    if (time) {
      handleDateChange("toBePerformAt", time, "time");
    }
  }}
/>

          </div>
          {errors.time ? <ErrorText message={errors?.time} /> : ""}
        </div>
        <div className="form-group">
          <label className="form_label">Repeat</label>
          <select
            id="inputState"
            value={taskData?.repeat}
            onChange={(e) => handleChange(e)}
            name="repeat"
            className="form-select"
          >
            {/* <option value="">Don't Repeat</option> */}
            {/* <option disabled selected>
              Select an option
            </option> */}
            {repeatOptions.map((option, o) => (
              <option value={option.value} key={o}>
                {option?.name}
              </option>
            ))}
          </select>
          {/* {errors.repeat ? <ErrorText message={errors?.repeat} /> : ""} */}
        </div>
        <div className="form-group">
          <label className="form_label">Task Assign to</label>
          <select
            id="inputState"
            value={taskData?.taskAssign}
            onChange={(e) => handleChange(e)}
            name="taskAssign"
            className="form-select"
          >
            <option value="">Select Task Assign to</option>
            {taskAssignTo?.map((item: any, j: number) => {
              return (
                <option value={item?._id} key={j}>
                  <label>
                    {/* {item.firstName} */}
                    {item.firstName} - {item.roleName} - {item.organizationName}
                  </label>
                </option>
              );
            })}
          </select>
          {/* {errors.taskAssign ? (
              <ErrorText message={errors?.taskAssign} />
            ) : (
              ""
            )} */}
        </div>
        <div className="form-group">
          <label className="form_label">Note</label>
          <textarea
            className="form-control note_area"
            value={taskData?.notes}
            onChange={(e) => handleChange(e)}
            name="notes"
            style={{ height: "120px" }}
            placeholder="Enter Note"
          ></textarea>
          {/* {errors.notes ? (
              <ErrorText message={errors?.notes} />
            ) : (
              ""
            )} */}
        </div>
        {renderPreviousNotes()}

        {action === "edit" ? (
          <Form.Check // prettier-ignore
            type={"checkbox"}
            id={`checkbox`}
            label={`Mark as completed`}
            className="mark_checkbox"
            checked={isCompleted}
            onChange={() => setIsCompleted(!isCompleted)}
          />
        ) : null}

        {/* {(status === "today" || status === "overdue") && (
          <>
            {action === "edit" && (
              <div className="form-group">
                <input
                  type="checkbox"
                  checked={isCompleted}
                  id="isCompleted"
                  name="isCompleted"
                  onChange={() => setIsCompleted(!isCompleted)}
                />
                &nbsp;
                <label htmlFor="isCompleted" className="form_label">
                  Mark as Complete
                </label>
                <br></br>
              </div>
            )}
          </>
        )} */}
      </div>

      <div className="auto_form_btn">
        <button
          type="button"
          onClick={(e) => handleTaskSubmit(e)}
          className="btn btn-primary-save"
        >
          {action === "add" ? "Add Task" : "Update Task"}
        </button>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </Root>
  );
};

export default CreateTaskForm;
const Root = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .form-group {
    margin: 6px 0px;
  }
  .auto_form_btn {
    position: sticky;
    margin-top: auto;
    bottom: 0px;
  }
  .select_date {
    display: block;
    background: #fff;
    border-radius: 2px;
    padding: 8px;
    border: 1px solid #ced4da;
    span.rs-picker-toggle-value {
      color: #000;
    }
    &:focus-visible {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }
`;
const NotesContainer = styled.div`
  margin-top: 16px;
  width: 100%;
`;

const NotesTitle = styled.h6`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 12px;
`;

const NoteItem = styled.div`
  background: #f8f9fa;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 6px;
  transition: background-color 0.2s ease;

  &:hover {
    background: #f3f4f6;
  }
`;

const NoteContent = styled.div`
  font-size: 13px;
  color: #1a202c;
  line-height: 1.4;
  margin-bottom: 4px;
`;

const NoteMeta = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: #718096;
`;

const MetaItem = styled.span`
  color: #718096;
  
  &:not(:last-child)::after {
    content: "•";
    margin-left: 8px;
    color: #CBD5E0;
  }
`;
