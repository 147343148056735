import { useEffect, useRef, useState } from "react";

type PropsType = {
  disabled?: boolean;
  name: string;
  className: string;
  type: string;
  value: string | string[];  // Only change: allow string[] for multi-select
  placeholder: string;
  onChange: (e: any) => void;
  label: string;
  autoComplete: string;
  isRequired: boolean;
  options: any;  // Keep as any to maintain backward compatibility
  id: string;
  des?: string;
};

export default function Inputs(Props: PropsType) {
  const { label, isRequired, ...rest } = Props;
  // Add states only for multi-select
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Handle click outside only for multi-select
  useEffect(() => {
    if (Props.type !== 'multi-selection') return;
    
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [Props.type]);

  return (
    <>
      <div>
        {Props.label != "" && (
          <label className="form-label">
            {label} <span className="text-danger">{isRequired && "*"}</span>
          </label>
        )}
        {Props.label === "Client Name" ? (
          <img src={"assets/img/person.png"} alt="img" className="form-icons" />
        ) : Props.label === "Email ID" ? (
          <img src={"assets/img/mail.png"} alt="img" className="form-icons" />
        ) : Props.label === "Phone Number" ? (
          <img src={"assets/img/call.png"} alt="img" className="form-icons" />
        ) : Props.label === "Sale Value" ? (
          <img src={"assets/img/rupees.png"} alt="img" className="form-icons" />
        ) : (
          ""
        )}

        {Props.des ? (
          <p className="fs-8 mb-2" style={{ marginTop: "-6px" }}>
            {Props.des}
          </p>
        ) : null}

        {label !== "Notes" && label !== "Description" ? (
          <>
            {Props.type === "multi-selection" ? (
              <div className="position-relative" ref={dropdownRef}>
                <div 
                  className="form-control d-flex align-items-center justify-content-between cursor-pointer"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <span>
                    {Array.isArray(Props.value) && Props.value.length > 0
                      ? `${Props.value.length} selected`
                      : Props.placeholder || 'Select options'}
                  </span>
                  <i className="ri-arrow-down-s-line fs-5"></i>
                </div>
                
                {isOpen && Array.isArray(Props.options) && (
                  <div className="dropdown-menu show w-100 p-0 mt-1">
                    {Props.options.map((option, index) => (
                      <div 
                        key={index}
                        className="dropdown-item d-flex align-items-center gap-2 py-2"
                        onClick={() => {
                          const currentValue = Array.isArray(Props.value) ? Props.value : [];
                          const newValue = currentValue.includes(option)
                            ? currentValue.filter(v => v !== option)
                            : [...currentValue, option];
                          
                          Props.onChange({
                            target: {
                              name: Props.name,
                              value: newValue
                            }
                          });
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={Array.isArray(Props.value) && Props.value.includes(option)}
                          onChange={() => {}} // Handled by div click
                          className="form-check-input m-0"
                        />
                        <span>{option}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : Props.type !== "selection" ? (
              <input
                {...rest}
                onKeyDown={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
              />
            ) : (
              <div className="position-relative">
                <select {...rest}>
                  <option value="">Select {label}</option>
                  {Props.options?.map((type: any, i: number) => (
                    <option value={type} key={i}>
                      {type}
                    </option>
                  ))}
                </select>
                <i className="ri-arrow-down-s-line position-absolute end-0 top-50 translate-middle-y fs-5 me-2"></i>
              </div>
            )}
          </>
        ) : (
          <textarea style={{ height: "120px" }} {...rest} />
        )}
      </div>
    </>
  );
}