import { 
  MessageSquare, CheckSquare, Eye, Pencil,
  Clock, ListPlus, Bot, CheckCircle2, DollarSign,
  PhoneCall, FileText, Upload, ListChecks,
  Facebook, ArrowRight, Copy, File,
  AlertCircle
} from 'lucide-react';

export const ACTIVITY_TYPES = {
  // Communication & Messages
  WHATSAPP_MESSAGE_SENDING: 'whatsapp_automation_message_sending',
  WHATSAPP_MESSAGE_FAILED: 'whatsapp_automation_message_failed',
  NOTE_CREATED: 'note_created',
  OUTGOING_CALL: 'outgoing_call',
  MESSAGE_SENT: 'message_sent',
  MESSAGE_FAILED: 'message_failed',

  // Task Management
  TASK_CREATED: 'task_created',
  TASK_COMPLETED: 'task_completed',
  TASK_UPDATED: 'task_updated',
  TASK_ASSIGNED: 'task_assigned',
  TASK_NOTE_ADDED: 'task_note_added',
  FOLLOW_UP_TASK: 'follow_up_task',
  TASK_DUE_DATE_UPDATED: 'task_due_date_updated',

  // Lead Management
  STATUS_UPDATED: 'status_updated',
  LEAD_MOVED: 'lead_moved',
  LEAD_COPIED: 'lead_copied',
  LEAD_ASSIGNED: 'lead_assigned',
  LEAD_SOURCE_UPDATED: 'lead_source_updated',
  NEW_LEAD_FACEBOOK: 'new_lead_facebook',
  
  // Automation & System
  AUTOMATION: 'automation',
  AUTO_LEAD_ASSIGNED: 'auto_lead_assigned',
  AUTOMATION_TRIGGERED: 'automation_triggered',
  INTEGRATION_SYNC: 'integration_sync',
  
  // Content & Documents
  CONTENT_SHARED: 'content_shared',
  CONTENT_VIEWED: 'content_viewed',
  QUOTE_EDITED: 'quote_edited',
  QUOTE_CREATED: 'quote_created',
  QUOTE_APPROVED: 'quote_approved',
  FILE_UPLOAD: 'file_upload',
  FILE_SHARED: 'file_shared',

  // Custom & Others
  CHECKIN: 'checkin',
  CUSTOM_ACTIVITY: 'custom_activity',
  CUSTOM_FIELD_UPDATED: 'custom_field_updated'
} as const;

export type ActivityType = keyof typeof ACTIVITY_TYPES;

interface ActivityMeta {
  icon: any; // Using any for Lucide icon components
  color: string;
  bgColor: string;
  status: 'success' | 'warning' | 'error' | 'info';
}

export const getActivityMeta = (type: string): ActivityMeta => {
  const metaMap: Record<string, ActivityMeta> = {
    // Communication
    [ACTIVITY_TYPES.WHATSAPP_MESSAGE_SENDING]: {
      icon: MessageSquare,
      color: '#FFFFFF',
      bgColor: '#22C55E',
      status: 'info'
    },
    [ACTIVITY_TYPES.WHATSAPP_MESSAGE_FAILED]: {
      icon: AlertCircle,
      color: '#FFFFFF',
      bgColor: '#EF4444',
      status: 'error'
    },
    [ACTIVITY_TYPES.NOTE_CREATED]: {
      icon: MessageSquare,
      color: '#FFFFFF',
      bgColor: '#6366F1',
      status: 'info'
    },
    [ACTIVITY_TYPES.OUTGOING_CALL]: {
      icon: PhoneCall,
      color: '#FFFFFF',
      bgColor: '#F59E0B',
      status: 'warning'
    },

    // Task Management
    [ACTIVITY_TYPES.TASK_CREATED]: {
      icon: CheckSquare,
      color: '#FFFFFF',
      bgColor: '#8B5CF6',
      status: 'info'
    },
    [ACTIVITY_TYPES.TASK_COMPLETED]: {
      icon: CheckCircle2,
      color: '#FFFFFF',
      bgColor: '#10B981',
      status: 'success'
    },
    [ACTIVITY_TYPES.TASK_UPDATED]: {
      icon: Clock,
      color: '#FFFFFF',
      bgColor: '#6366F1',
      status: 'info'
    },

    // Lead Management
    [ACTIVITY_TYPES.STATUS_UPDATED]: {
      icon: ArrowRight,
      color: '#FFFFFF',
      bgColor: '#F59E0B',
      status: 'warning'
    },
    [ACTIVITY_TYPES.LEAD_MOVED]: {
      icon: ListPlus,
      color: '#FFFFFF',
      bgColor: '#3B82F6',
      status: 'info'
    },

    // Automation
    [ACTIVITY_TYPES.AUTOMATION]: {
      icon: Bot,
      color: '#FFFFFF',
      bgColor: '#8B5CF6',
      status: 'info'
    },
    [ACTIVITY_TYPES.AUTO_LEAD_ASSIGNED]: {
      icon: Bot,
      color: '#FFFFFF',
      bgColor: '#3B82F6',
      status: 'info'
    },

    // Content & Documents
    [ACTIVITY_TYPES.CONTENT_SHARED]: {
      icon: Eye,
      color: '#FFFFFF',
      bgColor: '#3B82F6',
      status: 'info'
    },
    [ACTIVITY_TYPES.CONTENT_VIEWED]: {
      icon: Eye,
      color: '#FFFFFF',
      bgColor: '#3B82F6',
      status: 'info'
    },
    [ACTIVITY_TYPES.QUOTE_EDITED]: {
      icon: Pencil,
      color: '#FFFFFF',
      bgColor: '#3B82F6',
      status: 'info'
    },
    [ACTIVITY_TYPES.QUOTE_CREATED]: {
      icon: DollarSign,
      color: '#FFFFFF',
      bgColor: '#3B82F6',
      status: 'info'
    },
    [ACTIVITY_TYPES.QUOTE_APPROVED]: {
      icon: CheckCircle2,
      color: '#FFFFFF',
      bgColor: '#10B981',
      status: 'success'
    },
    [ACTIVITY_TYPES.FILE_UPLOAD]: {
      icon: File,
      color: '#FFFFFF',
      bgColor: '#3B82F6',
      status: 'info'
    },
    [ACTIVITY_TYPES.FILE_SHARED]: {
      icon: FileText,
      color: '#FFFFFF',
      bgColor: '#3B82F6',
      status: 'info'
    },
    
    // Custom & Others
    [ACTIVITY_TYPES.CHECKIN]: {
      icon: ListChecks,
      color: '#FFFFFF',
      bgColor: '#10B981',
      status: 'success'
    },
    [ACTIVITY_TYPES.CUSTOM_ACTIVITY]: {
      icon: MessageSquare,
      color: '#FFFFFF',
      bgColor: '#6366F1',
      status: 'info'
    },
    [ACTIVITY_TYPES.CUSTOM_FIELD_UPDATED]: {
      icon: Pencil,
      color: '#FFFFFF',
      bgColor: '#3B82F6',
      status: 'info'
    }
  };

  return metaMap[type] || {
    icon: Clock,
    color: '#FFFFFF',
    bgColor: '#64748B',
    status: 'info'
  };
};

// Activity Status Colors
export const STATUS_COLORS = {
  success: '#10B981',
  warning: '#F59E0B',
  error: '#EF4444',
  info: '#3B82F6'
} as const;


export type ActivityStatus = 'success' | 'warning' | 'error' | 'info';



// Activity Content Templates
export const ACTIVITY_TEMPLATES = {
  [ACTIVITY_TYPES.STATUS_UPDATED]: {
    getDescription: (prevStatus: string, nextStatus: string) => 
      `Status changed from ${prevStatus} to ${nextStatus}`
  },
  [ACTIVITY_TYPES.LEAD_MOVED]: {
    getDescription: (listName: string) => 
      `Lead moved to list ${listName}`
  },
  [ACTIVITY_TYPES.WHATSAPP_MESSAGE_SENDING]: {
    getDescription: (campaign?: string) => 
      `WhatsApp automation message sending${campaign ? ` for ${campaign}` : ''}`
  }
} as const;