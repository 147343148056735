import React, { useState } from 'react';
import { Package, Share2 } from 'lucide-react';
import Header from "../../components/header";
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import SubscriptionPage from '../Subscription/SubscriptionPage';
import LeadDistribution from '../settings/leaddistribution';

// Types
interface NavOption {
  id: string;
  label: string;
  icon: React.ReactNode;
}

interface SettingsContainerProps {
  children?: React.ReactNode;
  onNavigationChange?: (navId: string) => void;
}

// Styled Components
const MainContainer = styled.div`
  display: flex;
  height: calc(100vh - 30px);
  width: calc(100% - 225px);
  background-color: #f8f9fa;
  margin-left: 225px; /* Space for main sidebar */
  margin-top: 30px; /* Space for header */
`;

const Sidebar = styled.nav`
  width: 225px;
  background-color: white;
  border-right: 1px solid #e9ecef;
  padding: 20px 0;
  height: 100%;
`;

const TopBar = styled.header`
  height: 30px;
  background-color: white;
  border-bottom: 1px solid #e9ecef;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

const ContentArea = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100vh - 30px); /* Subtract header height */
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

const Logo = styled.div`
  padding: 0 20px 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e9ecef;
  font-size: 24px;
  font-weight: bold;
`;

const NavItem = styled.button<{ active?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.active ? '#1a73e8' : '#5f6368'};
  background-color: ${props => props.active ? '#e8f0fe' : 'transparent'};
  font-size: 14px;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${props => props.active ? '#e8f0fe' : '#f1f3f4'};
  }

  svg {
    margin-right: 12px;
  }
`;

const PageTitle = styled.h1`
  font-size: 20px;
  color: #202124;
  margin: 0;
  font-weight: 500;
`;

const SettingsContainer: React.FC<SettingsContainerProps> = ({ 
  children, 
  onNavigationChange 
}) => {
  const [activeNav, setActiveNav] = useState('subscriptions');

  const navigate = useNavigate();
  

  const renderContent = () => {
    switch(activeNav) {
      case 'subscriptions':
        return <SubscriptionPage />;
      case 'distribution':
        return <LeadDistribution />;
      default:
        return <SubscriptionPage />;
    }
  };

  const handleNavigationChange = (navId: string) => {
    switch (navId) {
      case 'subscriptions':
        navigate('/settings/subscriptions');
        break;
      case 'distribution':
        navigate('/settings/leaddistribution');
        break;
    }
  };

  const navOptions: NavOption[] = [
    {
      id: 'subscriptions',
      label: 'Subscriptions',
      icon: <Package size={20} />
    },
    {
      id: 'distribution',
      label: 'Distribution',
      icon: <Share2 size={20} />
    }
  ];

  const handleNavChange = (navId: string) => {
    setActiveNav(navId);
  };

  return (
    <MainContainer>
      <Sidebar>
        <Logo>Settings</Logo>
        {navOptions.map(option => (
          <NavItem
            key={option.id}
            active={activeNav === option.id}
            onClick={() => handleNavChange(option.id)}
          >
            {option.icon}
            {option.label}
          </NavItem>
        ))}
      </Sidebar>
      
      <ContentArea>
        <Header />
        <Content>
          {renderContent()}
        </Content>
      </ContentArea>
    </MainContainer>
  );
};
export default SettingsContainer;