import React, { useMemo } from 'react';
import moment from 'moment';
import { Pencil } from 'lucide-react';
import { getActivityMeta } from './activityConstants';
import { ActivityItemProps } from './types';
import './ActivityItem.css';

const ActivityItem: React.FC<ActivityItemProps> = React.memo(({ 
  activity, 
  handleEditModal,
  name 
}) => {
  const { icon: Icon, bgColor } = getActivityMeta(activity.type);
  
  const time = useMemo(() => {
    // Parse the UTC timestamp
    const utcDate = moment.utc(activity.performedAt || activity.createdAt);
    
    // Convert to IST
    const istDate = utcDate.utcOffset(330);
    
    // Current moment in IST
    const currentMoment = moment().utcOffset(330);
    
    if (istDate.isSame(currentMoment, 'day')) {
      return `Today ${istDate.format('hh:mm a')}`;
    } else if (istDate.isSame(currentMoment.clone().subtract(1, 'day'), 'day')) {
      return `Yesterday ${istDate.format('hh:mm a')}`;
    } else if (istDate.isSame(currentMoment, 'year')) {
      return istDate.format('DD MMM, hh:mm a');
    } else {
      return istDate.format('DD MMM YYYY, hh:mm a');
    }
  }, [activity.performedAt, activity.createdAt]);

  const creatorName = useMemo(() => {
    if (!activity.createdBy) return '';
    if (typeof activity.createdBy === 'string') return activity.createdBy;
    return `${activity.createdBy.firstName || ''} ${activity.createdBy.lastName || ''}`.trim();
  }, [activity.createdBy]);

  const getActivityBackground = (type: string) => {
    switch (type) {
      case 'task_created':
      case 'task_completed':
      case 'automation':
      case 'task_note_added':
        return 'bg-activity-purple';
      case 'lead_moved':
      case 'lead_copied':
      case 'status_updated':
        return 'bg-activity-blue';
      default:
        return '';
    }
  };

  const renderActivityContent = () => {
    switch (activity.type) {
      case 'task':
        return (
          <div className="activity-description">
            <div>task</div>
            {activity.notes && (
              <div className="note-content">
                {activity.notes}
              </div>
            )}
          </div>
        );

      case 'note_created':
        return (
          <div className="activity-description">
            <div>
              {activity.extraDetails?.quotation || activity.notes}
            </div>
            {activity.notes && activity.notes !== activity.extraDetails?.quotation && (
              <div className="note-content">
                {activity.notes}
              </div>
            )}
          </div>
        );

        case 'status_updated':
          return (
            <div className="activity-description">
              <div>
                Status changed from{' '}
                <span>
                  {activity.extraDetails?.prevStatus?.[0] || 'none'}
                </span>
                {' '}to{' '}
                <span>
                  {activity.extraDetails?.nextStatus}
                </span>
              </div>
            </div>
          );

      case 'lead_moved':
        return (
          <div className="activity-description">
            <div>
              To list: In{' '}
              <span className="fw-medium">
                {activity.extraDetails?.listName}
              </span>
            </div>
          </div>
        );

      case 'automation':
        return (
          <div className="activity-description">
            <div>
              Automation rule In follow up List Automation Rule was triggered
            </div>
            {activity.extraDetails?.automationTriggerEvent && (
              <div className="automation-trigger">
                Trigger: {activity.extraDetails.automationTriggerEvent}
              </div>
            )}
          </div>
        );

      case 'whatsapp_automation_message_sending':
        return (
          <div className="activity-description">
            <div>WhatsApp automation message sending</div>
            {activity.extraDetails && (
              <div className="note-content">
                {activity.extraDetails.campaignName && (
                  <div>Campaign: {activity.extraDetails.campaignName}</div>
                )}
                {activity.extraDetails.templateName && (
                  <div>Template: {activity.extraDetails.templateName}</div>
                )}
              </div>
            )}
          </div>
        );

      case 'whatsapp_automation_message_failed':
        return (
          <div className="activity-description">
            <div>WhatsApp automation message failed</div>
            {activity.notes && (
              <div className="note-content">
                {activity.notes}
              </div>
            )}
          </div>
        );

      case 'task_completed':
        return (
          <div className="activity-description">
            <div>Task marked as completed</div>
            {activity.notes && (
              <div className="note-content">
                {activity.notes}
              </div>
            )}
          </div>
        );

        case 'task_created':
          return (
            <div className="activity-description">
              <div>
                {activity.label || 'New task created'}
              </div>
              {activity.task?.toBePerformAt && (
                <div className="note-content">
                  Due: {moment(activity.task.toBePerformAt).format('DD MMM, hh:mm a')}
                </div>
              )}
            </div>
          );
      case 'task_assigned':
        return (
          <div className="activity-description">
            <div>
              Task assigned to{' '}
              <span className="fw-medium">
                {activity.extraDetails?.assignedTo}
              </span>
            </div>
          </div>
        );

      case 'follow_up_task':
        return (
          <div className="activity-description">
            <div>
              Follow-up task updated to{' '}
              <span className="fw-medium">
                {moment(activity.task?.toBePerformAt).format('DD MMM')}
              </span>
            </div>
          </div>
        );

      default:
        return (
          <div className="activity-description">
            <div>{activity.label || activity.description}</div>
            {activity.notes && (
              <div className="note-content">
                {activity.notes}
              </div>
            )}
          </div>
        );
    }
  };

  return (
    <div className={`activity-item ${getActivityBackground(activity.type)}`}>
      <div className="activity-line"></div>
      <div className="activity-icon" style={{ backgroundColor: bgColor }}>
      <Icon size={18} />
       
      </div>

      <div className="activity-content">
        <div className="d-flex justify-content-between align-items-center">
        <div className="activity-time">{time}</div>
          {activity.type !== 'automation' && (
            <button
              className="edit-button"
              onClick={() => handleEditModal(name, activity._id, 'edit')}
            >
              <Pencil size={12} />
            </button>
          )}
        </div>

        {renderActivityContent()}

        {creatorName && (
          <div className="creator-info">
            <div className="creator-avatar">
              {creatorName.charAt(0)}
            </div>
            <span>{creatorName}</span>
          </div>
        )}
      </div>
    </div>
  );
});

ActivityItem.displayName = 'ActivityItem';

export default ActivityItem;