import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import CreateBroadcast from "./createBroadcast";
import type { TemplateType, WhatsappTemplate, ValidationValues,EmailSender,SenderDetail } from "./types";
import TemplateSelector from "./templateSelector";
import "./createWhatsappBroadcast.css";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { includes, removeEmptyValues } from "../../hooks/use-debounce";
import { createBroadCastFun } from "../../services/broadcast";
import { useNavigate } from "react-router-dom";
import EmailTemplate from "./emailTemplate";
import LeadSelector from "../../components/LeadSelector/LeadSelector";
import DrawerExistModal from "../../components/drawerExistModal";
import { useSelector, useDispatch } from "react-redux";
import { setLeadIds } from "../../actions/actions";
import EmailTemplatePopup from "./EmailTemplatePopup";

interface ExpandedSections {
  [key: string]: boolean;
}

const user_preferences = [
  {
    email: "vivek@smn.com",
    name: "Usender1",
  },
  {
    email: "vivek@abc.com",
    name: "sendre 2",
  },
];

const CreateWhatsappBroadcast: React.FC = () => {
  const history = useNavigate();
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>("WhatsApp");
  const [selectedMail, setSelectedMail] = useState("");
  const [isTemplateOpen, setIsTemplateOpen] = useState<boolean>(false);
  const [expandedSections, setExpandedSections] = useState<ExpandedSections>({
    template: false,
    recipients: false,
    type: false,
  });
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [schedule, setSchedule] = useState(false);
  const [isLead, showIsLead] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [selectedLeadList, setSelectedLeadList] = useState<any>({});
  const [confirmShowExitModal, setConfirmShowExitModal] = useState(false);
  useState<boolean>(false);
  const [senderOptions, setSenderOptions] = useState<SenderDetail[]>([]);
  const [isScheduling, setIsScheduling] = useState<boolean>(false);
  const [actionToConfirm, setActionToConfirm] = useState<string>("");

  const { selectedLeadIds }: { selectedLeadIds: string[] } = useSelector(
    (state: any) => state.rootReducers.leads
  );

  console.log("senderOptions", senderOptions);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (selectedLeadIds?.length) {
        dispatch(setLeadIds([]));
      }
    };
  }, []);

  useEffect(() => {
    if (selectedLeadIds?.length) {
      validation.setFieldValue("leads", selectedLeadIds);
    }

    return () => {
      if (selectedLeadIds?.length) {
        dispatch(setLeadIds([]));
      }
    };
  }, [selectedLeadIds]);

  useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const mailSender = userData?.userPreference?.mailSender || {
        gmail: [],
        brevo: [],
      };
  
      const allSenders = [
        ...mailSender.gmail.map((sender: SenderDetail) => ({ ...sender, service: 'gmail' as const })),
        ...mailSender.brevo.map((sender: SenderDetail) => ({ ...sender, service: 'brevo' as const }))
      ];
      setSenderOptions(allSenders);
    }
  }, []);

  const STEPS = {
    CAMPAIGN_DETAILS: 1, // Combined Type & Info
    TEMPLATE: 2, // Template selection
    RECIPIENTS: 3, // Recipients selection
    SCHEDULE: 4, // Schedule & Send
  };

  // Progress Indicator Component (same as before)
  const ProgressIndicator = () => {
    const steps = [
      { step: 1, label: "Campaign Details" },
      { step: 2, label: "Template" },
      { step: 3, label: "Recipients" },
      { step: 4, label: "Schedule" },
    ];

    return (
      <div className="blockArea progress-area">
        <div className="progress-steps-container">
          {steps.map(({ step, label }, index) => (
            <div key={step} className="d-flex align-items-center progress-step">
              {/* Circle with number/check */}
              <div
                onClick={() => {
                  if (isStepComplete(step) || step < currentStep) {
                    setCurrentStep(step);
                  }
                }}
                className={`progress-number font-gilroy-bold
                  ${currentStep === step ? "active" : ""}
                  ${currentStep > step ? "completed" : ""}
                `}
              >
                {currentStep > step ? (
                  <i className="ri-check-line"></i>
                ) : (
                  <span>{step}</span>
                )}
              </div>

              {/* Label */}
              <span
                className={`progress-text font-gilroy-medium 
                ${currentStep === step ? "active" : ""}`}
              >
                {label}
              </span>

              {/* Connector line - except for last item */}
              {index < steps.length - 1 && (
                <div className="progress-line">
                  <div
                    className={`line-inner ${
                      currentStep > step + 1
                        ? "completed"
                        : currentStep > step
                        ? "active"
                        : ""
                    }`}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Function to check if a step is complete
  const isStepComplete = (step: number) => {
    switch (step) {
      case STEPS.CAMPAIGN_DETAILS:
        return (
          !!validation.values.serviceType &&
          !!validation.values.name &&
          (validation.values.serviceType !== "Email" ||
            (validation.values.senderDetail?.email &&
              validation.values.senderDetail?.name))
        );

      case STEPS.TEMPLATE:
        return validation.values.serviceType === "Email"
          ? !!validation.values.emailTemplate
          : !!validation.values.whatsappTemplate;

      case STEPS.RECIPIENTS:
        return validation.values.leads && validation.values.leads.length > 0;

      case STEPS.SCHEDULE:
        return true; // Always allow final step

      default:
        return false;
    }
  };

  const toggleSection = (sectionId: string) => {
    if (sectionId === "template") {
      setIsTemplateOpen(true);
    } else {
      setExpandedSections((prevState) => ({
        ...prevState,
        [sectionId]: !prevState[sectionId],
      }));
    }
  };

  const handleTemplateClick = () => {
    setIsTemplateOpen(true);
  };

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    
    // Reset sender details when switching to Email
    if (option === "Email") {
      validation.setFieldValue("senderDetail", {
        email: "",
        name: "",
        service: undefined
      });
      validation.setFieldValue("serviceType", "");
    } else {
      // For WhatsApp, set service type directly
      validation.setFieldValue("serviceType", option.toLowerCase());
    }
  };

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setExpandedSections((prev) => ({
        ...prev,
        type: false,
        sender: false,
      }));
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSchedule = () => {
    setSchedule(true);
  };

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
     
        const payload = {
          name: values.name,
          serviceType: validation.values.serviceType, // This will be 'whatsapp' or 'gmail' or 'brevo'
          scheduleAt: values.scheduleAt,
          whatsappTemplate: values.serviceType === "whatsapp" ? values.whatsappTemplate : undefined,
          content: ["gmail", "brevo"].includes(values.serviceType) ? values.emailTemplate : undefined,
          leads: values.leads,
          ...(["gmail", "brevo"].includes(values.serviceType) && {
            senderDetail: {
              email: values.senderDetail.email,
              name: values.senderDetail.name,
              
            },
          }),
        };
  
      if (!payload.scheduleAt) {
        payload.scheduleAt = new Date();
      }
  
      console.log("Payload before API call:", payload); // Debugging
  
      await createBroadCastFun(removeEmptyValues(payload));
      history("/whatsappBroadcast");
  
      if (selectedLeadIds?.length) {
        dispatch(setLeadIds([]));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const getValidationSchema = (selectedOption: string) => {
    if (selectedOption === "Email") {
      return Yup.object({
        name: Yup.string().required("Please enter campaign name"),
        senderDetail: Yup.object({
          email: Yup.string().required("Please enter Sender"),
        }),
        serviceType: Yup.string().required("Please enter serviceType"),
        scheduleAt: Yup.string().when([], {
          is: () => isScheduling,
          then: Yup.string().required("Please select a schedule date and time"),
          otherwise: Yup.string(),
        }),
        emailTemplate: Yup.string().required("Please select Template"),
        leads: Yup.array().min(1).required("Please select leads"),
      });
    } else {
      return Yup.object({
        name: Yup.string().required("Please enter campaign name"),
        serviceType: Yup.string().required("Please enter serviceType"),
        scheduleAt: Yup.string().when([], {
          is: () => isScheduling,
          then: Yup.string().required("Please select a schedule date and time"),
          otherwise: Yup.string(),
        }),
        whatsappTemplate: Yup.string().required("Please select Template"),
        leads: Yup.array().min(1).required("Please select leads"),
      });
    }
  };

  useEffect(() => {
    validation.setErrors({});
    validation.setTouched({});
    validation.setFieldValue("whatsappTemplate", "");
    validation.setFieldValue("emailTemplate", "");
    setSelectedTemplate("");
    setSelectedTemplateName("");
    setIsScheduling(false);
    setActionToConfirm("");
  }, [selectedOption]);

  
  const validation: any = useFormik({
    // enableReinitialize: true,
    initialValues: {
      name: "",
      senderDetail: {
        email: "",
        name: "",
      },
      serviceType: "whatsapp",
      scheduleAt: "",
      whatsappTemplate: "",
      emailTemplate: "",

      leads: [],
    },

    validationSchema: getValidationSchema(selectedOption),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleRecipientsSelected = (value: any) => {
    if (includes(validation.values.leads, value)) {
      const newArray = validation.values.leads.filter(
        (item: string) => item !== value
      );
      validation.setFieldValue("leads", newArray);
      setExpandedSections({ ...expandedSections, recipients: false });
    } else {
      validation.setFieldValue("leads", [...validation.values.leads, value]);
    }
  };

  const handleTemplateSelect = (template: WhatsappTemplate) => {
    console.log("Selected Template Object:", template); // Debugging
    if (selectedOption === "WhatsApp") {
      validation.setFieldValue("whatsappTemplate", template._id);
      setSelectedTemplate(template._id); // Set selectedTemplate state
      setSelectedTemplateName(template.name); // Set selectedTemplateName state
      console.log("Selected Template ID:", template._id); // Debugging
      console.log("Selected Template Name:", template.name); // Debugging
    } else {
      validation.setFieldValue("emailTemplate", template._id);
      setSelectedTemplate(template._id); // For email
      setSelectedTemplateName(template.name); // For email
      console.log("Selected Template ID:", template._id); // Debugging
      console.log("Selected Template Name:", template.name); // Debugging
    }
  };

  const handleEmailTemplateSelect = (template: TemplateType) => {
    setSelectedTemplate(template._id);
    setSelectedTemplateName(template.details?.title || template.name);
    validation.setFieldValue("emailTemplate", template._id);
    setExpandedSections({ ...expandedSections, template: false });
    console.log("Selected Email Template ID:", template._id); // Debugging
    console.log(
      "Selected Email Template Name:",
      template.details?.title || template.name
    ); // Debugging
  };

  const handleSelectAll = (value: string[]) => {
    validation.setFieldValue("leads", value);
  };

  const handleDiscard = () => {
    setExpandedSections({ ...expandedSections, template: false });
    validation.setFieldValue("whatsappTemplate", "");
    setSelectedTemplate("");
    setSelectedTemplateName("");
  };

  const handleDiscardEmail = () => {
    setExpandedSections({ ...expandedSections, template: false });
    validation.setFieldValue("emailTemplate", "");
    setSelectedTemplate("");
    setSelectedTemplateName("");
  };

  const handleDiscardRecipients = () => {
    setExpandedSections({ ...expandedSections, recipients: false });
    validation.setFieldValue("leads", []);
  };

  const handleSenderSelect = (sender: SenderDetail) => {
    validation.setValues({
      ...validation.values,
      senderDetail: {
        email: sender.email,
        name: sender.name,
        service: sender.service
      },
      // Crucially, set serviceType to the sender's service
      serviceType: sender.service
    });
  };

  const toggleConfirmEditModal = () => {
    setConfirmShowExitModal(!confirmShowExitModal);
  };

  const handleConfirmDrawerClick = async () => {
    toggleConfirmEditModal(); // Close the modal
  
    if (actionToConfirm === "discard") {
      // Reset form to initial state
      validation.resetForm();
      
      // Reset all states
      setSelectedTemplate("");
      setSelectedTemplateName("");
      setSelectedOption("WhatsApp");
      setExpandedSections({
        template: false,
        recipients: false,
        type: false,
      });
      setCurrentStep(1);
      setIsScheduling(false);
      
      // Navigate back to the broadcast list
      history("/whatsappBroadcast");
    } else if (actionToConfirm === "sendNow") {
      // Existing sendNow logic
      validation.setFieldValue("scheduleAt", new Date().toISOString());
      validation.handleSubmit();
    } else if (actionToConfirm === "schedule") {
      // Existing schedule logic
      validation.handleSubmit();
    }
  };

  
  const isFormComplete = (values: ValidationValues): boolean => {
    console.log('Validation Check - Current Values:', {
      name: values.name?.trim(),
      serviceType: values.serviceType,
      leadsCount: values.leads?.length,
      whatsappTemplate: values.whatsappTemplate,
      emailTemplate: values.emailTemplate,
      senderEmail: values.senderDetail?.email
    });
  
    // Check for required fields
    if (!values.name?.trim()) {
      console.log('Validation failed: Missing campaign name');
      return false;
    }
  
    if (!values.leads?.length) {
      console.log('Validation failed: No recipients selected');
      return false;
    }
  
    // Normalize service type for comparison
    const normalizedServiceType = values.serviceType?.toUpperCase();
  
    // WhatsApp campaign validation
    if (normalizedServiceType === 'WHATSAPP') {
      if (!values.whatsappTemplate) {
        console.log('Validation failed: Missing WhatsApp template');
        return false;
      }
    }
  
    // Email campaign validation
    if (normalizedServiceType === 'EMAIL') {
      if (!values.emailTemplate) {
        console.log('Validation failed: Missing email template');
        return false;
      }
      if (!values.senderDetail?.email?.trim()) {
        console.log('Validation failed: Missing sender email');
        return false;
      }
    }
  
    console.log('Form validation passed');
    return true;
  };

useEffect(() => {
  console.log('Form values changed:', validation.values);
  console.log('Form completion status:', isFormComplete(validation.values));
}, [validation.values]);

  return (
    <>
      <div id="main" className="main">
        <Header />
        <div className="createWhatsapp-broadcast">
          {/* Header Section */}
          <section className="auto-section-1 px-0">
            <div className="d-flex align-items-center">
              <div className="align-items-center auto-section-1-sub-1">
                <label>Create new campaign</label>
              </div>
              <span
                onClick={() => {
                  history("/whatsappBroadcast");
                }}
                className="ms-auto cursor-pointer"
              >
                <i className="ri-close-line fs-3"></i>
              </span>
            </div>
          </section>

          {/* Progress Indicator */}
          <ProgressIndicator />

          {/* Step 1: Campaign Details */}
          {currentStep === STEPS.CAMPAIGN_DETAILS && (
            <>
              {/* Campaign Type Selection */}
              <div className="blockArea">
                <div className="row align-items-center">
                  <div className="col-5">
                    <div className="d-flex align-items-center">
                      <img src="./assets/img/verified.png" alt="Verified" />
                      Campaign Type
                    </div>
                  </div>
                  <div className="col-7">
                    <div
                      className="fakeDropdown"
                      onClick={() => toggleSection("type")}
                      style={{
                        display: "flex",
                        fontSize: "18px",
                        position: "relative",
                      }}
                    >
                      <label>
                        {selectedOption
                          ? selectedOption
                          : "Select Campaign Type"}
                      </label>
                      <i
                        className={`fs-4 ri-arrow-${
                          expandedSections["type"] ? "up" : "down"
                        }-circle-line`}
                      ></i>
                      {expandedSections["type"] && (
                        <div
                          style={{
                            position: "absolute",
                            backgroundColor: "#fff",
                            border: "1px solid #ddd",
                            padding: "10px",
                            zIndex: 1,
                            width: "100%",
                            top: "100%",
                            left: 0,
                          }}
                        >
                          <div
                            className="dropdown-item"
                            onClick={() => handleOptionSelect("WhatsApp")}
                          >
                            <span className="dropdown-item-email">
                              WhatsApp
                            </span>
                          </div>
                          <div
                            className="dropdown-item"
                            onClick={() => handleOptionSelect("Email")}
                          >
                            <span className="dropdown-item-email">Email</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Campaign Name */}
              <div className="blockArea mt-3">
                <div className="row align-items-center">
                  <div className="col-5">
                    <div className="d-flex align-items-center">
                      {validation.values.name ? (
                        <img src="./assets/img/verified.png" alt="Verified" />
                      ) : (
                        <img
                          src="./assets/img/grey-ring.png"
                          alt="Not Verified"
                        />
                      )}
                      Campaign name
                    </div>
                  </div>
                  <div className="col-7">
                    <input
                      type="text"
                      placeholder="Enter broadcast name"
                      name="name"
                      value={validation.values.name}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
              </div>
              {validation.touched.name && validation.errors.name && (
                <span className="text-danger">{validation.errors.name}</span>
              )}

              {/* Email Sender Selection */}
              {selectedOption === "Email" && (
                <div className="blockArea mt-3">
                  <div className="row align-items-center">
                    <div className="col-5">
                      <div className="d-flex align-items-center">
                        {validation.values.senderDetail &&
                        validation.values.senderDetail.email ? (
                          <img src="./assets/img/verified.png" alt="Verified" />
                        ) : (
                          <img
                            src="./assets/img/grey-ring.png"
                            alt="Not Verified"
                          />
                        )}
                        Select Sender
                      </div>
                    </div>
                    <div className="col-7">
                      <div
                        className="fakeDropdown"
                        ref={dropdownRef}
                        onClick={() => toggleSection("sender")}
                        style={{
                          display: "flex",
                          fontSize: "18px",
                          position: "relative",
                        }}
                      >
                        <label>
                          {validation.values.senderDetail &&
                          validation.values.senderDetail.email ? (
                            <>{validation.values.senderDetail.email}</>
                          ) : (
                            <>Select Sender</>
                          )}
                        </label>
                        <i
                          className={`fs-4 ri-arrow-${
                            expandedSections["sender"] ? "up" : "down"
                          }-circle-line`}
                        ></i>
                        {expandedSections["sender"] && (
    <div
    style={{
      position: "absolute",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      padding: "10px",
      zIndex: 1000,
      width: "100%",
      bottom: "100%", // Changed from top to bottom
      left: 0,
      maxHeight: "300px",
      overflowY: "auto",
      boxShadow: "0 -2px 5px rgba(0,0,0,0.1)" // Optional: adds shadow for better visibility
    }}
  >
    {senderOptions.length > 0 ? (
      <>
        {/* Gmail Section */}
        <div style={{ padding: "5px", backgroundColor: "#f5f5f5", marginBottom: "5px" }}>
          Gmail Senders
        </div>
        {senderOptions
  .filter((sender: SenderDetail) => sender.service === 'gmail')
  .map((sender: SenderDetail) => (
    <div
      key={sender._id}
      className="dropdown-item"
      onClick={() => handleSenderSelect(sender)}
    >
      <span className="dropdown-item-email">
        {sender.email} ({sender.name})
      </span>
    </div>
  ))}
        {/* Brevo Section */}
        <div style={{ padding: "5px", backgroundColor: "#f5f5f5", marginTop: "10px", marginBottom: "5px" }}>
          Brevo Senders
        </div>
        {senderOptions
          .filter(sender => sender.service === 'brevo')
          .map((sender) => (
            <div
              key={sender._id}
              className="dropdown-item"
              onClick={() => handleSenderSelect(sender)}
            >
              <span className="dropdown-item-email">
                {sender.email} ({sender.name})
              </span>
            </div>
          ))}
      </>
    ) : (
      <div className="dropdown-item text-center">
        <span className="dropdown-item-email text-muted">
          Please connect Gmail or Brevo to send email
        </span>
      </div>
    )}
  </div>
)}
                      </div>
                    </div>
                  </div>
                  {validation.touched.senderDetail &&
                    validation.errors.senderDetail?.email && (
                      <span className="text-danger">
                        {validation.errors.senderDetail.email}
                      </span>
                    )}
                </div>
              )}
            </>
          )}

          {/* Step 2: Template Selection */}
          {currentStep === STEPS.TEMPLATE && (
            <div className="blockArea">
              <div className="row align-items-center">
                <div className="col-5">
                  <div className="d-flex align-items-center">
                    {(validation.values.whatsappTemplate &&
                      selectedOption === "WhatsApp") ||
                    (validation.values.emailTemplate &&
                      selectedOption === "Email") ? (
                      <img src="./assets/img/verified.png" alt="Verified" />
                    ) : (
                      <img
                        src="./assets/img/grey-ring.png"
                        alt="Not Verified"
                      />
                    )}
                    Select Template
                  </div>
                </div>
                <div className="col-7">
                  <div
                    className="fakeDropdown"
                    onClick={() => {
                      if (selectedOption === "Email") {
                        setExpandedSections((prev) => ({
                          ...prev,
                          template: !prev.template,
                        }));
                      } else {
                        setIsTemplateOpen(true);
                      }
                    }}
                    style={{ display: "flex" }}
                  >
                    <label>
                      {selectedOption === "WhatsApp"
                        ? validation.values.whatsappTemplate
                          ? selectedTemplateName
                          : "Select Template"
                        : validation.values.emailTemplate
                        ? selectedTemplateName
                        : "Select Template"}
                    </label>
                    <i
                      className={`fs-4 ri-arrow-${
                        expandedSections["template"] ? "up" : "down"
                      }-circle-line`}
                    ></i>
                  </div>
                </div>
              </div>

              {expandedSections["template"] &&
                (selectedOption === "Email" ? (
                  <EmailTemplatePopup
                  isOpen={expandedSections["template"]}
                  onClose={() => {
                    setExpandedSections((prev) => ({
                      ...prev,
                      template: false,
                    }));
                  }}
                  onEmailTemplateSelect={handleEmailTemplateSelect}
                  handleDiscardEmail={handleDiscardEmail}
                />
                ) : (
                  <TemplateSelector
                    isOpen={isTemplateOpen}
                    onClose={() => setIsTemplateOpen(false)}
                    onTemplateSelect={handleTemplateSelect}
                    selectedTemplate={selectedTemplate} // Pass the selectedTemplate ID
                    type="whatsapp"
                    expandedSections={expandedSections}
                    setExpandedSections={setExpandedSections}
                    handleDiscard={handleDiscard}
                  />
                ))}
            </div>
          )}

          {/* Step 3: Recipients Selection */}
          {currentStep === STEPS.RECIPIENTS && (
            <div className="blockArea">
              <div className="row align-items-center">
                <div className="col-5">
                  <div className="d-flex align-items-center">
                    {validation.values.leads.length !== 0 ? (
                      <img src="./assets/img/verified.png" alt="Verified" />
                    ) : (
                      <img
                        src="./assets/img/grey-ring.png"
                        alt="Not Verified"
                      />
                    )}
                    Select Recipients
                  </div>
                </div>
                <div className="col-7">
                  <div
                    className="fakeDropdown"
                    onClick={() => {
                      showIsLead(true);
                    }}
                    style={{ display: "flex" }}
                  >
                    <label>
                      {validation.values.leads.length !== 0 ? (
                        <>
                          {validation.values.leads.length} recipients selected
                        </>
                      ) : (
                        <>Select leads</>
                      )}
                    </label>
                    <i className="fs-4 ri-arrow-down-circle-line"></i>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Step 4: Schedule & Send */}
          {currentStep === STEPS.SCHEDULE && (
            <div className="blockArea">
              {/* Campaign Summary */}
              <div className="bg-light rounded p-4">
                <h5 className="fw-bold mb-3">Campaign Summary</h5>
                <div className="d-flex justify-content-between mb-2">
                  <span className="text-muted">Campaign Type:</span>
                  <span className="fw-medium">{selectedOption}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span className="text-muted">Campaign Name:</span>
                  <span className="fw-medium">{validation.values.name}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span className="text-muted">Recipients:</span>
                  <span className="fw-medium">
                    {validation.values.leads.length || 0} selected
                  </span>
                </div>
                {selectedOption === "Email" && (
                  <div className="d-flex justify-content-between mb-2">
                    <span className="text-muted">Sender:</span>
                    <span className="fw-medium">
                      {validation.values.senderDetail.email}
                    </span>
                  </div>
                )}
                {selectedOption === "WhatsApp" && (
                  <div className="d-flex justify-content-between mb-2">
                    <span className="text-muted">Template:</span>
                    <span className="fw-medium">{selectedTemplateName}</span>
                  </div>
                )}
              </div>

              {/* Action Buttons */}
              <div className="d-flex justify-content-end mt-3">
              <button
  className="btn me-2"
  onClick={() => {
    setActionToConfirm("discard");  // Set action type to discard
    toggleConfirmEditModal();        // Show confirmation modal
  }}
  style={{
    background: "none",
    border: "none",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "700",
    fontSize: "14px",
  }}
>
  Discard
</button>
                <button
        className="btn"
        onClick={() => {
          setActionToConfirm("sendNow");
          toggleConfirmEditModal();
        }}
        // Add this disabled prop
        disabled={!isFormComplete(validation.values)}
      >
        Send Now
      </button>
                <button
        className="btn auto_btn ms-2"
        onClick={() => setIsScheduling(true)}
        // Add this disabled prop
        disabled={!isFormComplete(validation.values)}
      >
        Schedule
      </button>
              </div>

              {/* Date Selector - Visible Only When Scheduling */}
              {isScheduling && (
                <div className="mt-3">
                  <div className="row align-items-center">
                    <div className="col-5">
                      <div className="d-flex align-items-center">
                        <img src="./assets/img/verified.png" alt="Verified" />
                        Schedule Date
                      </div>
                    </div>
                    <div className="col-7">
                    <input
  type="datetime-local"
  name="scheduleAt"
  min={new Date().toISOString().slice(0, 16)}
  value={validation.values.scheduleAt}
  onChange={validation.handleChange}
/>
                      {validation.touched.scheduleAt &&
                        validation.errors.scheduleAt && (
                          <div className="text-danger mt-1 small">
                            {validation.errors.scheduleAt}
                          </div>
                        )}
                    </div>
                  </div>

                  {/* Confirm Schedule Button */}
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="btn auto_btn"
                      onClick={() => {
                        if (validation.values.scheduleAt) {
                          setActionToConfirm("schedule");
                          toggleConfirmEditModal();
                        }
                      }}
                      disabled={!validation.values.scheduleAt}
                    >
                      Confirm Schedule
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* Fixed Navigation Buttons */}
      <div className="navigation-buttons">
        <button
          className="btn"
          onClick={() => setCurrentStep((prev) => Math.max(1, prev - 1))}
          disabled={currentStep === 1}
        >
          Previous
        </button>

        <button
          className="btn auto_btn"
          onClick={() => setCurrentStep((prev) => Math.min(4, prev + 1))}
          disabled={currentStep === 4}
        >
          Next
        </button>
      </div>
      {/* Modals and Drawers */}
      <LeadSelector
        isOpen={isLead}
        onClose={() => showIsLead(false)}
        selectedLeads={validation.values?.leads}
        handleSelectedLeads={(leads: any) => {
          validation.setFieldValue(
            "leads",
            leads?.map((e: any) => e._id)
          );
        }}
        setSelectedLeadList={setSelectedLeadList}
        selectedLeadList={selectedLeadList}
      />
      <TemplateSelector
        isOpen={isTemplateOpen}
        onClose={() => setIsTemplateOpen(false)}
        onTemplateSelect={handleTemplateSelect}
        selectedTemplate={selectedTemplate} // Pass the selectedTemplate ID
        type="whatsapp"
        expandedSections={expandedSections}
        setExpandedSections={setExpandedSections}
        handleDiscard={handleDiscard}
      />
      
      <DrawerExistModal
  title={
    actionToConfirm === "sendNow"
      ? "Are you sure you want to send the campaign now?"
      : actionToConfirm === "schedule"
      ? "Are you sure you want to schedule this campaign?"
      : "Are you sure you want to discard this campaign?"  // Add discard message
  }
  showExitModal={confirmShowExitModal}
  toggleEditModal={toggleConfirmEditModal}
  handleDrawerClick={handleConfirmDrawerClick}
/>
    </>
  );
};

export default CreateWhatsappBroadcast;
